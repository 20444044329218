import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { ErrorBox } from 'components/ErrorBox';
import { Input, isRequired, Submit } from 'components/Form';
import { TrackingContext } from 'context/Tracking';
import { Endpoints, post } from 'utils/api';

import { forgotPasswordRoutes } from './routes';

interface RecoverFormValues {
  login: string;
}

const formName = 'password-recovery';

export const RecoverForm = () => {
  const [, { faro }] = useContext(TrackingContext);
  const history = useHistory();
  const [serverError, setServerError] = useState<string>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<RecoverFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const handleFormSubmit: SubmitHandler<RecoverFormValues> = async (values) => {
    setServerError(undefined);

    try {
      faro.trackFormSubmit(formName);

      await post<boolean>(Endpoints.FORGOT_PASSWORD, {
        emailOrUsername: values.login,
      });

      history.push(forgotPasswordRoutes.checkInbox, {
        emailOrUsername: values.login,
      });

      faro.trackFormSubmitSuccess(formName);
    } catch (err) {
      faro.trackFormSubmitError(formName, err.message);
      faro.trackError(err);
      setServerError(err.message);
    }
  };

  return (
    <form id="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Input
        control={control}
        disabled={isSubmitting}
        errors={errors}
        id="username"
        label="Email or username"
        name="login"
        showLabel
        type="text"
        validate={[isRequired()]}
      />
      <Submit
        disabled={isSubmitting}
        id="submit"
        isLoading={isSubmitting}
        label="Send reset email"
        loadingText="Sending reset email..."
      />
      {serverError && <ErrorBox id="server-error">{serverError}</ErrorBox>}
    </form>
  );
};
