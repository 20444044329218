/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { Tooltip } from '@grafana/ui';
import cx from 'classnames';
import { ChangeEventHandler, FocusEventHandler, useContext, useState } from 'react';
import { Control, FieldErrors, useController, useFormState, Validate } from 'react-hook-form';

import { IconInline } from 'components/Icon/IconInline';
import { ErrorText, HintText, SuccessText, Text } from 'components/Text';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { PUBLIC_URL } from 'utils/consts';
import { noop } from 'utils/noop';
import { useStyles } from 'utils/useStyles';

const getStyles = ({ colors, typography }: StylingContextState) => ({
  columnContainer: css`
    display: flex;
    flex-flow: column nowrap;
    margin: 1.7rem 0;
    position: relative;
  `,
  label: css`
    margin: 0 0 0.8rem;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 23.2px */
    letter-spacing: -0.24px;
    color: ${colors.gray11};

    @media (max-width: 550px) {
      font-size: 14px;
    }
  `,
  rowContainer: css`
    align-items: center;
    background: ${colors.white};
    border: 1px solid var(--border-weak, rgba(36, 41, 46, 0.12));
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    padding: 8px 16px;
    position: relative;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    width: 100%;
    color: ${colors.gray11};

    &:hover {
      background-color: ${colors.white};
      border-color: #24292e4d;
    }

    &.focused {
      border-color: ${colors.blue3};
      box-shadow: 0 0.1rem 0.6rem ${colors.blue2};
    }

    &.disabled {
      opacity: 0.6;
    }

    &.invalid:not(.focused) {
      border-color: ${colors.red3};
    }

    &::after {
      content: url(${PUBLIC_URL}/img/icons/caret.svg);
      height: 1.1rem;
      pointer-events: none;
      position: absolute;
      right: 12px;
      transform: translateY(-50%);
    }
  `,
  select: css`
    ${typography.text.normal};
    appearance: none;
    background-color: inherit;
    border: none;
    color: ${colors.gray11};
    filter: none;
    margin: 0;
    outline: none;
    width: 100%;
  `,
  error: css`
    margin: 0.8rem 0 0;
  `,
  inputLabelText: css`
    text-wrap: nowrap;
    width: min-content;
    display: flex;
    flex-direction: row;
    gap: 2px;

    svg {
      width: min-content;
      margin-bottom: 8px;
      line-height: 0;
      display: flex;
      align-self: center;
      fill: rgba(36, 41, 46, 0.75);
      height: 16px;
    }
  `,
  labelIcon: css`
    height: 16px;
  `,
});

export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectProps {
  // `any` is used here as there is no other way to pass control without type errors
  control: Control<any>;
  name: string;
  label: string;
  options: SelectOption[];

  autoFocus?: boolean;
  className?: string;
  id?: string;
  defaultValue?: string;
  disabled?: boolean;
  errors?: FieldErrors;
  hint?: string;
  onChange?: () => void;
  showLabel?: boolean;
  validate?: Array<Validate<string, any>>;
  validMessage?: string;
  infoTooltip?: string;
}

export const Select = ({
  name,
  label,
  control,
  options,
  autoFocus,
  className,
  id,
  defaultValue = '',
  disabled,
  errors,
  hint,
  onChange = noop,
  showLabel = false,
  validate = [],
  validMessage,
  infoTooltip,
}: SelectProps) => {
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));
  const [, { faro }] = useContext(TrackingContext);

  const [isFocused, setIsFocused] = useState(false);
  const formState = useFormState({ control });

  const {
    field: fieldProps,
    fieldState: { invalid, isTouched },
  } = useController({
    name,
    control,
    defaultValue,
    rules: {
      validate: validate.reduce((acc, validator, index) => {
        acc[index.toString()] = validator;

        return acc;
      }, {} as Record<string, Validate<string, any>>),
    },
  });

  const shouldShowIndicators = formState.isSubmitted || isTouched;
  const shouldShowValidIndicator = shouldShowIndicators && !invalid;
  const shouldShowInvalidIndicator = shouldShowIndicators && invalid;

  const handleSelectChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    fieldProps.onChange(event);
    onChange();
  };

  const handleSelectFocus: FocusEventHandler<HTMLSelectElement> = () => {
    faro.trackSelectFocus(label);
    setIsFocused(true);
  };

  const handleSelectBlur: FocusEventHandler<HTMLSelectElement> = () => {
    faro.trackSelectBlur(label);
    fieldProps.onBlur();
    setIsFocused(false);
  };

  return (
    <div className={cx(className)} css={styles.columnContainer} id={id}>
      {showLabel && infoTooltip ? (
        <>
          <Tooltip content={<span>{infoTooltip}</span>} placement={'right'}>
            <span css={styles.inputLabelText}>
              <Text align="left" className="label" color={'gray11'} css={styles.label} type="label">
                {label}
              </Text>
              <IconInline title={infoTooltip} name="info-circle" size="sm" css={styles.labelIcon} />
            </span>
          </Tooltip>
        </>
      ) : showLabel && !infoTooltip ? (
        <Text align="left" className="label" color={'gray11'} css={styles.label} type="label">
          {label}
        </Text>
      ) : null}
      <div
        css={styles.rowContainer}
        className={cx({
          container: true,
          disabled,
          focused: isFocused,
          invalid: shouldShowIndicators && invalid,
        })}
      >
        <select
          aria-label={label}
          autoFocus={autoFocus}
          className="field"
          css={styles.select}
          disabled={disabled}
          // placeholder={label}
          {...fieldProps}
          onChange={handleSelectChange}
          onFocus={handleSelectFocus}
          onBlur={handleSelectBlur}
        >
          {options.map((option, idx) => (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {shouldShowValidIndicator && !!validMessage && (
        <SuccessText align="left" className="valid-msg">
          {validMessage}
        </SuccessText>
      )}
      {shouldShowInvalidIndicator && !!errors?.[name]?.message && (
        <ErrorText align="left" className="invalid-msg">
          {String(errors[name]?.message ?? '')}
        </ErrorText>
      )}
      {!!hint && (
        <HintText align="left" className="hint">
          {hint}
        </HintText>
      )}
    </div>
  );
};
