/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext } from 'react';

import { Button, ButtonProps } from 'components/Button';
import { RedirectContext } from 'context/Redirect';
import { StylingContext, StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { useStyles } from 'utils/useStyles';

export type SSOProvidersKeys = 'google' | 'github' | 'microsoft' | 'amazon' | 'pingidentity';

export interface SSOProvider {
  key: SSOProvidersKeys | string;
  name: string;
  provider: SSOProvidersKeys | string;
}

export const publicSsoProviders: SSOProvider[] = [
  {
    provider: 'google',
    key: 'google',
    name: 'Google',
  },
  {
    provider: 'github',
    key: 'github',
    name: 'GitHub',
  },
  {
    provider: 'microsoft',
    key: 'microsoft',
    name: 'Microsoft',
  },
  {
    provider: 'amazon',
    key: 'amazon',
    name: 'Amazon',
  },
];

export const privateSsoProviders: SSOProvider[] = [];

export interface SSOButtonsProps {
  disableUserCreation: boolean;
  redirectRoute: string;
  ssoProviders: SSOProvider[];
  disabled?: boolean;
  redirectErrorRoute?: string;
  inviteKey?: string;
}

export const SSOButtons = ({
  disableUserCreation,
  redirectRoute,
  ssoProviders,
  disabled,
  redirectErrorRoute,
  inviteKey,
}: SSOButtonsProps) => {
  const {
    viewport: { md },
  } = useContext(StylingContext);
  const [, { getSourcesAsQueryParams, getSSItem }] = useContext(TrackingContext);
  const [, { getRedirectUrl }] = useContext(RedirectContext);
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));

  return (
    <div css={styles.container} id="sso-buttons" role="group">
      {ssoProviders.map((provider) => {
        const handleButtonClick: ButtonProps['onClick'] = () => {
          const queryParams = new URLSearchParams(getSourcesAsQueryParams());

          queryParams.set('disableUserCreation', String(disableUserCreation));
          queryParams.set('redirectUrl', getRedirectUrl(redirectRoute));

          if (inviteKey) {
            queryParams.set('inviteKey', String(inviteKey));
          }

          if (redirectErrorRoute) {
            queryParams.set('redirectErrorUrl', getRedirectUrl(redirectErrorRoute));
          }

          if (!queryParams.get('signupForm')) {
            queryParams.set('signupForm', getSSItem('signupForm') || 'cloud_trial');
          }

          window.location.href = `/api/login/${provider.key}?${queryParams.toString()}`;
        };

        return (
          <Button
            ariaLabel={`Login using ${provider.name}`}
            variant="dark"
            css={styles.button}
            disabled={disabled}
            hideLabel={!md}
            icon={
              provider.provider === 'github' || provider.provider === 'amazon'
                ? `${provider.provider}-for-light-theme`
                : provider.provider
            }
            iconLabel={`${provider.name} logo`}
            iconMarginMultiplier={2}
            id={`sso-buttons-${provider.provider}`}
            key={provider.provider}
            label={provider.name}
            trackingLabel={`Login using ${provider.name}`}
            onClick={handleButtonClick}
          />
        );
      })}
    </div>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  container: css`
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: var(--background-primary, ${colors.white});
  `,
  button: css`
    margin-right: 0.6rem;
    border-radius: 5px;
    background: #f4f5f5;
    color: rgba(36, 41, 46, 0.75);
    outline: none !important;
    height: 50px;
    /* Body */
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.021px;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      background: var(--action-hover, rgba(36, 41, 46, 0.16));
      color: rgba(36, 41, 46, 0.75);
      outline: none !important;
    }
  `,
});
