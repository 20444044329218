/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';

import { Link, LinkProps } from 'components/Link';
import { Text, TextTypes } from 'components/Text';
import { StylingContextState } from 'context/Styling';
import { useStyles } from 'utils/useStyles';

const getStyles = ({ colors }: StylingContextState) => ({
  container: css`
    margin: ${`15px 0 0`};
    color: ${colors.gray11};
  `,
});

export interface AlternateActionProps {
  actionLabel: string;
  label: string;

  actionEvent?: LinkProps['onClick'];
  actionLink?: string;
  actionType?: LinkProps['type'];
  id?: string;
  type?: TextTypes;
}

export const AlternateAction = ({
  actionLabel,
  label,
  actionEvent,
  actionLink,
  actionType = 'internal',
  id,
  type,
}: AlternateActionProps) => {
  const styles = useStyles(getStyles);

  return (
    <Text align="center" className="alternate-action" css={styles.container} id={id} type={type || 'small'}>
      {label}
      <Link label={actionLabel} onClick={actionEvent} spaced={true} to={actionLink} type={actionType} />
    </Text>
  );
};
