import { generateRoutes } from 'utils/generateRoutes';

export const invitesRoutes = generateRoutes(
  {
    accept: 'accept',
    accepted: 'accepted',
    alreadyAccepted: 'already-accepted',
    createUser: 'create-user',
    createUserFromSSO: 'create-user-from-sso',
    expired: 'expired',
    invalidUser: 'invalid-user',
  },
  'invites'
);
