import { ReactNode, useState } from 'react';

import { useEffectOnce } from 'hooks/useEffectOnce';
import { useQuery } from 'hooks/useQuery';
import { Endpoints, get } from 'utils/api';

import { defaultPluginContextState, PluginContext } from './pluginContext';
import { Plugin } from './types';

export interface PluginProviderProps {
  children: ReactNode;
}

export const PluginProvider = ({ children }: PluginProviderProps) => {
  const [plugin, setPlugin] = useState(defaultPluginContextState);
  const pluginQueryParam = useQuery('plcmt');

  useEffectOnce(() => {
    const getPluginData = async () => {
      try {
        const plugin = await get<Plugin>(`${Endpoints.PLUGINS}/${pluginQueryParam}`);
        if (plugin.status === 'enterprise' || plugin.status === 'active') {
          setPlugin({ plugin });
        }
      } catch (err) {
        return null;
      }
      return;
    };

    if (pluginQueryParam) {
      getPluginData();
    }
  });

  return <PluginContext.Provider value={plugin}>{children}</PluginContext.Provider>;
};
