import { Redirect, Switch, useLocation } from 'react-router-dom';

import { SelectOrganisation } from 'pages/SignUp/SelectOrganisation';
import { FaroRoute } from 'utils/faro';

import { CheckInbox } from './CheckInbox';
import { CreateUser } from './CreateUser';
import { LoadingInstanceAnimation } from './LoadingInstanceAnimation';
import { signUpRoutes } from './routes';

export const SignUp = () => {
  const location = useLocation();

  return (
    <Switch>
      <FaroRoute path={signUpRoutes.createUser} component={CreateUser} />
      <FaroRoute path={signUpRoutes.checkInbox} component={CheckInbox} />
      <FaroRoute path={signUpRoutes.selectOrganisation} component={SelectOrganisation} />
      <FaroRoute path={signUpRoutes.loadingInstance} component={LoadingInstanceAnimation} />
      <Redirect
        exact
        from={signUpRoutes.base}
        to={{
          pathname: signUpRoutes.createUser,
          search: location.search,
        }}
      />
    </Switch>
  );
};
