/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext, useEffect } from 'react';

import { Button } from 'components/Button';
import { Page, PagePanel } from 'components/Page';
import { SpacedText } from 'components/Text';
import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { UserContext } from 'context/User';
import { signInRoutes } from 'pages/SignIn/routes';
import { useStyles } from 'utils/useStyles';

export const AlreadyAccepted = () => {
  const styles = useStyles(getStyles);
  const [, { redirectToPortal }] = useContext(RedirectContext);
  const [
    {
      userMeta: { userIsLoggedIn },
    },
  ] = useContext(UserContext);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (userIsLoggedIn) {
      setTimeout(() => {
        redirectToPortal();
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [redirectToPortal, userIsLoggedIn]);

  return (
    <Page heading="Invite already accepted" title="Invites - Already Accepted">
      <PagePanel id="panel" css={styles.centeredParas}>
        {userIsLoggedIn ? (
          <SpacedText align="center" id="description-redirect">
            Redirecting you back to the portal...
          </SpacedText>
        ) : (
          <>
            <SpacedText align="center" id="description-sign-in">
              Looks like the invitation was already accepted.
            </SpacedText>
            <Button variant="primary" id="sign-in-btn" label="Sign in" role="link" href={signInRoutes.base} />
          </>
        )}
      </PagePanel>
    </Page>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  centeredParas: css`
    text-align: center;
    color: ${colors.gray11};
    place-tiems: center;
    margin-top: 16px;
  `,
});
