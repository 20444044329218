import { createContext } from 'react';

import { noopPromise, nullNoopPromise } from 'utils/noop';

import { StackRegionContextActions, StackRegionContextModel, StackRegionContextState } from './types';

export const defaultStackRegionContextState: StackRegionContextState = {
  stackRegionsData: null,
  isLoading: true,
};

export const defaultStackRegionContextActions: StackRegionContextActions = {
  getStackRegions: nullNoopPromise,
  setStackRegions: noopPromise,
};

export const StackRegionContext = createContext<StackRegionContextModel>([
  defaultStackRegionContextState,
  defaultStackRegionContextActions,
]);

StackRegionContext.displayName = 'StackRegionContext';
