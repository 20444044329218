import { GOOGLE_ANALYTICS_KEY } from 'utils/consts';
import { noop } from 'utils/noop';

import { EventTraits, LocationDetails, LocationTraits, UserTraits } from './types';

const isEnabled = !!GOOGLE_ANALYTICS_KEY;

export interface WindowProperties {
  GoogleAnalyticsObject: string;
  ga: {
    (...args: unknown[]): void;
    q: unknown[];
    l: number;
  };
  ga_debug?: {
    trace: boolean;
  };
}

export const initialize = isEnabled
  ? () => {
      window.GoogleAnalyticsObject = 'ga';
      window.ga =
        window.ga ||
        ((...args: unknown[]) => {
          (window.ga.q = window.ga.q || []).push(args);
        });

      window.ga.l = +new Date();
      window.ga('create', GOOGLE_ANALYTICS_KEY, 'auto');
      window.ga('set', 'anonymizeIp', true);
    }
  : noop;

export const load = isEnabled
  ? () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.google-analytics.com/analytics.js';

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  : noop;

export const trackPage = isEnabled
  ? (_locationTraits: LocationTraits, locationDetails: LocationDetails) => {
      window.ga('send', {
        hitType: 'pageview',
        location: locationDetails.location,
        title: locationDetails.title,
      });
    }
  : noop;

export const trackEvent = isEnabled
  ? ({ name }: EventTraits) => {
      window.ga('send', 'event', 'auth', name);
    }
  : noop;

export const identifyUser = isEnabled
  ? ({ id }: UserTraits) => {
      window.ga('set', 'userId', id);
    }
  : noop;

export const resetUser = isEnabled
  ? () => {
      window.ga('set', 'userId', undefined);
    }
  : noop;
