/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { FocusEventHandler, KeyboardEventHandler, MouseEventHandler, useContext } from 'react';
import { Control, useController, Validate } from 'react-hook-form';

import { Icon } from 'components/Icon';
import { StylingContextState } from 'context/Styling';
import { palette } from 'context/Styling/palette';
import { TrackingContext } from 'context/Tracking';
import { noop } from 'utils/noop';
import { useStyles } from 'utils/useStyles';

const getStyles = ({ colors }: StylingContextState) => ({
  container: css`
    display: flex;
    flex-flow: row nowrap;
    margin: 1.7rem 0;
    outline: none;
  `,
  checkbox: css`
    background: ${colors.white};
    border: 1px solid var(--border-weak, rgba(36, 41, 46, 0.12));
    border-radius: 4px;
    color: ${colors.green};
    height: 2.4rem;
    margin-right: 0.8rem;
    outline: none;
    padding: 0.4rem;
    transition: 300ms;
    width: 2.4rem;

    [role='checkbox']:not(.disabled):focus > & {
      border: 1px solid ${colors.blue3};
      box-shadow: 0 0.1rem 0.6rem ${colors.blue2};
    }

    [role='checkbox'][aria-checked='true'] > & {
      background: ${palette.blueLightMain};
      color: ${colors.white};
      border: none;
      box-shadow: none;
    }
    [role='checkbox'][aria-checked='true']:hover > & {
      background: ${palette.blueLightMainHover};
      color: ${colors.white};
      border: none;
      box-shadow: none;
    }

    [role='checkbox']:not(.disabled):hover & {
      border-color: var(--border-medium, rgba(36, 41, 46, 0.3));
    }

    [role='checkbox'].disabled & {
      opacity: 0.6;
    }
  `,
  icon: css`
    animation: fadeIn 250ms forwards;

    &.hidden {
      //animation: fadeOut 250ms forwards;
      display: none;
    }
  `,
  label: css`
    font-size: 14px;
    color: ${colors.gray11};
    margin: 0;
    align-self: center;
  `,
});

export interface CheckboxProps {
  // `any` is used here as there is no other way to pass control without type errors
  control: Control<any>;
  label: string;
  name: string;

  className?: string;
  id?: string;
  defaultValue?: boolean;
  disabled?: boolean;
  validate?: Array<Validate<boolean, any>>;
}

export const Checkbox = ({
  control,
  label,
  name,
  className,
  id,
  defaultValue = false,
  disabled,
  validate = [],
}: CheckboxProps) => {
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));
  const [, { faro }] = useContext(TrackingContext);

  const {
    field: { value, onBlur, onChange },
  } = useController({
    name,
    control,
    defaultValue,
    rules: {
      validate: validate.reduce((acc, validator, index) => {
        acc[index.toString()] = validator;

        return acc;
      }, {} as Record<string, Validate<boolean, any>>),
    },
  });

  const handleContainerClick: MouseEventHandler<HTMLDivElement> = () => {
    onChange(!value);
  };

  const handleContainerKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onChange(!value);
    }
  };

  const handleContainerMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.detail > 1) {
      event.preventDefault();
    }
  };

  const handleCheckboxFocus: FocusEventHandler<HTMLDivElement> = () => {
    faro.trackCheckboxFocus(label);
  };

  const handleCheckboxBlur: FocusEventHandler<HTMLDivElement> = () => {
    faro.trackCheckboxBlur(label);
    onBlur();
  };

  return (
    <div
      aria-checked={value}
      aria-disabled={disabled}
      aria-labelledby={name}
      className={cx('checkbox', className, disabled && 'disabled')}
      css={styles.container}
      id={id}
      role="checkbox"
      onBlur={handleCheckboxBlur}
      onFocus={handleCheckboxFocus}
      onClick={!disabled ? handleContainerClick : noop}
      onKeyDown={!disabled ? handleContainerKeyDown : noop}
      onMouseDown={handleContainerMouseDown}
      tabIndex={!disabled ? 0 : -1}
    >
      <div className="indicator" css={styles.checkbox}>
        <Icon className={cx('tick', !value && 'hidden')} css={styles.icon} name="check" title="Checkbox icon" />
      </div>
      <label className="label" css={styles.label} id={name}>
        {label}
      </label>
    </div>
  );
};
