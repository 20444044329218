/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { Tooltip } from '@grafana/ui';
import cx from 'classnames';
import { FocusEventHandler, MouseEventHandler, useContext, useState, SyntheticEvent } from 'react';
import { Control, FieldErrors, useController, useFormState, Validate } from 'react-hook-form';

import { Icon } from 'components/Icon';
import { IconInline } from 'components/Icon/IconInline';
import { ErrorText, HintText, SuccessText, Text } from 'components/Text';
import { StylingContextState } from 'context/Styling';
import { palette } from 'context/Styling/palette';
import { TrackingContext } from 'context/Tracking';
import { noop } from 'utils/noop';
import { useStyles } from 'utils/useStyles';

const getStyles = ({ colors, typography }: StylingContextState) => ({
  columnContainer: css`
    display: flex;
    flex-flow: column nowrap;
    margin: 1.7rem 0;
    position: relative;
  `,
  label: css`
    margin: 0 0 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 23.2px */
    letter-spacing: -0.24px;

    p {
      color: ${colors.gray11};
    }

    @media (max-width: 550px) {
      font-size: 14px;
    }
  `,
  legend: css`
    font-size: 12px;
    font-weight: 200;
  `,
  rowContainer: css`
    align-items: center;
    background: ${colors.white};
    border: 1px solid var(--border-weak, rgba(36, 41, 46, 0.12));
    border-radius: 4px;

    display: flex;
    flex-flow: row nowrap;
    padding: 1rem 1.4rem;
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    width: 100%;
    color: rgba(36, 41, 46, 0.75);

    &:hover {
      background-color: ${colors.white};
      border-color: #24292e4d;
    }

    &.focused {
      border-color: ${colors.blue3};
      box-shadow: 0 0.1rem 0.6rem ${colors.blue2};
    }

    &.disabled {
      opacity: 0.6;
    }

    &.invalid:not(.focused) {
      border-color: ${colors.red3};
      box-shadow: none;
    }
  `,
  input: css`
    ${typography.text.normal};
    background-color: transparent;
    border: none;
    color: rgba(36, 41, 46, 0.75);
    filter: none;
    margin: 0;
    outline: none;
    width: 100%;

    &::placeholder {
      color: ${colors.gray11};
      opacity: 0.5;
    }

    &:-webkit-autofill {
      &,
      &:hover,
      &:focus {
        -webkit-background-clip: text !important;
        background-color: #f4f5f5;
        border-color: #f4f5f5;
      }

      &::first-line {
        ${typography.text.normal};
      }
    }

    @media (max-width: 550px) {
      font-size: 14px;
    }
  `,
  rightContainer: css`
    align-items: center;
    display: flex;

    > * + * {
      margin-left: 8px;
    }
  `,
  suffixBox: css`
    margin: 0;
    font-weight: 700;
  `,
  button: css`
    background: none;
    border: none;
    color: ${colors.gray3};
    cursor: pointer;
    display: inline-flex;
    outline: none;
    padding: 0;
    transition: all 300ms;

    &:focus {
      box-shadow: inset 0 0 0 0.2rem ${colors.blue3}, 0 0.1rem 0.6rem ${colors.blue2};
    }
  `,
  buttonIcon: css`
    height: 24px;
    width: 24px;

    &.red {
      color: ${colors.red3};
    }

    &.green {
      color: ${palette.greenLightMain};
    }

    &.hidden {
      display: none;
    }
  `,
  error: css`
    margin: 0.8rem 0 0;
  `,
  inlineInputIcon: css`
    margin-right: 6px;
    align-self: center;
  `,
  inputLabel: css`
    display: flex;
    flex: auto auto;
    flex-flow: row nowrap;
    justify-content: flex-start;
    column-gap: 4px;

    svg {
      width: min-content;
      margin-bottom: 2px;
      line-height: 0;
      display: flex;
      align-self: center;
      fill: rgba(36, 41, 46, 0.75);
    }
  `,
  inputLabelText: css`
    text-wrap: nowrap;
    width: min-content;
    display: flex;
    flex-direction: row;
    gap: 2px;
  `,
  labelIcon: css`
    height: 16px;
  `,
});

export interface InputProps {
  // `any` is used here as there is no other way to pass control without type errors
  control: Control<any>;
  label: string;
  name: string;
  type: 'text' | 'password' | 'email';

  autoFocus?: boolean;
  className?: string;
  id?: string;
  defaultValue?: string;
  disabled?: boolean;
  errors?: FieldErrors;
  hint?: string;
  onChange?: () => void;
  showLabel?: boolean;
  suffix?: string;
  validate?: Array<Validate<string, any>>;
  validMessage?: string;
  formatter?: (value: string) => string;
  legend?: string;
  placeholder?: string;
  infoTooltip?: string;
  infoTooltipPlacement?: string;
}

export const Input = ({
  control,
  label,
  name,
  type,
  autoFocus,
  className,
  id,
  defaultValue = '',
  disabled,
  errors,
  hint,
  onChange = noop,
  showLabel = false,
  suffix,
  validate = [],
  validMessage,
  formatter,
  legend,
  placeholder,
  infoTooltip,
  infoTooltipPlacement,
}: InputProps) => {
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));
  const [, { faro }] = useContext(TrackingContext);

  const [actualType, setActualType] = useState<InputProps['type']>(type);
  const [isFocused, setIsFocused] = useState(false);
  const formState = useFormState({ control });

  const {
    field: fieldProps,
    fieldState: { isDirty, error },
  } = useController({
    name,
    control,
    defaultValue,
    rules: {
      validate: validate.reduce((acc, validator, index) => {
        acc[index.toString()] = validator;

        return acc;
      }, {} as Record<string, Validate<string, any>>),
    },
  });

  const shouldShowIndicators = formState.isSubmitted || isDirty;
  const shouldShowValidIndicator = shouldShowIndicators && !error;
  const shouldShowInvalidIndicator = shouldShowIndicators && !!error;
  const isPasswordField = type === 'password';
  const hasSuffix = !!suffix;
  const shouldDisplayRightContainer = isPasswordField || hasSuffix || shouldShowIndicators;
  const isPasswordVisible = actualType === 'password';

  const handleInputChange = (event?: SyntheticEvent<HTMLInputElement>) => {
    if (formatter && event) {
      (event.target as HTMLInputElement).value = formatter((event.target as HTMLInputElement).value);
      (event.currentTarget as HTMLInputElement).value = formatter((event.currentTarget as HTMLInputElement).value);
    }

    fieldProps.onChange(event);
    onChange();
  };

  const handleInputFocus: FocusEventHandler<HTMLInputElement> = () => {
    faro.trackInputFocus(label);
    setIsFocused(true);
  };

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = () => {
    faro.trackInputBlur(label);
    fieldProps.onBlur();
    setIsFocused(false);
  };

  const handleTogglePasswordClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    faro.trackInputPasswordVisibilityClick(label);
    event.preventDefault();
    setActualType(isPasswordVisible ? 'text' : 'password');
  };

  return (
    <div className={cx('input', className)} css={styles.columnContainer} id={id}>
      {showLabel && (
        <Text align="left" className="label" color={'gray11'} css={styles.label} type="label">
          <span css={styles.inputLabel}>
            {infoTooltip ? (
              <>
                <Tooltip
                  content={<span>{infoTooltip}</span>}
                  placement={(infoTooltipPlacement as unknown as any) || 'right'}
                >
                  <span css={styles.inputLabelText}>
                    {label}
                    <IconInline title={infoTooltip} name="info-circle" size="sm" css={styles.labelIcon} />
                  </span>
                </Tooltip>
              </>
            ) : (
              <span css={styles.inputLabelText}>{label}</span>
            )}
          </span>
          {legend && <legend css={styles.legend}>{legend}</legend>}
        </Text>
      )}
      <div
        css={styles.rowContainer}
        className={cx({
          container: true,
          disabled,
          focused: isFocused,
          invalid: shouldShowInvalidIndicator,
        })}
      >
        {(name === 'password' || name === 'login') && (
          <IconInline title={name} name={name === 'login' ? 'person' : 'lock'} size="19" css={styles.inlineInputIcon} />
        )}
        <input
          aria-label={label}
          autoFocus={autoFocus}
          className="field"
          css={styles.input}
          disabled={disabled}
          placeholder={placeholder || label}
          type={actualType}
          {...fieldProps}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {shouldDisplayRightContainer && (
          <div className="right-container" css={styles.rightContainer}>
            {hasSuffix && (
              <Text align="left" className="suffix" color={'gray11'} css={styles.suffixBox}>
                {suffix}
              </Text>
            )}
            {isPasswordField && (
              <button
                aria-label="Toggle password visibility"
                className="password-toggle"
                css={styles.button}
                onClick={handleTogglePasswordClick}
                type="button"
              >
                <Icon
                  className={cx('show', isPasswordVisible && 'hidden')}
                  css={styles.buttonIcon}
                  name="eye-hidden-size-updated"
                  title="Show password"
                />
                <Icon
                  className={cx('hide', !isPasswordVisible && 'hidden')}
                  css={styles.buttonIcon}
                  name="eye-visible-size-updated"
                  title="Hide password"
                />
              </button>
            )}
            {shouldShowValidIndicator && (
              <IconInline
                className="valid-indicator green"
                css={styles.buttonIcon}
                name="check-darker"
                title="Input valid icon"
                size="xl"
              />
            )}
            {shouldShowInvalidIndicator && (
              <Icon
                className="invalid-indicator red"
                css={styles.buttonIcon}
                name="warning"
                title="Input invalid icon"
              />
            )}
          </div>
        )}
      </div>
      {shouldShowValidIndicator && !!validMessage && (
        <SuccessText align="left" className="valid-msg">
          {validMessage}
        </SuccessText>
      )}
      {shouldShowInvalidIndicator && !!errors?.[name]?.message && (
        <ErrorText align="left" className="invalid-msg">
          {String(errors[name]?.message ?? '')}
        </ErrorText>
      )}
      {!!hint && (
        <HintText align="left" className="hint">
          {hint}
        </HintText>
      )}
    </div>
  );
};
