import { css } from '@emotion/react/macro';
import { createContext } from 'react';

import { createColors } from 'context/Styling/createColors';
import { createTransitions } from 'context/Styling/createTransitions';

import { breakpoints } from './breakpoints';
import { StylingContextModel, StylingContextState } from './types';

const dummyCss = css``;

export const defaultStylingContextState: StylingContextState = {
  theme: 'light',
  breakpoints,
  colors: createColors({}),
  transitions: createTransitions(),
  typography: {
    size: {
      base: '',
      xs: '',
      sm: '',
      md: '',
      lg: '',
    },
    headings: {
      1: dummyCss,
      2: dummyCss,
      3: dummyCss,
      4: dummyCss,
    },
    text: {
      normal: dummyCss,
      small: dummyCss,
      tiny: dummyCss,
      label: dummyCss,
      large: dummyCss,
    },
  },
  viewport: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    lgHeight: false,
  },
};

export const StylingContext = createContext<StylingContextModel>(defaultStylingContextState);

StylingContext.displayName = 'StylingContext';
