import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { Header } from 'components/Header';
export interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const history = useHistory();
  return (
    <>
      {!history.location.pathname.includes('sign-up') && <Header />}
      {children}
    </>
  );
};
