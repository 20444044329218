import { createContext } from 'react';

import { PluginContextState } from './types';

export const defaultPluginContextState: PluginContextState = {
  plugin: null,
};

export const PluginContext = createContext(defaultPluginContextState);

PluginContext.displayName = 'PluginContext';
