/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { ReactNode } from 'react';

import { StylingContextState } from 'context/Styling';
import { useStyles } from 'utils/useStyles';

import { widths } from './widths';

type PanelWidth = 'wide' | 'medium' | 'narrow' | 'extraLarge' | 'full';
type PanelContentAlignment = 'flex-start' | 'center' | 'flex-end';

const getStyles = (
  { colors, viewport: { lg } }: StylingContextState,
  width: PanelWidth,
  align: PanelContentAlignment
) => {
  return {
    container: css`
      display: flex;
      flex-flow: column nowrap;
      justify-content: ${align};
      width: ${!width ? '506px' : widths[width]};

      @media (max-width: ${widths.narrow}) {
        width: 100%;
      }

      & + * {
        border-left: ${lg ? `0.1rem solid ${colors.gray1}` : 'none'};
        border-top: ${lg ? 'none' : `0.1rem solid ${colors.gray3}`};
        margin-left: ${lg ? '5rem' : '0'};
        margin-top: ${!lg ? '1.6rem' : '0'};
        padding-top: ${!lg ? '2rem' : '0'};
        padding-left: ${lg ? '5rem' : '0'};
      }

      @media (max-width: ${widths.narrow}) {
        justify-content: center;
      }
    `,
  };
};

export interface PagePanelProps {
  children: ReactNode;
  align?: PanelContentAlignment;
  className?: string;
  id?: string;
  labelledBy?: string;
  type?: PanelWidth;
}

export const PagePanel = ({
  children,
  align = 'flex-start',
  className,
  id,
  labelledBy,
  type = 'medium',
}: PagePanelProps) => {
  const styles = useStyles(getStyles, type, align);

  return (
    <section aria-label={labelledBy} className={cx('page-panel', className)} css={styles.container} id={id}>
      {children}
    </section>
  );
};
