import Cookies from 'js-cookie';

import { YEAR_IN_DAYS } from 'utils/consts';

export let canTrack = false;

const CAN_TRACK_COOKIE_NAME = 'consent';
const ANALYTICS_VERSION = '2';

const readCanTrackFromCookies = (): boolean => {
  let consentCookie: { analytics?: string };

  try {
    consentCookie = JSON.parse(Cookies.get(CAN_TRACK_COOKIE_NAME) ?? '{}');
  } catch (err) {
    consentCookie = {};
  }

  return consentCookie?.analytics === ANALYTICS_VERSION;
};

const readCanTrackFromApi = async (): Promise<boolean> => {
  try {
    const response = await fetch('/canspam');

    return response.status === 204;
  } catch (err) {
    return false;
  }
};

export const readCanTrack = async (): Promise<boolean> => {
  let canTrack = readCanTrackFromCookies();

  if (!canTrack) {
    canTrack = await readCanTrackFromApi();
  }

  return canTrack;
};

export const saveCanTrack = () => {
  canTrack = true;

  if (!readCanTrackFromCookies()) {
    Cookies.set(
      CAN_TRACK_COOKIE_NAME,
      JSON.stringify({
        analytics: ANALYTICS_VERSION,
      }),
      {
        expires: YEAR_IN_DAYS,
        sameSite: 'Lax',
        secure: location.protocol === 'https',
      }
    );
  }
};
