import { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useEffectOnce } from 'hooks/useEffectOnce';
import { redirectSetter } from 'pages/SignUp/customSignupHelpers';
import { CustomSignupProductType } from 'pages/SignUp/types';

import { defaultPersistentStateContextState, PersistentStateContext } from './persistentStateContext';
import { SetPersistentDataFn } from './types';

export interface PersistentStateProviderProps {
  children: ReactNode;
}

/** This PersistentStateProvider is designed to handle the specific pieces of custom data that need
 * to be accessed up and down the component tree, information that we dont want to keep in query params but
 * dont want to lose the values of altogether. Context Providers such as TrackingProvider and RedirectProvider
 * do some the heavy lifting here in gathering state data and then removing it from query params, but this
 * new Context Provider will keep track of some of this removed data because it is required by the custom signup
 * flow (see files in pages>Signup for more about custom signup flow, functions, and components).
 * Currently the usage here is limited, but given where this is in the component tree, it can hold a lot more.
 *
 */
export const PersistentStateProvider = ({ children }: PersistentStateProviderProps) => {
  const [persistentState, setPersistentState] = useState(defaultPersistentStateContextState);
  const { search } = useLocation();

  useEffectOnce(() => {
    const params = new URLSearchParams(search);
    const plcmtParam = params?.get('plcmt')!;
    const pgParam = params?.get('pg')!;
    const customSignupProduct = redirectSetter(search, plcmtParam, pgParam);

    setPersistentState((persistentState) => ({
      ...persistentState,
      initialized: true,
      customContent: customSignupProduct,
    }));
  });

  const setPersistentData: SetPersistentDataFn = (customContent = {} as CustomSignupProductType) =>
    setPersistentState({
      ...persistentState,
      customContent,
    });

  return (
    <PersistentStateContext.Provider
      value={[
        persistentState,
        {
          setPersistentData,
        },
      ]}
    >
      {persistentState.initialized ? children : null}
    </PersistentStateContext.Provider>
  );
};
