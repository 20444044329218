import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { Page, PagePanel } from 'components/Page';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { SignInErrorCodes } from 'pages/SignIn/errorCodes';
import { signInRoutes } from 'pages/SignIn/routes';

export const PostSSOError = () => {
  const history = useHistory();

  useEffectOnce(() => {
    history.push(signInRoutes.base, {
      errorCode: SignInErrorCodes.INVALID_USER,
    });
  });

  return (
    <Page heading="Redirecting" title="Redirecting">
      <PagePanel id="panel">
        <Loader id="loader" label="This should not take longer than a few seconds." />
      </PagePanel>
    </Page>
  );
};
