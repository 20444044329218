import {
  faro,
  FaroErrorBoundary as FaroOriginalErrorBoundary,
  FaroRoute as FaroOriginalRoute,
  ReactIntegration,
  ReactRouterHistory,
  ReactRouterVersion,
} from '@grafana/faro-react';
import {
  getWebInstrumentations,
  initializeFaro as initializeFaroWeb,
  ConsoleInstrumentation,
  ConsoleTransport,
  ErrorsInstrumentation,
  WebVitalsInstrumentation,
  Transport,
  Instrumentation,
} from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { Fragment } from 'react';
import { Route as ReactRoute } from 'react-router-dom';

import {
  APM_ENABLED,
  APP_ENVIRONMENT,
  APP_VERSION,
  FARO_APP,
  FARO_ENABLED,
  FARO_INSTRUMENTATION_CONSOLE_ENABLED,
  FARO_INSTRUMENTATION_ERRORS_ENABLED,
  FARO_INSTRUMENTATION_RUM_ENABLED,
  FARO_INSTRUMENTATION_WEB_VITALS_ENABLED,
  FARO_KEY,
  FARO_URL,
  IS_HOSTNAME_MATCHING,
} from 'utils/consts';
import { noop, noopPromise } from 'utils/noop';

const isFaroEnabled = APM_ENABLED && FARO_ENABLED && IS_HOSTNAME_MATCHING;
const isRumEnabled = isFaroEnabled && FARO_INSTRUMENTATION_RUM_ENABLED;
const IGNORE_URLS = [new RegExp(/.*.rudderstack*/)];

export const initializeFaro = isFaroEnabled
  ? async (history: ReactRouterHistory) => {
      let transports: Transport[] | undefined;
      const instrumentations = [
        ...getWebInstrumentations({
          captureConsole: true,
        }),

        new TracingInstrumentation({
          instrumentations: [
            new FetchInstrumentation({
              ignoreUrls: IGNORE_URLS,
              propagateTraceHeaderCorsUrls: [
                new RegExp('https://grafana.com/auth/*'),
                new RegExp('https://*/api/health'),
              ],
            }),
          ],
        }),

        new ReactIntegration({
          router: {
            version: ReactRouterVersion.V5,
            dependencies: {
              Route: ReactRoute,
              history,
            },
          },
        }),
      ] as Instrumentation[];

      if (!FARO_URL || !FARO_KEY) {
        transports = [new ConsoleTransport()];
      }

      if (FARO_INSTRUMENTATION_CONSOLE_ENABLED) {
        instrumentations.push(new ConsoleInstrumentation());
      }

      if (FARO_INSTRUMENTATION_ERRORS_ENABLED) {
        instrumentations.push(new ErrorsInstrumentation());
      }

      if (FARO_INSTRUMENTATION_WEB_VITALS_ENABLED) {
        instrumentations.push(new WebVitalsInstrumentation());
      }

      initializeFaroWeb({
        url: `${FARO_URL}/${FARO_KEY}`,
        transports,
        instrumentations,
        app: {
          name: FARO_APP,
          version: APP_VERSION,
          environment: APP_ENVIRONMENT,
        },
        ignoreUrls: IGNORE_URLS,
        ignoreErrors: [
          'Invalid login name',
          'Invalid password',
          'That email is already registered',
          'Invite already accepted',
          'Value must be an alphanumeric string: shortKey',
          'Possible side-effect in debug-evaluate',
          'The node to be removed is not a child of this node.',
          'Email already confirmed',
          'Login Required',
          'Please try again later.',
          'Too many requests. Try again later.',
          'Too many login attempts. Try again later.',
          'Hosted instance limit reached',
        ],
      });
    }
  : noopPromise;

export const trackEventFaro = isRumEnabled
  ? (type: string, data?: Record<string, string>) => {
      faro?.api?.pushEvent(type, data);
    }
  : noop;

export const trackButtonClickFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('button_click', { label });
    }
  : noop;

export const trackCheckboxBlurFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('checkbox_blur', { label });
    }
  : noop;

export const trackCheckboxFocusFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('checkbox_focus', { label });
    }
  : noop;

export const trackFormSubmitFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('form_submit', { label });
    }
  : noop;

export const trackFormSubmitErrorFaro = isRumEnabled
  ? (label: string, message?: string) => {
      trackEventFaro('form_submit_error', { label, message: message ?? '' });
    }
  : noop;

export const trackFormSubmitSuccessFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('form_submit_success', { label });
    }
  : noop;

export const trackInputBlurFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('input_blur', { label });
    }
  : noop;

export const trackInputFocusFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('input_focus', { label });
    }
  : noop;

export const trackInputPasswordVisibilityClickFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('input_password_visibility_click', { label });
    }
  : noop;

export const trackLinkClickFaro = isRumEnabled
  ? (label: string, linkPath: string) => {
      trackEventFaro('link_click', { label, linkPath });
    }
  : noop;

export const trackSelectBlurFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('select_blur', { label });
    }
  : noop;

export const trackSelectFocusFaro = isRumEnabled
  ? (label: string) => {
      trackEventFaro('select_focus', { label });
    }
  : noop;

export const trackPageViewFaro = isRumEnabled
  ? (path: string) => {
      trackEventFaro('page_view', { path });
    }
  : noop;

export const trackErrorFaro = FARO_ENABLED
  ? (err: Error, errorType?: string) => {
      faro?.api?.pushError(err, { type: errorType });
    }
  : noop;

export const FaroErrorBoundary = FARO_ENABLED ? FaroOriginalErrorBoundary : Fragment;

export const FaroRoute = FARO_ENABLED ? FaroOriginalRoute : ReactRoute;
