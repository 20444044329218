/** @jsxImportSource @emotion/react */
import cx from 'classnames';
import { useEffect, useState } from 'react';

import { PUBLIC_URL } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

import { customSignupStyles } from './CustomSignup.styles';

export const BulletRenderer = ({
  listItems,
  isNumber = false,
  renderBulletImages = false,
  shouldAnimate = false,
}: {
  listItems: string[] | string | JSX.Element;
  isNumber?: boolean;
  renderBulletImages?: boolean;
  shouldAnimate?: boolean;
}) => {
  const [shouldShowListItem, setShouldShowListItem] = useState(!shouldAnimate);
  const [list, setList] = useState<any[]>(() => (shouldAnimate ? [] : (listItems as string[])));
  const styles = useStyles((theme) => customSignupStyles(theme, false, shouldShowListItem, shouldAnimate));

  useEffect(() => {
    if (shouldAnimate) {
      setShouldShowListItem(true);

      if (list.length === 0) {
        // for animations we want to start out with one item in the list so the animation is anchored
        setList([...list, (listItems as string[]).shift()]);
      } else {
        // this setTimeout will add one more item to the list every 1.5 seconds
        if (Array.isArray(listItems)) {
          setTimeout(() => {
            if (listItems.length !== 0) {
              const nextItem = listItems.shift();

              if (!list.includes(nextItem)) {
                setList([...list, nextItem]);
              }
            }
          }, 1500);
        }
      }
    }
  }, [listItems, shouldAnimate, list]);

  return (
    <ul data-testid={'bullet-renderer-list'} css={styles.ulStyle}>
      {Array.isArray(list) ? (
        list.map((item, index) => {
          return (
            <li key={index} className={cx('list-item')} css={styles.liStyle}>
              {!renderBulletImages && (
                <span>
                  {!isNumber ? (
                    <img
                      data-testid={'bullet-checkbox'}
                      src={`${PUBLIC_URL}/img/icons/checkbox.svg`}
                      alt={'checkbox.svg'}
                      css={styles.bulletCheckbox}
                    />
                  ) : (
                    <div css={styles.bulletNumber}>{(index += 1)}</div>
                  )}
                </span>
              )}
              <span css={styles.li}>{item}</span>
            </li>
          );
        })
      ) : (
        <>{listItems}</>
      )}
    </ul>
  );
};
