/** @jsxImportSource @emotion/react */

import { Heading } from 'components/Heading';
import { CustomSignupProps } from 'pages/SignUp/types';
import { useStyles } from 'utils/useStyles';

import { BulletRenderer } from './BulletRenderer';
import { customSignupStyles } from './CustomSignup.styles';

export const CustomSignup = ({ customContent }: CustomSignupProps): JSX.Element => {
  const styles = useStyles((theme) => customSignupStyles(theme, !!customContent.description));
  // const isDashboard = customContent && Object.keys(customContent).includes('isDashboard');
  const { isHeroImg, isNumber } = customContent && customContent;

  return (
    <div key={customContent.key}>
      <Heading type="3" css={styles.headingSpacing}>
        <div css={styles.iconsWrapper}>
          {!isHeroImg && customContent.imgSrc.length > 0 ? (
            <picture css={styles.iconImg}>
              <img src={`${customContent.imgSrc}`} alt={`${customContent.imgAlt} logo`} />
            </picture>
          ) : isHeroImg ? (
            <picture css={styles.dashboardImg}>
              <img src={`${customContent.imgSrc}`} alt={`${customContent.imgAlt} logo`} />
            </picture>
          ) : null}
          <div css={styles.header}>{customContent.title}</div>
          {customContent.description && <h4 css={styles.h4}>{customContent.description}</h4>}
        </div>
      </Heading>
      <div css={styles.textWrapper}>
        <BulletRenderer listItems={customContent.subtitle} isNumber={isNumber} />
      </div>
    </div>
  );
};
