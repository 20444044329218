/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorBox } from 'components/ErrorBox';
import { Loader } from 'components/Loader';
import { LoaderTypes } from 'components/Loader/types';
import { Page, PagePanel } from 'components/Page';
import { SSOProvidersKeys } from 'components/SSOButtons';
import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { SSOProvider, UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { Endpoints, get, post } from 'utils/api';
import { useStyles } from 'utils/useStyles';

import { invitesRoutes } from './routes';
import { InviteData } from './types';

export const Accept = () => {
  const [
    {
      userData,
      userMeta: { userIsLoggedIn },
    },
    { getUser, setUser },
  ] = useContext(UserContext);
  const [, { setOrgId }] = useContext(RedirectContext);
  const [, { faro }] = useContext(TrackingContext);
  const [serverError, setServerError] = useState<string | null>(null);
  const history = useHistory();
  const styles = useStyles(getStyles);

  useEffectOnce(() => {
    const acceptInvite = async () => {
      try {
        const queryParams = new URLSearchParams(history.location.search);
        const key = queryParams.get('key');
        const redirectApp = queryParams.get('redirectApp');
        const redirectStack = queryParams.get('redirectStack');

        if (!key) {
          setServerError('Invite key is missing');
        } else {
          let inviteData = await get<InviteData>(`${Endpoints.CHECK_INVITE}/${key}`);
          let ssoProviders = [];

          const ssoProvidersData = (await get<SSOProvider[]>(
            `${Endpoints.GET_ORG_SSO_PROVIDERS}/${key}`
          )) as SSOProvider[];
          for (const provider of ssoProvidersData as SSOProvider[]) {
            let ssoProviderKey: SSOProvidersKeys = provider.slug as SSOProvidersKeys;
            ssoProviders.push({
              key: ssoProviderKey,
              name: provider.name,
            });
          }

          const inviteState = {
            data: inviteData,
            key,
            ssoProviders: ssoProviders,
            redirectApp: redirectApp,
            redirectStack: redirectStack,
          };

          setOrgId(inviteData.orgId ?? null);

          if (userIsLoggedIn && inviteData.email !== userData!.email) {
            history.push(invitesRoutes.invalidUser, inviteState);
          } else {
            if (inviteData.userStatus === 'active') {
              await post<boolean>(Endpoints.ACCEPT_INVITE, {
                key,
              });

              const newUserData = await getUser();

              await setUser(newUserData);

              history.push(invitesRoutes.accepted, inviteState);
            } else {
              history.push(invitesRoutes.createUser, inviteState);
            }
          }
        }
      } catch (err) {
        if (err.code === 'InvalidArgument') {
          history.push(
            err.message === 'Invite already accepted' ? invitesRoutes.alreadyAccepted : invitesRoutes.expired
          );
        } else {
          faro.trackError(err);
          setServerError(err.message);
        }
      }
    };

    acceptInvite();
  });

  return (
    <Page heading="Accepting invite" title="Invites - Accepting">
      <PagePanel id="panel">
        {serverError ? (
          <ErrorBox id="server-error">{serverError}</ErrorBox>
        ) : (
          <Loader
            css={styles.centeredParas}
            id="loader"
            type={LoaderTypes.SPINNER}
            label="This should not take longer than a few seconds."
          />
        )}
      </PagePanel>
    </Page>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  centeredParas: css`
    text-align: center;
    color: ${colors.gray11};
    place-tiems: center;
    margin-top: 16px;
  `,
});
