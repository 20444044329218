import { useHistory, useLocation } from 'react-router-dom';

import { Page, PagePanel } from 'components/Page';
import { SSOButtons } from 'components/SSOButtons';
import { SpacedText } from 'components/Text';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { redirectToRoutes } from 'pages/RedirectTo/routes';
import { signInRoutes } from 'pages/SignIn/routes';

import { InviteState } from './types';

export const CreateUserFromSSO = () => {
  const { state: { data, key, ssoProviders } = {} } = useLocation<InviteState>();
  const history = useHistory();

  useEffectOnce(() => {
    if (!ssoProviders || ssoProviders.length === 0) {
      history.push(signInRoutes.base);
    }
  });

  return (
    <Page heading="Accept invite" title="Invites - Create User">
      <PagePanel id="panel">
        <SpacedText align="center" id="description">
          Choose an SSO provider to finish setting up your Grafana Cloud account and get added to the {data?.orgName}{' '}
          organization.
        </SpacedText>
        <SSOButtons
          inviteKey={key}
          disableUserCreation={false}
          ssoProviders={ssoProviders || []}
          disabled={false}
          redirectRoute={redirectToRoutes.postSso}
          redirectErrorRoute={redirectToRoutes.postSsoError}
        />
      </PagePanel>
    </Page>
  );
};
