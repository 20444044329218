import { HOTJAR_ID, HOTJAR_SV } from 'utils/consts';
import { noop } from 'utils/noop';

export interface WindowProperties {
  hj: {
    (...args: unknown[]): void;
    q: unknown[];
  };
  _hjSettings: {
    hjid: number;
    hjsv: number;
  };
}

const isEnabled = !!HOTJAR_ID && !!HOTJAR_SV;

export const initialize = isEnabled
  ? () => {
      window.hj =
        window.hj ??
        ((...args) => {
          (window.hj.q = window.hj.q ?? []).push(args);
        });

      window._hjSettings = { hjid: HOTJAR_ID!, hjsv: HOTJAR_SV! };
    }
  : noop;

export const load = isEnabled
  ? () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://static.hotjar.com/c/hotjar-${HOTJAR_ID}.js?sv=${HOTJAR_SV}`;

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  : noop;
