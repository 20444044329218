import { useContext } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';

import { RedirectContext } from 'context/Redirect';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { signUpRoutes } from 'pages/SignUp/routes';
import { FaroRoute } from 'utils/faro';

import { CheckInbox } from './CheckInbox';
import { Recover } from './Recover';
import { forgotPasswordRoutes } from './routes';

export const ForgotPassword = () => {
  const [{ instance, to }, { redirectToStateInstanceHome, redirectToPortal, redirectToTo }] =
    useContext(RedirectContext);
  const [
    {
      userMeta: { userIsLoggedIn, userHasConfirmedEmail },
    },
  ] = useContext(UserContext);
  const history = useHistory();

  useEffectOnce(() => {
    if (userIsLoggedIn) {
      if (!userHasConfirmedEmail) {
        history.push(signUpRoutes.checkInbox);
      } else if (to) {
        redirectToTo();
      } else if (instance) {
        redirectToStateInstanceHome();
      } else {
        redirectToPortal();
      }
    }
  });

  if (userIsLoggedIn) {
    return null;
  }

  return (
    <Switch>
      <FaroRoute path={forgotPasswordRoutes.recover} component={Recover} />
      <FaroRoute path={forgotPasswordRoutes.checkInbox} component={CheckInbox} />
      <Redirect exact from={forgotPasswordRoutes.base} to={forgotPasswordRoutes.recover} />
    </Switch>
  );
};
