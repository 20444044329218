const isDatasourceStringIncluded = (pluginName: string) => pluginName.match(/(data)( )?(source)/i);
const isPluginStringIncluded = (pluginName: string) => pluginName.match(/(plugin)/i);

export enum PluginTextTypes {
  List_Title,
  List_Text,
  Title,
}

export const getPluginText = (pluginName: string, pluginTextType: PluginTextTypes) => {
  switch (pluginTextType) {
    case PluginTextTypes.Title:
      return `to visualize ${pluginName} ${isDatasourceStringIncluded(pluginName) ? '' : 'data'}`;
    case PluginTextTypes.List_Title:
      return `${pluginName} ${isPluginStringIncluded(pluginName) ? '' : 'Plugin'}`;
    case PluginTextTypes.List_Text:
      return `Create a ${pluginName} ${
        isDatasourceStringIncluded(pluginName) ? 'to' : 'data source and'
      } visualize your data.`;
  }
};
