import { createContext } from 'react';

import { noop } from 'utils/noop';

import { PersistentStateContextActions, PersistentStateContextModel, PersistentStateContextState } from './types';

export const defaultPersistentStateContextState: PersistentStateContextState = {
  initialized: false,
  customContent: undefined,
};

export const defaultPersistentStateContextActions: PersistentStateContextActions = {
  setPersistentData: noop,
};

export const PersistentStateContext = createContext<PersistentStateContextModel>([
  defaultPersistentStateContextState,
  defaultPersistentStateContextActions,
]);

PersistentStateContext.displayName = 'PersistentStateContext';
