import { LINKEDIN_KEY } from 'utils/consts';
import { noop } from 'utils/noop';

export interface WindowProperties {
  _linkedin_data_partner_ids: unknown[];
}

const isEnabled = !!LINKEDIN_KEY;

export const initialize = isEnabled
  ? () => {
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids ?? [];
      window._linkedin_data_partner_ids.push(LINKEDIN_KEY);
    }
  : noop;

export const load = isEnabled
  ? () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://snap.licdn.com/li.lms-analytics/insight.min.js`;

      document.getElementsByTagName('head')[0].appendChild(script);

      const pixel = document.createElement('img');
      pixel.alt = '';
      pixel.height = 1;
      pixel.src = `https://px.ads.linkedin.com/collect/?pid=${LINKEDIN_KEY}&fmt=gif`;
      pixel.style.display = 'none';
      pixel.width = 1;

      const noScript = document.createElement('noscript');
      noScript.append(pixel);

      document.getElementsByTagName('body')[0].append(noScript);
    }
  : noop;
