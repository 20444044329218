import { createContext } from 'react';

import {
  assignUserToOrganisations,
  getReferrers,
  getSources,
  getSourcesAsQueryParams,
  getSourcesAsTrackingParams,
  identifyUser,
  resetUser,
  trackEvent,
  trackPage,
  getSSItem,
  clearSSItem,
} from 'tracking';
import { trackErrorFaro } from 'utils/faro';
import { noop } from 'utils/noop';

import { TrackingContextActions, TrackingContextModel, TrackingContextState } from './types';

export const defaultTrackingContextState: TrackingContextState = {
  initialized: false,
  trackingData: {
    canTrack: false,
  },
  trackingMeta: {
    canTrackIsLoading: true,
  },
};

export const defaultTrackingContextActions: TrackingContextActions = {
  startTracking: noop,
  identifyUser,
  resetUser,
  assignUserToOrganisations,
  trackEvent,
  trackPage,
  getSources,
  getSourcesAsTrackingParams,
  getSourcesAsQueryParams,
  getReferrers,
  getSSItem,
  clearSSItem,

  faro: {
    trackError: trackErrorFaro,
    trackEvent: noop,
    trackButtonClick: noop,
    trackCheckboxBlur: noop,
    trackCheckboxFocus: noop,
    trackFormSubmit: noop,
    trackFormSubmitError: noop,
    trackFormSubmitSuccess: noop,
    trackInputBlur: noop,
    trackInputFocus: noop,
    trackInputPasswordVisibilityClick: noop,
    trackLinkClick: noop,
    trackSelectBlur: noop,
    trackSelectFocus: noop,
  },
};

export const TrackingContext = createContext<TrackingContextModel>([
  defaultTrackingContextState,
  defaultTrackingContextActions,
]);

TrackingContext.displayName = 'TrackingContext';
