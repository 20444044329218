/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { ReactNode } from 'react';

import { palette } from 'context/Styling/palette';
import { useStyles } from 'utils/useStyles';

import { Text, TextProps } from './Text';

const getStyles = () =>
  css`
    margin: 4px 0 0;
    color: ${palette.greenLightMain};
  `;

export interface SuccessTextProps {
  children: ReactNode;

  align?: TextProps['align'];
  className?: string;
  id?: string;
}

export const SuccessText = ({ children, align, className, id }: SuccessTextProps) => {
  const styles = useStyles(getStyles);

  return (
    <Text align={align} color="green" className={cx('success-text', className)} css={styles} id={id} type="small">
      {children}
    </Text>
  );
};
