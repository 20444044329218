import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { Page, PagePanel } from 'components/Page';
import { RedirectContext } from 'context/Redirect';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { signInRoutes } from 'pages/SignIn/routes';
import { signUpRoutes } from 'pages/SignUp/routes';

export const Portal = () => {
  const [
    {
      userMeta: { userIsLoggedIn, userHasConfirmedEmail },
    },
  ] = useContext(UserContext);
  const [, { redirectToPortal }] = useContext(RedirectContext);
  const history = useHistory();

  useEffectOnce(() => {
    if (!userIsLoggedIn) {
      history.push(signInRoutes.base);
    } else if (!userHasConfirmedEmail) {
      history.push(signUpRoutes.checkInbox);
    } else {
      redirectToPortal();
    }
  });

  return !userIsLoggedIn || !userHasConfirmedEmail ? null : (
    <Page heading="Redirecting to the portal" title="Redirect to portal">
      <PagePanel id="panel">
        <Loader id="loader" label="This should not take longer than a few seconds." />
      </PagePanel>
    </Page>
  );
};
