/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Page, PagePanel } from 'components/Page';
import { RedirectContext } from 'context/Redirect';
import { StackRegionProvider } from 'context/StackRegion';
import { StylingContextState } from 'context/Styling';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { Endpoints, post } from 'utils/api';
import { useStyles } from 'utils/useStyles';

import { signUpRoutes } from './routes';
import { SelectOrganisationForm } from './SelectOrganisationForm';

export const SelectOrganisation = () => {
  const [
    {
      userMeta: { userIsLoggedIn, userHasConfirmedEmail, userHasOrganisations },
    },
  ] = useContext(UserContext);
  const [{ instance, to }, { redirectToStateInstanceHome, redirectToTo, redirectToPortal }] =
    useContext(RedirectContext);
  const history = useHistory();
  const styles = useStyles(getStyles);

  useEffectOnce(() => {
    const trackTrial = async () => {
      await post(Endpoints.TRACK_TRIAL, {});
    };

    if (!userIsLoggedIn) {
      history.push(signUpRoutes.createUser);
    } else if (!userHasConfirmedEmail) {
      history.push(signUpRoutes.checkInbox);
    } else if (to) {
      redirectToTo();
    } else if (instance) {
      redirectToStateInstanceHome();
    } else if (userHasOrganisations) {
      redirectToPortal();
    } else {
      trackTrial();
    }
  });

  if (!userIsLoggedIn || !userHasConfirmedEmail || to || instance || userHasOrganisations) {
    return null;
  }

  return (
    <StackRegionProvider>
      <Page
        heading="Set up your first Grafana stack"
        title="Sign Up - Select Organisation"
        headerIcon="create-stack"
        css={styles.createUserPageStyleOverride}
      >
        <PagePanel id="panel" type="full">
          <SelectOrganisationForm />
        </PagePanel>
      </Page>
    </StackRegionProvider>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  createUserPageStyleOverride: css`
    #page-content {
      background-color: ${colors.white};
      box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.05) !important;
      width: 610px;

      @media (max-width: 768px) {
        margin: 24px;
        padding: 48px;
        width: 90%;
      }

      @media (max-width: 550px) {
        padding: 24px 0;
        max-width: 414px;
      }
      @media (max-width: 400px) {
        h1 {
          padding: 0 12px;
        }
      }
    }
    #page-panels,
    #panel {
      width: 510px;

      @media (max-width: 768px) {
        padding: 0px;
        margin: 0px;
        width: 100%;
      }

      @media (max-width: 550px) {
        padding: 0 24px;
      }
    }
  `,
});
