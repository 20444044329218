import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { LoaderTypes } from 'components/Loader/types';
import { Page, PagePanel } from 'components/Page';
import { RedirectContext } from 'context/Redirect';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { signInRoutes } from 'pages/SignIn/routes';
import { signUpRoutes } from 'pages/SignUp/routes';

export const PostSSO = () => {
  const [
    {
      userMeta: { userIsLoggedIn, userHasConfirmedEmail, userHasOrganisations },
    },
  ] = useContext(UserContext);
  const [{ instance, to }, { redirectToStateInstanceHome, redirectToTo, redirectToPortalOrInstanceHome }] =
    useContext(RedirectContext);
  const history = useHistory();

  useEffectOnce(() => {
    if (!userIsLoggedIn) {
      history.push(signInRoutes.base);
    } else if (!userHasConfirmedEmail) {
      history.push(signUpRoutes.checkInbox);
    } else if (to) {
      redirectToTo();
    } else if (instance) {
      redirectToStateInstanceHome();
    } else if (!userHasOrganisations) {
      history.push(signUpRoutes.selectOrganisation);
    } else {
      redirectToPortalOrInstanceHome();
    }
  });

  return !userIsLoggedIn || !userHasConfirmedEmail ? null : (
    <Page heading="Loading your account" title="Loading your account">
      <PagePanel id="panel">
        <Loader id="loader" label="This should not take longer than a few seconds." type={LoaderTypes.SPINNER} />
      </PagePanel>
    </Page>
  );
};
