import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { Page, PagePanel } from 'components/Page';
import { RedirectContext } from 'context/Redirect';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { signInRoutes } from 'pages/SignIn/routes';
import { signUpRoutes } from 'pages/SignUp/routes';

import { redirectToRoutes } from './routes';

export const Instance = () => {
  const [
    {
      userMeta: { userIsLoggedIn, userHasConfirmedEmail },
    },
  ] = useContext(UserContext);
  const [
    { instance },
    {
      redirectToStateInstanceHome,
      redirectToStateInstanceOnboarding,
      redirectToComputedInstanceHome,
      redirectToComputedInstanceOnboarding,
    },
  ] = useContext(RedirectContext);
  const { pathname } = useLocation();
  const history = useHistory();

  useEffectOnce(() => {
    const toOnboarding = pathname === redirectToRoutes.instanceOnboarding;

    if (!userIsLoggedIn) {
      history.push(signInRoutes.base);
    } else if (!userHasConfirmedEmail) {
      history.push(signUpRoutes.checkInbox);
    } else if (instance) {
      if (toOnboarding) {
        redirectToStateInstanceOnboarding();
      } else {
        redirectToStateInstanceHome();
      }
    } else {
      if (toOnboarding) {
        redirectToComputedInstanceOnboarding();
      } else {
        redirectToComputedInstanceHome();
      }
    }
  });

  return !userIsLoggedIn || !userHasConfirmedEmail ? null : (
    <Page heading="Redirecting to your instance" title="Redirect to Instance">
      <PagePanel id="panel">
        <Loader id="loader" label="This should not take longer than a few seconds." />
      </PagePanel>
    </Page>
  );
};
