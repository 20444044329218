import { ReactNode, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TrackingContext } from 'context/Tracking';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { Endpoints, get } from 'utils/api';

import { GetUserFn, SetUserFn, SignOutFn, User } from './types';
import { defaultUserContextState, UserContext } from './userContext';

export interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [userState, setUserState] = useState(defaultUserContextState);
  const [, { identifyUser, assignUserToOrganisations, resetUser }] = useContext(TrackingContext);
  const history = useHistory();

  const setUser: SetUserFn = useCallback(
    async (data) => {
      setUserState((userState) => ({
        ...userState,
        userData: data,
        userMeta: {
          userIsLoggedIn: !!data,
          userHasConfirmedEmail: !!data?.emailConfirmed,
          userHasOrganisations: (data?.memberships ?? []).length > 0,
          userIsLoading: false,
        },
      }));

      if (data) {
        await identifyUser({
          company: data.company,
          email: data.email,
          firstName: data.firstName,
          id: data.id,
          lastName: data.lastName,
        });

        assignUserToOrganisations(
          (data.memberships ?? []).map((organisation) => ({
            id: organisation.orgId,
            slug: organisation.orgSlug,
            role: organisation.role,
          }))
        );
      }
    },
    [assignUserToOrganisations, identifyUser]
  );

  const getUser: GetUserFn = async () => {
    let userData;

    try {
      userData = await get<User>(Endpoints.GET_USER_PROFILE);
    } catch (err) {
      userData = null;
    }

    return userData;
  };

  const signOut: SignOutFn = async (redirectUrl, state) => {
    await get(Endpoints.SIGN_OUT);
    resetUser();
    await setUser(null);

    if (redirectUrl) {
      history.push(redirectUrl, state ?? {});
    }
  };

  const fetchUser = useCallback(async () => {
    const user = await getUser();
    await setUser(user);
  }, [setUser]);

  useEffectOnce(() => {
    fetchUser();
  });

  return (
    <UserContext.Provider value={[userState, { getUser, setUser, signOut }]}>
      {userState.userMeta.userIsLoading ? null : children}
    </UserContext.Provider>
  );
};
