import { Button } from 'components/Button';
import { Page, PagePanel } from 'components/Page';
import { SpacedText, Text } from 'components/Text';
import { signUpRoutes } from 'pages/SignUp/routes';

export const Expired = () => {
  return (
    <Page heading="Failed to accept invite" title="Invites - Expired">
      <PagePanel id="panel" type="full">
        <div style={{ backgroundColor: 'white' }}>
          <Text align="center" id="description-first" color="gray11">
            Oh no! Looks like the invitation is no longer valid.
          </Text>
          <SpacedText align="center" id="description-second">
            No worries, you can still use Grafana on your own. We have a free forever plan that you can start using
            right away.
          </SpacedText>
          <Button
            variant="primary"
            id="sign-up-btn"
            label="Sign up for free"
            role="link"
            href={signUpRoutes.createUser}
          />
        </div>
      </PagePanel>
    </Page>
  );
};
