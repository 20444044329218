import { CLEARBIT_KEY, CLEARBIT_SNIPPET_VERSION } from 'utils/consts';
import { noop } from 'utils/noop';

import { UserTraits } from './types';

enum ClearbitMethod {
  Alias = 'alias',
  Group = 'group',
  Identify = 'identify',
  Off = 'off',
  On = 'on',
  Once = 'once',
  Page = 'page',
  Pageview = 'pageview',
  Ready = 'ready',
  Reset = 'reset',
  Track = 'track',
  TrackClick = 'trackClick',
  TrackForm = 'trackForm',
  TrackLink = 'trackLink',
  TrackSubmit = 'trackSubmit',
}

type ClearbitFn = (...args: unknown[]) => Clearbit;

type MethodType = Record<ClearbitMethod, ClearbitFn>;

export interface Clearbit extends MethodType {
  factory: (t: string) => ClearbitFn;
  invoked: boolean;
  initialize: boolean;
  methods: ClearbitMethod[];
  push: ([]) => void;
  SNIPPET_VERSION: string | undefined;
}

export interface WindowProperties {
  clearbit: Clearbit;
}

const isEnabled = !!CLEARBIT_KEY && !!CLEARBIT_SNIPPET_VERSION;

export const initialize = isEnabled
  ? () => {
      window.clearbit = window.clearbit ?? [];

      if (!window.clearbit.initialize && !window.clearbit.invoked) {
        window.clearbit.invoked = true;
        window.clearbit.methods = Object.values(ClearbitMethod);
        window.clearbit.factory =
          (t: string) =>
          (...args: unknown[]) => {
            const e = Array.prototype.slice.call(args);
            e.unshift(t);
            window.clearbit.push(e);

            return window.clearbit;
          };

        window.clearbit.methods.forEach((method) => {
          window.clearbit[method] = window.clearbit.factory(method);
        });

        window.clearbit.SNIPPET_VERSION = CLEARBIT_SNIPPET_VERSION;
      }
    }
  : noop;

export const load = isEnabled
  ? () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://x.clearbitjs.com/v1/${CLEARBIT_KEY}/clearbit.js`;

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  : noop;

export const trackPage = isEnabled
  ? () => {
      window.clearbit?.page();
    }
  : noop;

export const identifyUser = isEnabled
  ? ({ id, ...traits }: UserTraits) => {
      if (id) {
        window.clearbit?.identify(id, traits);
      } else {
        window.clearbit?.identify(traits);
      }
    }
  : noop;

export const resetUser = isEnabled
  ? () => {
      window.clearbit?.reset();
    }
  : noop;
