/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, ButtonProps } from 'components/Button';
import { Page, PagePanel } from 'components/Page';
import { SpacedText } from 'components/Text';
import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { signInRoutes } from 'pages/SignIn/routes';
import { useStyles } from 'utils/useStyles';

import { InviteState } from './types';

export const Accepted = () => {
  const styles = useStyles(getStyles);
  const { state: { data } = {} } = useLocation<InviteState>();
  const [, { redirectToComputedInstanceHome }] = useContext(RedirectContext);
  const history = useHistory();

  useEffectOnce(() => {
    if (!data) {
      history.push(signInRoutes.base);
    }
  });

  const handleAccessGrafanaClick: ButtonProps['onClick'] = () => {
    redirectToComputedInstanceHome();
  };

  return (
    <Page heading="Invite accepted" title="Invites - Create User">
      <PagePanel id="panel" css={styles.centeredParas}>
        <SpacedText align="center" id="description">
          You are now a member of {data?.orgName} organization on Grafana Cloud.
        </SpacedText>
        <Button variant="primary" id="access-grafana-link" label="Access Grafana" onClick={handleAccessGrafanaClick} />
      </PagePanel>
    </Page>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  centeredParas: css`
    text-align: center;
    color: ${colors.gray11};
    place-items: center;
    margin-top: 16px;
  `,
});
