import { GrafanaCloudPlans, CONTRACT_TYPE_NAMES } from 'utils/planNames';

export enum SubscriptionCodeType {
  FREE = 'grafana-cloud-free',
  FREE_TRIAL = 'grafana-cloud-free-trial',
  PRO = 'grafana-cloud-pro',
  ADVANCED = 'grafana-cloud-advanced',
  SS_ADVANCED = 'grafana-cloud-ss-advanced',
}

type OrgSubscriptions = {
  current: {
    product: SubscriptionCodeType;
    isTrial: boolean;
    endDate: string | null;
    payload: Record<string, never>;
    plan: string;
    publicName: string;
  };
  nextProduct: SubscriptionCodeType | null;
  next: {
    product: SubscriptionCodeType;
    payload: Record<string, never>;
  } | null;
};

export type CloudOrgData = {
  id: number;
  slug: string;
  name: string;
  url: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  avatar: string;
  checksPerMonth: number;
  hgInstanceLimit: number;
  hmInstanceLimit: number;
  hlInstanceLimit: number;
  userQuota: number;
  supportPlan: string;
  creditApproved: number;
  msaSignedAt: string | null;
  msaSignedBy: string | null;
  enterprisePlugins: number;
  /**
   * @deprecated use `subscriptions` to calculate it
   */
  grafanaCloud: GrafanaCloudPlans;
  privacy: string;
  reseller: string;
  emergencySupport: boolean;
  /** @deprecated */
  trialStartDate: string | null;
  /** @deprecated */
  trialEndDate: string | null;
  trialNoticeDate: string | null;
  cancellationDate: string | null;
  pluginSignatureType: string;
  contractType: CONTRACT_TYPE_NAMES;
  subscriptions: OrgSubscriptions;
  links: any[];
  wpPlan: string;
  hgUsage: number;
  hmUsage: number;
  hlUsage: number;
  htUsage: number;
  k6VuhUsage: number;
  hpUsage: number;
  irmUsage: number;
  hgIncludedUsers: number;
  hmIncludedSeries: number;
  hlIncludedUsage: number;
  htIncludedUsage: number;
  k6VuhIncludedUsage: number;
  hpIncludedUsage: number;
  irmIncludedUsage: number;
  feO11YUsage: number;
  feO11YIncludedUsage: number;
  smUsage: number;
  smIncludedUsage: number;
};
