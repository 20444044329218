/** @jsxImportSource @emotion/react */
import cx from 'classnames';
import InlineSVG from 'react-inlinesvg';

import { getSvgSize } from 'components/Icon/utils';
import { IconSize } from 'types/size';
import { PUBLIC_URL } from 'utils/consts';

interface IconProps {
  name: string;
  title: string;

  className?: string;
  id?: string;
  size?: IconSize;
}

export const IconInline = ({ name, className, id, title, size }: IconProps) => {
  const svgSize = !!size ? getSvgSize(size) : '100%';

  return (
    <InlineSVG
      id={id}
      aria-label={title}
      src={`${PUBLIC_URL}/img/icons/${name}.svg#icon`}
      width={svgSize}
      height={svgSize}
      className={cx('icon', className)}
      role=""
    />
  );
};
