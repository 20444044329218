import { generateRoutes } from 'utils/generateRoutes';

export const signUpRoutes = generateRoutes(
  {
    checkInbox: 'check-inbox',
    createUser: 'create-user',
    selectOrganisation: 'select-organisation',
    loadingInstance: 'loading-instance',
  },
  'sign-up'
);
