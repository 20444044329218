import Cookies from 'js-cookie';

import { canTrack } from 'tracking/canTrack';
import { IS_HTTPS } from 'utils/consts';

export let referrers: ReferrersMap = {
  ireferrer: null,
  oreferrer: null,
};

export enum ReferrerTypes {
  INTERNAL = 'ireferrer',
  EXTERNAL = 'oreferrer',
}

export type ReferrersMap = Record<ReferrerTypes, string | null>;

const shouldIgnoreReferrer = (url: string): boolean => {
  return (
    !url ||
    // Don't update referrer if we are returning from a social login
    new RegExp(`redirect_uri=${location.origin}/login/(google|github|microsoft)/callback`).test(
      decodeURIComponent(url)
    ) ||
    // Don't update referrer if we are coming from another signup step
    url.startsWith(`${location.origin}/auth`) ||
    url.startsWith(`${location.origin}/signup`) ||
    url.startsWith(`${location.origin}/partners/gcp/signup`) ||
    url.startsWith(`${location.origin}/partners/azure/signup`)
  );
};

const isInternalUrl = (url: string): boolean => {
  return (
    !!url &&
    /^(https?:\/\/[^/]*(grafana\.com|grafana\.org|grafana.netlify.com|grafana.netlify.app|localhost:\d+|raintank-dev:\d+))?\//.test(
      url
    )
  );
};

const setCookie = (name: string, value: string, expires?: number) => {
  if (Cookies.get(name) !== value) {
    Cookies.set(name, value, {
      expires,
      path: '/',
      httpOnly: false,
      sameSite: 'Lax',
      secure: IS_HTTPS,
    });
  }
};

export const readReferrers = () => {
  const referrer = document.referrer;

  if (!shouldIgnoreReferrer(referrer)) {
    if (isInternalUrl(referrer)) {
      referrers.ireferrer = referrer;
    } else {
      referrers.oreferrer = referrer;
      referrers.ireferrer = null;
      Cookies.remove(ReferrerTypes.INTERNAL);
    }
  }

  return referrers;
};

export const saveReferrers = () => {
  if (canTrack) {
    const { ireferrer, oreferrer } = referrers;

    if (ireferrer) {
      setCookie(ReferrerTypes.INTERNAL, ireferrer);
    }

    if (oreferrer) {
      setCookie(ReferrerTypes.EXTERNAL, oreferrer);
    }
  }
};

export const getReferrers = () => referrers;
