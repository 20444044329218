import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { hasComplexity, hasMinLength, Input, isRequired, Submit } from 'components/Form';
import { Link } from 'components/Link';
import { privateSsoProviders, SSOProvidersKeys } from 'components/SSOButtons';
import { Text } from 'components/Text';
import { RedirectContext } from 'context/Redirect';
import { TrackingContext } from 'context/Tracking';
import { SSOProvider, User, UserContext } from 'context/User';
import { forgotPasswordRoutes } from 'pages/ForgotPassword/routes';
import { signUpRoutes } from 'pages/SignUp/routes';
import { getSourcesAsQueryParams, getSSItem } from 'tracking';
import { Endpoints, post } from 'utils/api';

import { SignInErrorCodes } from './errorCodes';
import { signInRoutes } from './routes';
import { SignInFormErrorBox } from './SignInFormErrorBox';

interface SignInFormValues {
  login: string;
  password: string;
}

export interface SignInFormProps {
  onSubmit: () => void;
  onError: () => void;
  onSuccess: () => void;
}

const formName = 'sign-in';

export const SignInForm = ({ onSubmit, onError, onSuccess }: SignInFormProps) => {
  const { state } = useLocation<{ errorCode?: SignInErrorCodes }>();
  const [, { getUser, setUser }] = useContext(UserContext);
  const [{ instance, to }, { redirectToPortal, redirectToStateInstanceHome, redirectToTo }] =
    useContext(RedirectContext);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [, { faro }] = useContext(TrackingContext);
  const [serverError, setServerError] = useState<string | null>(null);
  const history = useHistory();
  const [submitLabel, setSubmitLabel] = useState<string>('Sign In');
  const [isUsernameInputDisabled, setUsernameInputDisabled] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<SignInFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const handleFormSubmit: SubmitHandler<SignInFormValues> = async (values) => {
    setServerError(null);
    history.replace(signInRoutes.base);

    onSubmit();

    if (privateSsoProviders.length > 0) {
      const queryParams = new URLSearchParams(getSourcesAsQueryParams());
      if (!queryParams.get('signupForm')) {
        queryParams.set('signupForm', getSSItem('signupForm') || 'cloud_trial');
      }

      const provider = privateSsoProviders[0];
      window.location.href = `/api/login/${provider.key}?${queryParams.toString()}`;
    } else if (!values.password) {
      try {
        faro.trackFormSubmit(formName);
        const ssoProvidersData = (await post<SSOProvider[]>(Endpoints.CHECK_USER_SSO, {
          username: values.login,
        })) as SSOProvider[];

        faro.trackFormSubmitSuccess(formName);
        setUsernameInputDisabled(true);
        onSuccess();

        if (!ssoProvidersData || ssoProvidersData.length === 0) {
          history.push(signInRoutes.base);
          setShowPasswordField(true);
          setUsernameInputDisabled(false);
        } else {
          for (const provider of ssoProvidersData) {
            let key: SSOProvidersKeys = provider.slug as SSOProvidersKeys;
            privateSsoProviders.push({
              key: key,
              name: provider.name,
              provider: provider.provider,
            });
            setSubmitLabel('Log in with ' + provider.name);
          }
          history.push(signInRoutes.base);
        }
      } catch (err) {
        setSubmitLabel('Log in with email');
        history.push(signInRoutes.base);
        setShowPasswordField(true);
        setUsernameInputDisabled(false);
      }
    } else {
      try {
        await post<User>(Endpoints.SIGN_IN, values);

        const newUserData = (await getUser()) as User;
        await setUser(newUserData);

        faro.trackFormSubmitSuccess(formName);

        onSuccess();

        if (!newUserData.emailConfirmed) {
          history.push(signUpRoutes.checkInbox);
        } else if (to) {
          redirectToTo();
        } else if (instance) {
          redirectToStateInstanceHome();
        } else {
          redirectToPortal(newUserData);
        }
      } catch (err) {
        faro.trackFormSubmitError(formName, err.message);
        faro.trackError(err);
        setServerError(err.message);

        onError();
      }
    }
  };

  return (
    <form id="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Input
        control={control}
        disabled={isSubmitting || isUsernameInputDisabled}
        errors={errors}
        id="username"
        label="Email or username"
        name="login"
        type="text"
        validate={[isRequired()]}
      />
      {showPasswordField && (
        <Input
          control={control}
          disabled={isSubmitting}
          errors={errors}
          id="password"
          label="Password"
          name="password"
          type="password"
          validate={[isRequired(), hasMinLength(6), hasComplexity()]}
        />
      )}
      {showPasswordField && (
        <Text align="right" id="forgot-password-link-container" type="small">
          <Link id="forgot-password-link" label="Forgot password?" to={forgotPasswordRoutes.recover} />
        </Text>
      )}
      {isUsernameInputDisabled && (
        <Text align="right" id="cancel-link-container" type="small">
          <Link
            id="cancel-link"
            label="Cancel"
            onClick={() => {
              setUsernameInputDisabled(false);
              privateSsoProviders.length = 0;
              setSubmitLabel('Sign in');
            }}
            to={signInRoutes.base}
          />
        </Text>
      )}
      <Submit
        disabled={isSubmitting}
        id="submit"
        isLoading={isSubmitting}
        label={submitLabel}
        loadingText="Logging in..."
      />
      <SignInFormErrorBox code={state?.errorCode ?? null} text={serverError} />
    </form>
  );
};
