import { IconSize } from 'types/size';

/* Transform string with px to number and add 2 pxs as path in svg is 2px smaller */
export function getSvgSize(size: IconSize = 'md') {
  switch (size) {
    case 'xs':
      return 12;
    case 'sm':
      return 14;
    case 'md':
    default:
      return 16;
    case 'lg':
      return 18;
    case '19':
      return 19;
    case 'xl':
      return 24;
  }
}
