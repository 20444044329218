import { ReactNode, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useEffectOnce } from 'hooks/useEffectOnce';
import {
  initProviders,
  loadProviders,
  readCanTrack,
  readReferrers,
  readSources,
  saveCanTrack,
  saveReferrers,
  saveSources,
} from 'tracking';
import {
  trackButtonClickFaro,
  trackCheckboxBlurFaro,
  trackCheckboxFocusFaro,
  trackEventFaro,
  trackFormSubmitErrorFaro,
  trackFormSubmitFaro,
  trackFormSubmitSuccessFaro,
  trackInputBlurFaro,
  trackInputFocusFaro,
  trackInputPasswordVisibilityClickFaro,
  trackLinkClickFaro,
  trackPageViewFaro,
  trackSelectBlurFaro,
  trackSelectFocusFaro,
} from 'utils/faro';
import { noop } from 'utils/noop';

import { defaultTrackingContextActions, defaultTrackingContextState, TrackingContext } from './trackingContext';

export interface TrackingProviderProps {
  children: ReactNode;
}

export const TrackingProvider = ({ children }: TrackingProviderProps) => {
  const [trackingState, setTrackingState] = useState(defaultTrackingContextState);
  const location = useLocation();
  const history = useHistory();

  useEffectOnce(() => {
    const handleGetCanTrack = async () => {
      let localCanTrack: boolean;

      try {
        localCanTrack = await readCanTrack();
      } catch (err) {
        localCanTrack = false;
      }

      if (localCanTrack && !trackingState.trackingData.canTrack) {
        startTracking();
      } else {
        setTrackingState((trackingState) => ({
          ...trackingState,
          trackingMeta: {
            ...trackingState.trackingMeta,
            canTrackIsLoading: false,
          },
        }));
      }
    };

    initProviders();
    readSources(location.search);
    readReferrers();

    handleGetCanTrack();

    history.listen((location) => {
      readSources(location.search);
      saveSources();
      readReferrers();
      saveReferrers();
    });

    setTrackingState((trackingState) => ({
      ...trackingState,
      initialized: true,
    }));
  });

  const startTracking = () => {
    setTrackingState((trackingState) => ({
      ...trackingState,
      trackingData: {
        canTrack: true,
      },
      trackingMeta: {
        canTrackIsLoading: false,
      },
    }));

    saveCanTrack();
    saveSources();
    saveReferrers();
    loadProviders();

    history.listen((location) => {
      trackPageViewFaro(location.pathname);
    });
  };

  return (
    <TrackingContext.Provider
      value={[
        trackingState,
        {
          ...defaultTrackingContextActions,
          startTracking,
          faro: {
            ...defaultTrackingContextActions.faro,
            trackEvent: trackingState.trackingData.canTrack ? trackEventFaro : noop,
            trackButtonClick: trackingState.trackingData.canTrack ? trackButtonClickFaro : noop,
            trackCheckboxBlur: trackingState.trackingData.canTrack ? trackCheckboxBlurFaro : noop,
            trackCheckboxFocus: trackingState.trackingData.canTrack ? trackCheckboxFocusFaro : noop,
            trackFormSubmit: trackingState.trackingData.canTrack ? trackFormSubmitFaro : noop,
            trackFormSubmitError: trackingState.trackingData.canTrack ? trackFormSubmitErrorFaro : noop,
            trackFormSubmitSuccess: trackingState.trackingData.canTrack ? trackFormSubmitSuccessFaro : noop,
            trackInputBlur: trackingState.trackingData.canTrack ? trackInputBlurFaro : noop,
            trackInputFocus: trackingState.trackingData.canTrack ? trackInputFocusFaro : noop,
            trackInputPasswordVisibilityClick: trackingState.trackingData.canTrack
              ? trackInputPasswordVisibilityClickFaro
              : noop,
            trackLinkClick: trackingState.trackingData.canTrack ? trackLinkClickFaro : noop,
            trackSelectBlur: trackingState.trackingData.canTrack ? trackSelectBlurFaro : noop,
            trackSelectFocus: trackingState.trackingData.canTrack ? trackSelectFocusFaro : noop,
          },
        },
      ]}
    >
      {trackingState.initialized ? children : null}
    </TrackingContext.Provider>
  );
};
