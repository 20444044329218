import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react/macro';
import { ReactRouterHistory } from '@grafana/faro-react';
import { createBrowserHistory, History } from 'history';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';

import { App } from './App';
import './index.scss';
import { PUBLIC_URL } from './utils/consts';
import { FaroErrorBoundary, initializeFaro } from './utils/faro';

function initializeApp(history: History) {
  const container = document.getElementById('root');
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript
  // Remove the noscript element as it doesn't make sense when JS is enabled and it may cause accessibility issues
  document.getElementById('noroot')?.remove();

  // Emotion Cache is create in order to set the compat property to true
  // Without it emotion will print error messages about :last-child selectors
  const emotionCache = createCache({
    key: 'grafana-com-auth',
  });
  emotionCache.compat = true;

  // Render the App
  root.render(
    <FaroErrorBoundary>
      <CacheProvider value={emotionCache}>
        <Router history={history}>
          <App />
        </Router>
      </CacheProvider>
    </FaroErrorBoundary>
  );
}

async function initialize() {
  const history = createBrowserHistory({
    basename: PUBLIC_URL,
  });

  await initializeFaro(history as ReactRouterHistory);

  initializeApp(history);
}

initialize();
