import { useEffect, useState } from 'react';

import { Instance as InstanceModel } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { CloudOrgData } from 'types/orgTypes';
import { Endpoints, get } from 'utils/api';

export function useGetOrgDataFromInstance(instance: string | undefined) {
  const [isLoading, setIsLoading] = useState(true);
  const [orgData, setOrgData] = useState<CloudOrgData | undefined>();
  const [instanceInState, setInstanceInState] = useState(instance);

  useEffectOnce(() => {
    // Set instance in state because on subsequent renders/calls of hook, it will have been removed from url it comes from
    setInstanceInState(instance);
  });

  useEffect(() => {
    const getOrgData = async () => {
      try {
        setIsLoading(true);

        // Use the instance name to get the orgSlug associated with that instance
        const getInstance = await get<InstanceModel>(`${Endpoints.GET_INSTANCES}/${instanceInState ?? instance}`);
        const getOrgSlug = getInstance?.orgSlug;

        // then get orgData
        const orgData = await get<CloudOrgData>(`${Endpoints.GET_ORGS}/${getOrgSlug}`);

        setIsLoading(false);
        setOrgData(orgData);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };

    getOrgData();
  }, [instance, instanceInState]);

  return { orgData, isLoading };
}
