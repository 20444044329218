import { useContext, useEffect, useState } from 'react';

import { TrackingContext } from 'context/Tracking';
import { getData } from 'tracking';
import { TestGroup } from 'types/TestGroup';

interface ABTestingData {
  experimentId: string;
  group: TestGroup;
  version: string;
}

const parseError = (err: any) => {
  if (!err) {
    return 'Error while loading the AB Testing data';
  } else if (typeof err === 'object') {
    return err.error?.message || err.error || JSON.stringify(err);
  } else {
    return err;
  }
};

export function useABTesting(mbox: string, skip?: boolean) {
  const [, { trackEvent, faro }] = useContext(TrackingContext);
  const [abTestingData, setABTestingData] = useState<ABTestingData>();
  const [isLoading, setIsLoading] = useState(true);
  // load adobe target data just when initialized
  const adobeTargetEnabled = Boolean(window.adobe?.target?.getOffer);

  console.log('AdobeTarget - skip*', skip);

  useEffect(() => {
    const getABTestingData = async () => {
      try {
        const data = await getData<Array<{ content: ABTestingData[] }>>(mbox);
        const content = data?.[0]?.content[0] || null;

        if (content) {
          trackEvent({
            name: 'experiment_viewed',
            experiment_id: content?.experimentId,
            experiment_version: content?.version,
            experiment_group: content?.group,
          });
          setABTestingData(content);
          setIsLoading(false);
        } else {
          setABTestingData(undefined);
          setIsLoading(false);
        }
      } catch (err: any) {
        setIsLoading(false);
        faro.trackError(err);

        const message = parseError(err);
        trackEvent({ name: 'experiment_load_error', message });
      }
    };

    if (adobeTargetEnabled && !skip) {
      getABTestingData();
    } else {
      setIsLoading(false);
    }
  }, [faro, mbox, trackEvent, adobeTargetEnabled, skip]);

  return { abTestingData, isLoading };
}
