/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { hasMinLength, Input, InputProps, isRequired, Submit } from 'components/Form';
import { LinkProps } from 'components/Link';
import { RequestIndicatorProps } from 'components/RequestIndicator';
import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { UserContext } from 'context/User';
import { Endpoints, post } from 'utils/api';
import { useStyles } from 'utils/useStyles';

import { CheckInboxFormErrorBox } from './CheckInboxFormErrorBox';
import { SignUpCheckInboxErrorCodes } from './errorCodes';
import { signUpRoutes } from './routes';

interface CheckInboxFormValues {
  shortKey: string;
}

interface CheckInboxFormProps {
  isResendReady: boolean;
  isResendSuccess: boolean;
  onHideRequestIndicator: RequestIndicatorProps['onHide'];
  onResendEmail: LinkProps['onClick'];
}

const formName = 'validate-account';

export const CheckInboxForm = ({
  isResendReady,
  isResendSuccess,
  onHideRequestIndicator,
  onResendEmail,
}: CheckInboxFormProps) => {
  const [{ instance, to }, { redirectToStateInstanceHome, redirectToTo }] = useContext(RedirectContext);
  const [, { getUser, setUser }] = useContext(UserContext);
  const [, { faro }] = useContext(TrackingContext);
  const history = useHistory();
  const [serverError, setServerError] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<SignUpCheckInboxErrorCodes | null>(null);
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<CheckInboxFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const handleFormSubmit: SubmitHandler<CheckInboxFormValues> = useCallback(
    async (values) => {
      setServerError(null);
      setErrorCode(null);

      try {
        faro.trackFormSubmit(formName);
        await post<boolean>(Endpoints.VALIDATE_EMAIL_SHORT_KEY, values);
        faro.trackFormSubmitSuccess(formName);

        if (to) {
          redirectToTo();
        } else if (instance) {
          redirectToStateInstanceHome();
        } else {
          const newUserData = await getUser();

          await setUser(newUserData);

          history.push(signUpRoutes.selectOrganisation);
        }
      } catch (err) {
        faro.trackFormSubmitError(formName, err.message);
        if ((err.message ?? '').includes('combination does not exist')) {
          setErrorCode(SignUpCheckInboxErrorCodes.INVALID_CODE);
        } else {
          faro.trackError(err);
          setServerError(err.message);
        }
      }
    },
    [getUser, setUser, instance, redirectToStateInstanceHome, to, redirectToTo, history, faro]
  );

  const handleInputChange: InputProps['onChange'] = () => {
    if (serverError !== null) {
      setServerError(null);
    }

    if (errorCode !== null) {
      setErrorCode(null);
    }
  };

  useEffect(() => {
    if (isDirty && isValid) {
      handleSubmit(handleFormSubmit)();
    }
  }, [handleSubmit, handleFormSubmit, isValid, isDirty]);

  return (
    <form id="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Input
        control={control}
        disabled={isSubmitting}
        errors={errors}
        id="short-key"
        label="Activation code"
        name="shortKey"
        onChange={handleInputChange}
        showLabel
        type="text"
        css={styles.inputs}
        formatter={(value) => value.trim()}
        validate={[isRequired(), hasMinLength(6)]}
      />
      <Submit
        disabled={isSubmitting}
        id="submit"
        isLoading={isSubmitting}
        label="Check activation code"
        loadingText={'Loading'}
      />
      <CheckInboxFormErrorBox
        code={errorCode}
        isResendReady={isResendReady}
        isResendSuccess={isResendSuccess}
        onHideRequestIndicator={onHideRequestIndicator}
        onResendEmail={onResendEmail}
        text={serverError}
      />
    </form>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  inputs: css`
    margin: 1.1rem 0;
    input {
      color: ${colors.gray11};

      &:focus,
      &:hover {
        background-color: ${colors.white};
      }
    }
  `,
});
