/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext, useState } from 'react';

import { ErrorBox } from 'components/ErrorBox';
import { Heading } from 'components/Heading';
import { Loader } from 'components/Loader';
import { LoaderTypes } from 'components/Loader/types';
import { Page, PagePanel } from 'components/Page';
import { Text } from 'components/Text';
import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useQuery } from 'hooks/useQuery';
import { Endpoints, post } from 'utils/api';
import { useStyles } from 'utils/useStyles';

import { CheckInboxForm } from './CheckInboxForm';

export interface GrafanaSsoState {
  email: string;
  redirectPath: string;
}


export const GrafanaSso = () => {
  const [
    {
      userMeta: { userIsLoggedIn },
    },
  ] = useContext(UserContext);
  const styles = useStyles(getStyles);
  const code = useQuery('code');
  const [{}, { redirectToPortal }] = useContext(RedirectContext);
  const [error, setError] = useState('');
  const [grafanaSsoState, setGrafanaSsoState] = useState<GrafanaSsoState | null>();

  useEffectOnce(async () => {
    if (userIsLoggedIn) {
      redirectToPortal();
      return;
    }

    if (!code) {
      setError('Missing authorization code.');
      return
    }

    try {
      const grafanaSso = await post<GrafanaSsoState>(Endpoints.GRAFANA_SSO_LOOKUP, { code });
      if (grafanaSso.email) {
        setGrafanaSsoState(grafanaSso);
      }
    } catch (err) {
      setError(err.message || 'Unable to load Grafana SSO code.');
    }
  });

  if (!grafanaSsoState && !error) {
    return <Page heading="Loading.." title="Loading your information.">
      <PagePanel id="panel">
        <Loader id="loader" label="This should only take a few seconds." type={LoaderTypes.SPINNER} />
      </PagePanel>
    </Page>
  }

  return (
    <Page
      heading="Please confirm you're a human"
      title="Check Your Inbox"
      headerIcon="check-inbox"
      headingWidth="full"
      css={styles.createUserPageStyleOverride}
    >
      {error && <ErrorBox id="error" title="Sorry, there's been an error.">{error}</ErrorBox>}
      {grafanaSsoState && grafanaSsoState.email &&
        <PagePanel id="panel" type="full">
          <Heading id="panel-heading" type={'2'}>
            We love software. But we make it for people, not bots.
          </Heading>
          <Text align="left" id="description" color={'gray11'} css={styles.modifiedTextMargins}>
            <p css={styles.checkInboxPText}>
              Check your inbox (<strong>{grafanaSsoState.email}</strong>) and click the confirmation link or use the
              activation code we&apos;ve sent.
            </p>
          </Text>
          <CheckInboxForm
            formName="grafana-sso-check-inbox"
            redirectPath={grafanaSsoState.redirectPath}
          />
        </PagePanel>
      }
    </Page>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  actions: css`
    margin-top: 21px;
    color: #24292ebf;
    text-align: center;
  `,
  modifiedTextMargins: css`
    margin: 32px 0 7px;

    @media (max-width: 550px) {
      font-size: 14px;
      margin: 24px 0 7px;
    }
  `,
  createUserPageStyleOverride: css`
    #page-content {
      background-color: ${colors.white};
      box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.05) !important;
      width: 610px;

      @media (max-width: 768px) {
        margin: 24px;
        padding: 48px;
        width: 90%;
      }

      @media (max-width: 550px) {
        padding: 24px 0;
        max-width: 414px;
      }

      @media (max-width: 400px) {
        h1 {
          padding: 0 12px;
        }
      }
    }
    #page-panels,
    #panel {
      width: 510px;

      @media (max-width: 768px) {
        padding: 0px;
        margin: 0px;
        width: 100%;
      }

      @media (max-width: 550px) {
        padding: 0 24px;
      }
    }

    button#submit {
      margin-top: 16px;
    }
  `,
  checkInboxPText: css`
    margin: 0;
  `,
});
