/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { ReactNode } from 'react';

import { Icon } from 'components/Icon';
import { StylingContextState } from 'context/Styling';
import { useStyles } from 'utils/useStyles';

type HeadingTypes = '1' | '2' | '3' | '4';

const getStyles = ({ colors, typography }: StylingContextState, type: HeadingTypes, id: string) => ({
  container: css`
    ${typography.headings[type]};
    font-size: ${type === '2' ? '16px' : type === '1' ? '28px' : ''};
    align-items: center;
    justify-content: ${type === '2' ? 'center' : ''};
    color: var(--text-primary, #24292e);
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 0;

    @media (max-width: 550px) {
      font-size: ${type === '2' ? '14px' : type === '1' ? '24px' : ''};
      display: ${id === 'panel-heading' ? 'none' : ''};
    }
  `,
  icon: css`
    color: ${colors.gray3};
    height: 2rem;
    margin-right: 1.2rem;
    width: 2rem;
  `,
});

export interface HeadingProps {
  children: ReactNode;
  type: HeadingTypes;

  className?: string;
  icon?: string;
  iconLabel?: string;
  id?: string;
}

export const Heading = ({ children, type, className, icon, iconLabel, id }: HeadingProps) => {
  const Component = `h${type}` as 'h1' | 'h2' | 'h3' | 'h4';
  const styles = useStyles(getStyles, type, id);

  return (
    <Component className={cx('heading', className)} css={styles.container} id={id}>
      {icon && <Icon css={styles.icon} name={icon} title={iconLabel!} />}
      {children}
    </Component>
  );
};
