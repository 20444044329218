import { createContext } from 'react';

import { noop, noopPromise, nullNoopPromise } from 'utils/noop';

import { UserContextActions, UserContextModel, UserContextState } from './types';

export const defaultUserContextState: UserContextState = {
  userData: null,
  userMeta: {
    userIsLoggedIn: false,
    userHasConfirmedEmail: false,
    userHasOrganisations: false,
    userIsLoading: true,
  },
};

export const defaultUserContextActions: UserContextActions = {
  getUser: nullNoopPromise,
  setUser: noopPromise,
  signOut: noop,
};

export const UserContext = createContext<UserContextModel>([defaultUserContextState, defaultUserContextActions]);

UserContext.displayName = 'UserContext';
