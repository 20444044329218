/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';

import { Button, ButtonProps } from 'components/Button';
import { StylingContextState } from 'context/Styling';
import { palette } from 'context/Styling/palette';
import { useStyles } from 'utils/useStyles';

interface SubmitProps extends Omit<ButtonProps, 'type'> {
  className?: string;
}

export const Submit = ({ className, ...props }: SubmitProps) => {
  const styles = useStyles((theme: StylingContextState) => getStyles(theme, { ...props }));
  const group = 'test';
  return (
    <Button
      {...props}
      className={cx(`submit-${group}`, className)}
      css={styles}
      type="submit"
      data-testid={`submit-${group}`}
    />
  );
};

const getStyles = ({ colors }: StylingContextState, { ...props }) =>
  css`
    margin: 24px 0px 9px 0px;
    border-radius: 5px;
    background-color: ${!props.disabled
      ? palette.blueLightMain
      : props.disabled
      ? 'var(--action-disabled-background, rgba(36, 41, 46, 0.04))'
      : ''};

    color: ${!props.disabled
      ? colors.white
      : props.disabled
      ? 'var(--text-disabled, rgba(36, 41, 46, 0.50))'
      : 'black'};

    &:hover:disabled {
      background: var(--action-disabled-background, rgba(36, 41, 46, 0.04));
      color: var(--text-disabled, rgba(36, 41, 46, 0.5));
      cursor: not-allowed;
    }
    &:hover {
      background: ${palette.blueLightMainHover};
    }
    &:focus,
    :active {
      background-color: ${!props.disabled
        ? palette.blueLightMain
        : props.disabled
        ? 'var(--action-disabled-background, rgba(36, 41, 46, 0.04))'
        : ''};
    }
  `;
