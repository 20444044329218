/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Page } from 'components/Page';
import { DashboardContext } from 'context/Dashboards';
import { PluginContext } from 'context/Plugin';
import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { CreateUserPage } from 'pages/SignUp/CreateUserPage';
import { Endpoints, post } from 'utils/api';
import { getPluginText, PluginTextTypes } from 'utils/pluginHelper';
import { useStyles } from 'utils/useStyles';

import { signUpRoutes } from './routes';

export const CreateUser = () => {
  const [
    {
      userMeta: { userIsLoggedIn, userHasConfirmedEmail },
    },
  ] = useContext(UserContext);
  const [{ instance, to }, { redirectToStateInstanceHome, redirectToPortal, redirectToTo }] =
    useContext(RedirectContext);
  const [, { faro, clearSSItem }] = useContext(TrackingContext);
  const { plugin } = useContext(PluginContext);
  const { dashboard } = useContext(DashboardContext);
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));

  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const incomingEmail = urlParams.get('email') ?? undefined;
  const refCode = urlParams.get('refCode') ?? undefined;
  const pluginTitle = plugin ? getPluginText(plugin.name, PluginTextTypes.Title) : '';

  let title = `Sign up for Grafana Cloud`;

  const trackTrial = async () => {
    try {
      await post(Endpoints.TRACK_TRIAL, {
        email: incomingEmail,
        refCode,
      });
    } catch (err) {
      faro.trackError(err);
    }
  };

  useEffectOnce(() => {
    if (userIsLoggedIn) {
      clearSSItem('signupForm');
      if (!userHasConfirmedEmail) {
        history.push(signUpRoutes.checkInbox);
      } else if (to) {
        redirectToTo();
      } else if (instance) {
        redirectToStateInstanceHome();
      } else {
        redirectToPortal();
      }
    } else {
      trackTrial();
    }
  });

  if (userIsLoggedIn) {
    return null;
  }

  // For legacy non-custom layout
  if (incomingEmail) {
    title = 'Complete your registration';
  } else if (dashboard) {
    title = 'Get started with dashboards in Grafana Cloud';
  } else if (plugin) {
    title = `Sign up for Grafana Cloud ${pluginTitle}`;
  } else {
    title = 'Sign up for Grafana Cloud';
  }

  return (
    <Page title="Sign Up - Create User" css={styles.createUserPageStyleOverride}>
      <div css={styles.panels}>
        <CreateUserPage incomingEmail={incomingEmail} title={title} />
      </div>
    </Page>
  );
};

const getStyles = ({ colors }: StylingContextState) => {
  return {
    createUserPageStyleOverride: css`
      #page-content {
        background-color: ${colors.gray9};
        box-shadow: none;
        width: 610px;

        @media (max-width: 1109px) {
          width: 100%;
        }

        @media (max-width: 768px) {
          margin: 40px 48px;
          padding: 0 24px;
          width: 90%;
        }

        @media (max-width: 550px) {
          padding: 0;
          max-width: 414px;
        }
      }
    `,
    panels: css`
      justify-content: center;
      align-items: normal;
      width: 514px;
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: 1fr auto;
      grid-template-areas:
        'ssoPanel customInfo'
        '. disclaimer';
      grid-column-gap: 96px;
      background-color: #f4f4f6;
      justify-items: center;

      @media (max-width: 1260px) {
        grid-column-gap: 32px;
        grid-template-areas:
          'customInfo ssoPanel'
          '. disclaimer';
        width: 100%;
      }

      @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto auto;
        grid-template-areas:
          'ssoPanel'
          'customInfo'
          'disclaimer';
        row-gap: 0;
      }
    `,
  };
};
