/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AlternateAction } from 'components/AlternateAction';
import { LinkProps } from 'components/Link';
import { Page, PageHeading, PagePanel } from 'components/Page';
import { privateSsoProviders, publicSsoProviders, SSOButtons } from 'components/SSOButtons';
import { Text } from 'components/Text';
import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { redirectToRoutes } from 'pages/RedirectTo/routes';
import { signUpRoutes } from 'pages/SignUp/routes';
import { GRAFANA_NET_DOMAIN } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

import { SignInForm, SignInFormProps } from './SignInForm';

const getStyles = ({ colors }: StylingContextState) => ({
  heading: css`
    color: ${colors.gray11};
    display: block;
    margin-bottom: 28px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  teamURLheading: css`
    color: var(--tag-0, #c65210);
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.07px;
  `,
  instanceHost: css`
    margin: 12px 0 32px 0;

    @media (max-width: 550px) {
      margin: 12px 0 24px 0;
    }
  `,
  loginText: css`
    color: ${colors.gray11};
  `,
  textSeparator: css`
    margin: 14px 0 0px;
    color: ${colors.gray11};
    background-color: ${colors.white};
  `,

  content: css`
    #page-content {
      box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.05) !important;
    }
  `,
});

export const SignIn = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [{ instance, to }, { redirectToStateInstanceHome, clearInstance, redirectToPortal, redirectToTo }] =
    useContext(RedirectContext);
  const [
    {
      userMeta: { userHasConfirmedEmail, userIsLoggedIn },
    },
  ] = useContext(UserContext);
  const [, { clearSSItem }] = useContext(TrackingContext);
  const history = useHistory();
  const styles = useStyles(getStyles);

  useEffectOnce(() => {
    if (userIsLoggedIn) {
      clearSSItem('signupForm');
      if (!userHasConfirmedEmail) {
        history.push(signUpRoutes.checkInbox);
      } else if (to) {
        redirectToTo();
      } else if (instance) {
        redirectToStateInstanceHome();
      } else {
        redirectToPortal();
      }
    }
  });

  let instanceHost = '';

  if (instance) {
    const instanceUrl = new URL(instance!);
    instanceHost = instanceUrl.host;
  }

  if (userIsLoggedIn) {
    return null;
  }

  const handleFormSubmit: SignInFormProps['onSubmit'] = () => {
    setIsSubmitting(true);
  };

  const handleFormError: SignInFormProps['onError'] = () => {
    setIsSubmitting(false);
  };

  const handleFormSuccess: SignInFormProps['onSuccess'] = () => {
    setIsSubmitting(false);
  };

  const handleCreateTeamClick: LinkProps['onClick'] = () => {
    clearInstance();

    history.push(signUpRoutes.createUser);
  };

  return (
    <Page title="Sign In" css={styles.content}>
      <PagePanel id="panel" type="full">
        {!!instance ? (
          <>
            <PageHeading css={styles.teamURLheading}>
              <span css={styles.loginText}>Log in to&nbsp;</span>
              {instanceHost.replace('.' + GRAFANA_NET_DOMAIN, '')}
            </PageHeading>
            <Text color="gray11" align="center" css={styles.instanceHost}>
              {instanceHost}
            </Text>
          </>
        ) : (
          <PageHeading css={styles.heading}>Log in to Grafana Cloud</PageHeading>
        )}

        {privateSsoProviders.length === 0 && (
          <SSOButtons
            disableUserCreation={true}
            ssoProviders={publicSsoProviders}
            disabled={isSubmitting}
            redirectRoute={redirectToRoutes.postSso}
            redirectErrorRoute={redirectToRoutes.postSsoError}
          />
        )}
        <SSOButtons
          disableUserCreation={true}
          ssoProviders={privateSsoProviders}
          disabled={isSubmitting}
          redirectRoute={redirectToRoutes.postSso}
          redirectErrorRoute={redirectToRoutes.postSsoError}
        />
        <Text align="center" css={styles.textSeparator} type="normal">
          or
        </Text>

        <SignInForm onSubmit={handleFormSubmit} onError={handleFormError} onSuccess={handleFormSuccess} />
        {!!instance ? (
          <AlternateAction
            actionEvent={handleCreateTeamClick}
            actionLabel="Register"
            id="sign-up-regular-link"
            actionType="button"
            label="Need to create a new team?"
          />
        ) : (
          <AlternateAction
            actionLabel="Register"
            actionLink={`${signUpRoutes.createUser}?pg=login`}
            id="sign-up-link"
            label="Don't have an account?"
          />
        )}
      </PagePanel>
    </Page>
  );
};
