/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';

import { StylingContextState } from 'context/Styling';

export const standardSignupStyles = ({ colors, typography }: StylingContextState) => {
  return {
    textWrapper: css`
      ${typography.text.small};
      color: ${colors.gray8};
      line-height: 1.5rem;
      text-align: left;
      margin-top: -5px;
      margin-left: 24px;
      white-space: nowrap;

      @media (max-width: 768px) {
        order: 2;
        margin-left: 0;
      }
    `,
    headingSpacing: css`
      margin-bottom: -10px;

      @media (max-width: 1024px) {
        padding-top: 32px;
      }
      @media (max-width: 768px) {
        order: 1;
        padding-top: 16px;
      }
    `,
    h2: css`
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 20px;
      color: ${colors.gray11};
      line-height: 22px;

      @media (width <=768px) {
        text-wrap: wrap;
        line-height: 26px;
      }
    `,
    iconsWrapper: css`
      font-size: 1rem;
      font-weight: 600;
      color: gray;
      align-items: flex-start;
      justify-content: flex-start;

      picture {
        display: inline-flex;
        content: 'Grafana Cloud';
        font-weight: 400;
        font-size: 28px;
        color: ${colors.white};
        vertical-align: top;

        img {
          width: 340px;
        }
      }
    `,
    leftPanelContainer: css`
      display: flex;
      flex-direction: column;
      text-align: center;
      align-self: baseline;
      min-width: 480px;
      padding: 0 24px;
      background-color: ${colors.gray9};

      @media (max-width: 1220px) {
        max-width: 534px;
        min-width: 400px;
      }

      @media (max-width: 768px) {
        display: grid;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 0 16px 0;
        align-items: center;
        padding: 32px 48px;
        width: 100%;
        min-width: 250px;

        picture {
          img {
            justify-content: center;
          }
        }
      }

      @media (max-width: 550px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 0 8px 0;
        align-items: top;
        padding: 24px 24px 0 24px;

        h1 {
          display: none;
        }
      }
    `,
    accountIncludes: css`
    margin-left: 2px;

      :before {
        content: 'Account includes:\';
        display: block;
        color: ${colors.gray11};
        ${typography.text.normal};
        padding: 0;
        font-size: 18px;
        margin: 0 0 10px 0;
      }
    `,
  };
};
