import { Redirect, Switch } from 'react-router-dom';

import { signUpRoutes } from 'pages/SignUp/routes';
import { FaroRoute } from 'utils/faro';

import { Accept } from './Accept';
import { Accepted } from './Accepted';
import { AlreadyAccepted } from './AlreadyAccepted';
import { CreateUser } from './CreateUser';
import { CreateUserFromSSO } from './CreateUserFromSSO';
import { Expired } from './Expired';
import { InvalidUser } from './InvalidUser';
import { invitesRoutes } from './routes';

export const Invites = () => {
  return (
    <Switch>
      <FaroRoute path={invitesRoutes.accept} component={Accept} />
      <FaroRoute path={invitesRoutes.accepted} component={Accepted} />
      <FaroRoute path={invitesRoutes.createUser} component={CreateUser} />
      <FaroRoute path={invitesRoutes.createUserFromSSO} component={CreateUserFromSSO} />
      <FaroRoute path={invitesRoutes.expired} component={Expired} />
      <FaroRoute path={invitesRoutes.invalidUser} component={InvalidUser} />
      <FaroRoute path={invitesRoutes.alreadyAccepted} component={AlreadyAccepted} />
      <Redirect exact from={invitesRoutes.base} to={signUpRoutes.base} />
    </Switch>
  );
};
