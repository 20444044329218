/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { ErrorBox } from 'components/ErrorBox';
import { Checkbox, hasComplexity, hasMinLength, Input, isEmail, isRequired, Submit } from 'components/Form';
import { PluginContext } from 'context/Plugin';
import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { User, UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { Endpoints, post } from 'utils/api';
import { useStyles } from 'utils/useStyles';

import { signUpRoutes } from './routes';

interface CreateUserFormValues {
  login: string;
  password: string;
  subscribe: boolean;
}

export interface CreateUserFormProps {
  onSubmit: () => void;
  onError: () => void;
  incomingEmail?: string;
}

const formName = 'sign-up';

export const CreateUserForm = ({ incomingEmail, onSubmit, onError }: CreateUserFormProps) => {
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));
  const [, { getUser, setUser }] = useContext(UserContext);
  const [, { identifyUser, trackEvent, getSSItem, faro }] = useContext(TrackingContext);
  const [, { getRedirectUrl }] = useContext(RedirectContext);
  const { plugin } = useContext(PluginContext);

  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
  } = useForm<CreateUserFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [serverError, setServerError] = useState<string>();

  useEffectOnce(() => {
    if (incomingEmail) {
      setValue('login', incomingEmail, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    }
  });

  const handleFormSubmit: SubmitHandler<CreateUserFormValues> = async (values) => {
    setServerError(undefined);

    onSubmit();

    try {
      faro.trackFormSubmit(formName);
      await post<User>(Endpoints.SIGN_UP, {
        email: values.login,
        password: values.password,
        signupForm: getSSItem('signupForm') || 'cloud_trial',
        subscribe: values.subscribe,
        msaSigned: true,
        redirectUrl: getRedirectUrl(signUpRoutes.selectOrganisation),
      });

      const newUserData = await getUser();
      if (newUserData?.id) {
        await identifyUser({
          id: newUserData.id,
          email: values.login,
        });
      }

      await setUser(newUserData);

      if (incomingEmail) {
        trackEvent({
          name: 'marketing_user_sign_up',
          email: values.login,
        });
      }

      faro.trackFormSubmitSuccess(formName);

      trackEvent({
        name: `create-account`,
      });

      history.push({
        pathname: `${signUpRoutes.checkInbox}`,
        ...(plugin && { search: `?plcmt=${plugin.slug}` }),
      });
    } catch (err) {
      faro.trackFormSubmitError(formName, err.message);
      faro.trackError(err);
      onError();
      setServerError(err.message);
    }
  };

  return (
    <form id="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Input
        control={control}
        disabled={isSubmitting}
        errors={errors}
        id="email"
        label="Email"
        name="login"
        type="email"
        css={styles.inputs}
        validate={[isRequired('Email address is required'), isEmail('This email address is invalid')]}
      />
      <Input
        control={control}
        disabled={isSubmitting}
        errors={errors}
        id="password"
        label="Password"
        name="password"
        type="password"
        css={styles.inputs}
        validate={[
          isRequired('Password is required'),
          hasMinLength(6, 'Password must be at least %value% characters'),
          hasComplexity('Password must contain at least 1 character and 1 number'),
        ]}
      />
      <Checkbox
        control={control}
        disabled={isSubmitting}
        id="marketing-subscribe"
        label="Send me news and feature updates"
        name="subscribe"
        css={styles.inputs}
      />
      <Submit
        disabled={isSubmitting}
        id="submit"
        isLoading={isSubmitting}
        label="Create my account"
        loadingText="Creating account..."
        css={styles.button}
      />
      {serverError && <ErrorBox id="server-error">{serverError}</ErrorBox>}
    </form>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  inputs: css`
    margin: 1.1rem 0 16px;
    input {
      color: ${colors.gray11};

      &:focus,
      &:hover {
        background-color: ${colors.white};
      }
    }
  `,
  button: css`
    margin-top: 32px;
  `,
});
