/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';

import { Typography, ViewportState } from './types';

export const getTypography = ({ lg }: ViewportState): Typography => ({
  size: {
    base: '14px',
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '18px',
  },
  text: {
    normal: css`
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      margin: 0.8rem;
    `,
    small: css`
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
    `,
    tiny: css`
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    `,
    label: css`
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
    `,
    large: css`
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    `,
  },
  headings: {
    '1': css`
      font-size: ${lg ? '3.1rem' : '3rem'};
      font-weight: 500;
      justify-content: ${lg ? 'unset' : 'center'};
      line-height: ${lg ? '5rem' : '4rem'};
      margin: ${lg ? '4rem 0' : '3rem 0'};
      text-align: ${lg ? 'left' : 'center'};

      &:first-child:not(style) {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    `,
    '2': css`
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 3rem;
      margin: 2.4rem 0;
      text-align: ${lg ? 'left' : 'center'};

      &:first-child:not(style) {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    `,
    '3': css`
      font-size: 1.9rem;
      font-weight: 400;
      line-height: 2.3rem;
      margin: 0.8rem 0;
      text-align: left;

      &:first-child:not(style) {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    `,
    '4': css`
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.3rem;
      margin: 0.2rem 0;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }
    `,
  },
});
