/** @jsxImportSource @emotion/react */

import { Heading } from 'components/Heading';
import { PUBLIC_URL } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

import { BulletRenderer } from './BulletRenderer';
import { standardSignupStyles } from './StandardSignup.styles';

export const CreateUserStandardInfo = (): JSX.Element => {
  const styles = useStyles((theme) => standardSignupStyles(theme));

  const standardBullets = [
    'Grafana, of course',
    '10k metrics',
    '50GB logs',
    '50GB traces',
    '50GB profiles',
    '100k Synthetics Test Executions',
    '500VUh k6 testing',
    '50k Frontend sessions',
    '14-day retention',
    '3 active users',
    '2,232 Application Observability host hours',
  ];

  return (
    <div css={styles.leftPanelContainer}>
      <Heading type="1" css={styles.headingSpacing}>
        <div css={styles.iconsWrapper}>
          <picture>
            <img src={`${PUBLIC_URL}/img/signup/signup-multiverse.svg`} alt="Grafana Cloud" />
          </picture>
        </div>
      </Heading>
      <div css={styles.textWrapper}>
        <h2 css={styles.h2}>The (actually useful) free forever plan</h2>
        <div css={styles.accountIncludes}>
          <BulletRenderer listItems={standardBullets} />
        </div>
      </div>
    </div>
  );
};
