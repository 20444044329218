/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { useEffect } from 'react';

import { Icon } from 'components/Icon';
import { StylingContextState } from 'context/Styling';
import { palette } from 'context/Styling/palette';
import { useStyles } from 'utils/useStyles';

const getStyles = ({ colors }: StylingContextState, success: boolean) => ({
  indicator: css`
    animation: fadeIn 500ms forwards;
    color: ${success ? palette.greenLightMain : colors.red3};
    height: 1.5rem;
    margin: 0 0.4rem;
    vertical-align: middle;
    width: 1.5rem;
  `,
});

export interface RequestIndicatorProps {
  errorTitle: string;
  success: boolean;
  successTitle: string;
  onHide: () => void;

  className?: string;
  id?: string;
}

export const RequestIndicator = ({
  errorTitle,
  success,
  successTitle,
  onHide,
  className,
  id,
}: RequestIndicatorProps) => {
  const styles = useStyles(getStyles, success);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    timer = setTimeout(() => {
      onHide();
    }, 5000);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [onHide]);

  return (
    <Icon
      className={cx('request-indicator', className)}
      css={styles.indicator}
      id={id}
      name={success ? 'check' : 'warning'}
      title={success ? successTitle : errorTitle}
    />
  );
};
