import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { ErrorBox } from 'components/ErrorBox';
import { hasComplexity, hasMinLength, Input, isRequired, Submit } from 'components/Form';
import { RedirectContext } from 'context/Redirect';
import { TrackingContext } from 'context/Tracking';
import { User, UserContext } from 'context/User';
import { Endpoints, post } from 'utils/api';

import { InviteState } from './types';

interface CreateUserFormValues {
  password: string;
}

const formName = 'sign-up-invite';

export const CreateUserForm = () => {
  const [, { redirectToComputedInstanceHome, redirectToComputedInstanceK6 }] = useContext(RedirectContext);
  const [, { getUser, setUser }] = useContext(UserContext);
  const [, { faro }] = useContext(TrackingContext);
  const { state: { key, redirectApp } = {} } = useLocation<InviteState>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<CreateUserFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [serverError, setServerError] = useState<string>();

  const handleFormSubmit: SubmitHandler<CreateUserFormValues> = async (values) => {
    setServerError(undefined);

    try {
      faro.trackFormSubmit(formName);
      await post<User>(Endpoints.CREATE_USER_FROM_INVITE, {
        password: values.password,
        key,
      });

      const newUserData = await getUser();

      await setUser(newUserData);

      faro.trackFormSubmitSuccess(formName);

      if (redirectApp === 'k6') {
        redirectToComputedInstanceK6(newUserData!);
      } else {
        redirectToComputedInstanceHome(newUserData!);
      }
    } catch (err) {
      faro.trackFormSubmitError(formName, err.message);
      faro.trackError(err);
      setServerError(err.message);
    }
  };

  return (
    <form id="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Input
        control={control}
        disabled={isSubmitting}
        errors={errors}
        id="password"
        label="Password"
        name="password"
        type="password"
        validate={[
          isRequired('Password is required'),
          hasMinLength(6, 'Password must be at least %value% characters'),
          hasComplexity('Password must contain at least 1 character and 1 number'),
        ]}
      />
      <Submit
        disabled={isSubmitting}
        id="submit"
        isLoading={isSubmitting}
        label="Create my account"
        loadingText="Creating account..."
      />
      {serverError && <ErrorBox id="server-error">{serverError}</ErrorBox>}
    </form>
  );
};
