import { Endpoints, post } from 'utils/api';
import { MARKETO_KEY } from 'utils/consts';
import { noop } from 'utils/noop';

import { LocationTraits, UserTraits } from './types';

export interface WindowProperties {
  Munchkin: {
    init: (key: string | undefined, config: { apiOnly: boolean }) => void;
    munchkinFunction: (event: string, payload: unknown) => void;
  };
}

const isEnabled = !!MARKETO_KEY;

export const load = isEnabled
  ? () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://munchkin.marketo.net/munchkin.js';
      script.onload = () => {
        window.Munchkin?.init(MARKETO_KEY, { apiOnly: true });
      };

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  : noop;

export const trackPage = isEnabled
  ? (providersLocation: LocationTraits) => {
      window.Munchkin?.munchkinFunction('visitWebPage', {
        url: providersLocation.pathname,
        params: providersLocation.search,
      });
    }
  : noop;

export const identifyUser = isEnabled
  ? async (user: UserTraits) => {
      if (user && user.email) {
        await post<boolean>(Endpoints.ASSOCIATE, {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          company: '',
        }).catch(noop);
      }
    }
  : noop;
