/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactNode } from 'react';

import { ErrorBox } from 'components/ErrorBox';
import { Link, LinkProps } from 'components/Link';
import { RequestIndicator, RequestIndicatorProps } from 'components/RequestIndicator';
import { StylingContextState } from 'context/Styling';
import { useStyles } from 'utils/useStyles';

import { SignUpCheckInboxErrorCodes } from './errorCodes';

interface CheckInboxFormErrorBoxProps {
  code: SignUpCheckInboxErrorCodes | null;
  isResendReady: boolean;
  isResendSuccess: boolean;
  onHideRequestIndicator: RequestIndicatorProps['onHide'];
  onResendEmail: LinkProps['onClick'];
  text: string | null;
}

export const CheckInboxFormErrorBox = ({
  code,
  isResendReady,
  isResendSuccess,
  onHideRequestIndicator,
  onResendEmail,
  text,
}: CheckInboxFormErrorBoxProps) => {
  const styles = useStyles(getStyles);
  let content: ReactNode;

  if (text) {
    content = <>{text}</>;
  } else {
    switch (code) {
      case SignUpCheckInboxErrorCodes.INVALID_CODE:
        content = (
          <div css={styles.errorText}>
            The activation code isn’t correct. Make sure you’ve entered it correctly or{' '}
            <Link
              label="send a new one"
              onClick={onResendEmail}
              type="button"
              underline
              css={styles.standardTextColor}
            />{' '}
            {isResendReady && (
              <RequestIndicator
                errorTitle="Code was not sent"
                id="resend-code-indicator"
                success={isResendSuccess}
                successTitle="Code sent"
                onHide={onHideRequestIndicator}
              />
            )}
          </div>
        );
        break;

      default:
        content = null;
    }
  }

  return content === null ? null : <ErrorBox id="server-error">{content}</ErrorBox>;
};

const getStyles = ({ colors }: StylingContextState) => ({
  errorText: css`
    color: ${colors.gray11};
  `,
  standardTextColor: css`
    color: ${colors.gray11} !important;
  `,
});
