/** @jsxImportSource @emotion/react */
import { isEqual } from 'lodash';
import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Disclaimer } from 'components/Page/Disclaimer';
import { PersistentStateContext } from 'context/PersistentState';
import { RedirectContext } from 'context/Redirect';
import { TrackingContext } from 'context/Tracking';
import { useABTesting } from 'hooks/useABTesting';
import { useQuery } from 'hooks/useQuery';
import { CreateUserStandardInfo } from 'pages/SignUp/CreateUserStandardInfo';
import { CustomProductRedirects } from 'pages/SignUp/CustomSignup.data';
import { DASHBOARDS_CONST, redirectSetter } from 'pages/SignUp/customSignupHelpers';
import { SELF_SERVE_MBOX } from 'utils/consts';

import { CreateUserCustomInfo } from './CreateUserCustomInfo';
import { CreateUserSSO } from './CreateUserSSO';

interface CreateUserPageProps {
  incomingEmail?: string;
  title: string;
}

export const CreateUserPage = ({ incomingEmail, title }: CreateUserPageProps) => {
  const { search } = useLocation();
  const history = useHistory();
  const [persistentState, { setPersistentData }] = useContext(PersistentStateContext);

  const [, { getSourcesAsQueryParams }] = useContext(TrackingContext);
  const [{ customContent }, { updateCustomContent }] = useContext(RedirectContext);
  let trackingQueryParams = getSourcesAsQueryParams();
  trackingQueryParams = trackingQueryParams ? `?${trackingQueryParams}` : '';

  const isDashboards = () => {
    return new URLSearchParams(search)?.get('pg')?.includes(DASHBOARDS_CONST);
  };

  const plcmtParam = useQuery('plcmt');
  const pgParam = useQuery('pg');
  const { abTestingData } = useABTesting(SELF_SERVE_MBOX, !isDashboards());

  useEffect(() => {
    document.title = `Grafana Cloud - ${title}`;

    return () => {
      document.title = 'Grafana Cloud';
    };
  }, [title]);

  useEffect(() => {
    if (!!abTestingData) {
      const customSignupProduct = redirectSetter(search, plcmtParam, pgParam, abTestingData?.group);
      if (!!customSignupProduct && !isEqual(customSignupProduct, persistentState.customContent)) {
        const params = new URLSearchParams(search);
        params.set('redirectPath', customSignupProduct.key as CustomProductRedirects);

        history.replace({ search: params.toString() });

        setPersistentData(customSignupProduct);
        updateCustomContent(customSignupProduct);
      }
    }
  }, [
    abTestingData,
    search,
    pgParam,
    plcmtParam,
    setPersistentData,
    history,
    persistentState.customContent,
    updateCustomContent,
  ]);

  return (
    <>
      {customContent && Object.keys(customContent).length > 0 ? (
        <CreateUserCustomInfo customContent={customContent} />
      ) : (
        <CreateUserStandardInfo />
      )}
      <CreateUserSSO incomingEmail={incomingEmail} />
      <Disclaimer trackingQueryParams={trackingQueryParams} align="center" />
    </>
  );
};
