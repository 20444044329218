import { ReactNode, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorBox } from 'components/ErrorBox';
import { Link, LinkProps } from 'components/Link';
import { RedirectContext } from 'context/Redirect';
import { signUpRoutes } from 'pages/SignUp/routes';

import { SignInErrorCodes } from './errorCodes';

interface SignInFormErrorBoxProps {
  code: SignInErrorCodes | null;
  text: string | null;
}

export const SignInFormErrorBox = ({ code, text }: SignInFormErrorBoxProps) => {
  const [, { clearInstance }] = useContext(RedirectContext);
  const history = useHistory();

  let content: ReactNode;

  if (text) {
    content = <>{text}</>;
  } else {
    switch (code) {
      case SignInErrorCodes.INVALID_USER:
      case SignInErrorCodes.NO_ORG_ACCESS:
        const handleCreateGrafanaCloudAccount: LinkProps['onClick'] = () => {
          clearInstance();

          history.push(signUpRoutes.createUser);
        };

        content = (
          <>
            To join an existing team, contact your organization admin for an invite. Otherwise,{' '}
            <Link
              label="create a Grafana Cloud account"
              onClick={handleCreateGrafanaCloudAccount}
              style="secondary"
              type="button"
            />{' '}
            to create a new organization.
          </>
        );
        break;

      default:
        content = null;
    }
  }

  return content === null ? null : (
    <ErrorBox id="server-error" title="Whoops, that login doesn't work.">
      {content}
    </ErrorBox>
  );
};
