/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TrackingContext } from 'context/Tracking';
import { UserContext } from 'context/User';
import { useABTesting } from 'hooks/useABTesting';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useGetOrgDataFromInstance } from 'hooks/useGetOrgDataFromInstance';
import { GraphAnimationLoader } from 'pages/SignUp/GraphAnimationLoader';
import { LoadingInstanceTrialBenefits } from 'pages/SignUp/LoadingInstanceTrialBenefits';
import { useCheckInstanceLoaded } from 'pages/SignUp/useCheckInstanceLoaded';
import { CloudOrgData } from 'types/orgTypes';
import { SELF_SERVE_MBOX } from 'utils/consts';
import { isTrial } from 'utils/orgUtils';

import { signUpRoutes } from './routes';

const CLOUD_SIGNUP_FLOW_EVENT = 'cloud_signup_flow_event';
export const LoadingInstanceAnimation = () => {
  const [, { trackEvent, faro }] = useContext(TrackingContext);
  const [
    {
      userData,
      userMeta: { userIsLoggedIn, userHasConfirmedEmail, userHasOrganisations },
    },
  ] = useContext(UserContext);

  // Get instance from url and then set it in component state bc subsequent renders will remove instanceUrl from url
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const instanceUrl = params?.get('instanceUrl') || undefined;
  const instance = instanceUrl && instanceUrl.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/\.(.*)/, '');
  const [instanceInState, setInstanceInState] = useState(instance);
  const [skipIfNotTrialOrg, setSkipIfNotTrialOrg] = useState<boolean>();

  // Set orgData based on instance
  const { orgData: orgDataFromHook, isLoading: orgDataLoading } = useGetOrgDataFromInstance(instanceInState);
  const [orgDataInState, setOrgDataInState] = useState<CloudOrgData>();

  // We only run Adobe Target experiment here if org is currently in a cloud trial and product is grafana-cloud-free
  // otherwise we'll skip that by passing in the skipIfNotTrialOrg flag to the skip param in the AT hook
  const skip = skipIfNotTrialOrg || orgDataLoading;
  const { abTestingData, isLoading: abTestLoading } = useABTesting(SELF_SERVE_MBOX, skip);

  useEffect(() => {
    if (!orgDataLoading) {
      setOrgDataInState(orgDataFromHook);

      const isNotFreeTrialOrg = !isTrial(orgDataInState);
      setSkipIfNotTrialOrg(isNotFreeTrialOrg);
    }
  }, [orgDataLoading, orgDataFromHook, orgDataInState]);

  useCheckInstanceLoaded().then((redirectTo: string) => {
    if (!!redirectTo) {
      faro.trackEvent('user-hg-instance-loaded-and-now-redirecting', { redirectTo });
      trackEvent({
        name: 'user-hg-instance-loaded-and-now-redirecting',
        userId: userData?.id,
        redirectToURL: redirectTo,
      });

      window.location.href = redirectTo;
    }
  });
  const history = useHistory();

  useEffectOnce(() => {
    setInstanceInState(instance);

    if (!userIsLoggedIn) {
      history.push(signUpRoutes.createUser);
    } else if (!userHasConfirmedEmail) {
      history.push(signUpRoutes.checkInbox);
    } else if (!userHasOrganisations) {
      history.push(signUpRoutes.selectOrganisation);
    }
  });

  useEffect(() => {
    // Tracking added for A/B test for two loading-page variations
    if (!abTestLoading) {
      if (!!abTestingData) {
        trackEvent({
          name: CLOUD_SIGNUP_FLOW_EVENT,
          componentKey: `loading_page_experiment_viewed_${abTestingData?.group}`,
        });
      } else {
        trackEvent({
          name: CLOUD_SIGNUP_FLOW_EVENT,
          componentKey: `loading_page_no_experiment_viewed`,
          skippedDueToNotTrialOrg: skipIfNotTrialOrg,
        });
      }
    }
  }, [trackEvent, abTestLoading, abTestingData, skipIfNotTrialOrg]);

  if (!userIsLoggedIn || !userHasConfirmedEmail) {
    return null;
  }

  return (
    <>
      {!abTestLoading &&
        (abTestingData?.group === 'test' ? <LoadingInstanceTrialBenefits /> : <GraphAnimationLoader />)}
    </>
  );
};
