import { IS_HOSTNAME_MATCHING, METRICS_ENABLED } from 'utils/consts';
import { noop, noopPromise } from 'utils/noop';

import * as adobeTarget from './adobeTarget';
import * as clearbit from './clearbit';
import * as facebook from './facebook';
import * as googleAnalytics from './googleAnalytics';
import * as hotjar from './hotjar';
import * as linkedin from './linkedin';
import * as marketo from './marketo';
import * as rudderStack from './rudderStack';
import { EventTraits, LocationDetails, LocationTraits, OrganisationsTraits, UserTraits } from './types';

declare global {
  interface Window
    extends adobeTarget.WindowProperties,
      clearbit.WindowProperties,
      facebook.WindowProperties,
      googleAnalytics.WindowProperties,
      hotjar.WindowProperties,
      linkedin.WindowProperties,
      marketo.WindowProperties {}
}

export const isEnabled = METRICS_ENABLED && IS_HOSTNAME_MATCHING;

export const initProviders = isEnabled
  ? () => {
      adobeTarget.initialize();
      clearbit.initialize();
      facebook.initialize();
      googleAnalytics.initialize();
      hotjar.initialize();
      linkedin.initialize();
    }
  : noop;

export const loadProviders = isEnabled
  ? () => {
      adobeTarget.load();
      clearbit.load();
      facebook.load();
      googleAnalytics.load();
      hotjar.load();
      linkedin.load();
      marketo.load();
      rudderStack.load();
    }
  : noop;

export const trackPage = isEnabled
  ? (locationTraits: LocationTraits, locationDetails: LocationDetails) => {
      adobeTarget.trackPage(locationDetails);
      clearbit.trackPage();
      facebook.trackPage();
      googleAnalytics.trackPage(locationTraits, locationDetails);
      marketo.trackPage(locationTraits);
      rudderStack.trackPage();
    }
  : noop;

export const trackEvent = isEnabled
  ? (eventTraits: EventTraits) => {
      googleAnalytics.trackEvent(eventTraits);
      rudderStack.trackEvent(eventTraits);
    }
  : noop;

export const identifyUser = isEnabled
  ? async (userTraits: UserTraits) => {
      clearbit.identifyUser(userTraits);
      googleAnalytics.identifyUser(userTraits);
      await marketo.identifyUser(userTraits);
      rudderStack.identifyUser(userTraits);
    }
  : noop;

export const resetUser = isEnabled
  ? () => {
      clearbit.resetUser();
      googleAnalytics.resetUser();
      rudderStack.resetUser();
    }
  : noop;

export const assignUserToOrganisations = isEnabled
  ? (organisationsTraits: OrganisationsTraits) => {
      rudderStack.assignUserToOrganisation(organisationsTraits);
    }
  : noop;

export const getData = isEnabled ? adobeTarget.getData : noopPromise;

export * from './types';
