/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react/macro';

import { StylingContextState } from 'context/Styling';
import { useStyles } from 'utils/useStyles';

const getStyles = ({ colors, viewport: { lgHeight } }: StylingContextState) => css`
  html {
    font-family: 'Inter', 'system-ui', sans-serif;
    font-size: ${lgHeight ? '55%' : '62.5%'};
  }

  html,
  body,
  #root {
    min-height: 100vh;
    width: 100%;
  }

  body {
    background-color: ${colors.gray9};
    margin: 0;
    color: ${colors.gray11};
    font-size: 14px;
  }

  #root {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
  }

  input:focus {
    outline: none;
  }

  input:active {
    outline: none;
  }

  /* Global tooltip styling */
  div[role='tooltip'] {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px;
    background-color: ${colors.gray9};
    border: none;
    color: ${colors.gray11};
    padding: 10px;
    box-shadow: 0px 4px 8px 0px rgba(24, 26, 27, 0.2);
  }

  div:has(div[role='tooltip']) {
    // Hack to target svg in parent of tooltip only (which is the caret svg)
    svg {
      fill: ${colors.gray9};
      path {
        stroke: rgba(24, 26, 27, 0.2);
        stroke-width: 0.15px;
      }
    }
  }
`;

export const GlobalStyles = () => {
  const styles = useStyles(getStyles);

  return <Global styles={styles} />;
};
