import Cookies from 'js-cookie';

import { canTrack } from 'tracking/canTrack';
import { IS_HTTPS, MONTH_IN_DAYS, YEAR_IN_DAYS } from 'utils/consts';

export let sources: SourcesMap = {
  isource: null,
  osource: null,
};

export enum SourceTypes {
  INTERNAL = 'isource',
  EXTERNAL = 'osource',
}

export type SourcesMap = Record<SourceTypes, string | null>;

// The source keys are groups of sources, gathered by types
// Be careful when adding sources as the order is important:
// - first key is the universal key used to identified that source
// - the rest of the items are aliases
// If multiple params are set, the first occurrence is the one that provides the value
export const sourceKeys: Record<SourceTypes, string[][]> = {
  isource: [['tech'], ['pg', 'isource'], ['plcmt'], ['aud'], ['cta']],
  osource: [
    ['src', 'source', 'osource', 'utm_source'],
    ['mdm', 'utm_medium'],
    ['cnt', 'utm_content'],
    ['camp', 'utm_campaign'],
    ['trm', 'utm_term'],
  ],
};

const readSourcesFromQueryString = (query: string, type: SourceTypes): string | null => {
  const urlParams = new URLSearchParams(query);
  const queryParams = Object.fromEntries(urlParams.entries());

  if (queryParams['signupForm']) {
    sessionStorage.setItem('signupForm', queryParams['signupForm']);
    delete queryParams['signupForm'];
  }

  const params = sourceKeys[type]
    .reduce((acc, group) => {
      for (let currentKey of group) {
        if (queryParams.hasOwnProperty(currentKey)) {
          const value = queryParams[currentKey];

          if (value && value.match(/^[+-_a-zA-Z0-9]+$/)) {
            acc.push(`${group[0]}=${value}`);

            break;
          }
        }
      }

      return acc;
    }, [] as string[])
    .join('&');

  return params.length === 0 ? null : params;
};

const readSourcesFromCookies = (type: SourceTypes): string | null => {
  return Cookies.get(type) ?? null;
};

const readSourcesByType = (query: string, type: SourceTypes): string | null => {
  return readSourcesFromQueryString(query, type) ?? readSourcesFromCookies(type) ?? sources[type];
};

const setCookie = (name: string, value: string, expires?: number) => {
  Cookies.set(name, value, {
    expires,
    path: '/',
    httpOnly: false,
    sameSite: 'Lax',
    secure: IS_HTTPS,
  });
};

const convertSourcesToTrackingParams = (type: SourceTypes) => {
  const values = Object.fromEntries(new URLSearchParams(sources[type] ?? '').entries());

  return sourceKeys[type].reduce((acc, keys) => {
    acc[`${keys[0]}Source`] = values[keys[0]] ?? null;

    return acc;
  }, {} as { [label: string]: string | null });
};

export const readSources = (query: string) => {
  sources = {
    isource: readSourcesByType(query, SourceTypes.INTERNAL),
    osource: readSourcesByType(query, SourceTypes.EXTERNAL),
  };
};

export const saveSources = () => {
  if (canTrack) {
    const { isource, osource } = sources;

    if (isource) {
      setCookie(SourceTypes.INTERNAL, isource, YEAR_IN_DAYS);
    }

    if (osource) {
      setCookie(SourceTypes.EXTERNAL, osource, MONTH_IN_DAYS);
    }
  }
};

export const getSources = () => sources;

export const getSourcesAsTrackingParams = () => ({
  ...convertSourcesToTrackingParams(SourceTypes.INTERNAL),
  ...convertSourcesToTrackingParams(SourceTypes.EXTERNAL),
});

export const getSourcesAsQueryParams = () => {
  const separator = sources.isource && sources.osource;

  return `${sources.isource || ''}${separator ? '&' : ''}${sources.osource || ''}`;
};

export const getSSItem = (name: string) => {
  return sessionStorage.getItem(name) ?? null;
};

export const clearSSItem = (name: string) => {
  sessionStorage.removeItem(name);
};
