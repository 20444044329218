/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { ReactNode } from 'react';

import { StylingContextState } from 'context/Styling';
import { BaseColors } from 'context/Styling/palette';
import { useStyles } from 'utils/useStyles';

export type TextTypes = 'normal' | 'small' | 'tiny' | 'label' | 'large';
export type TextAlign = 'left' | 'center' | 'right';

const getStyles = (
  { colors, typography, viewport: { lg } }: StylingContextState,
  forceAlign: TextAlign | undefined,
  color: keyof BaseColors = 'gray11',
  type: TextTypes = 'normal',
  id?: string
) => css`
  ${typography.text[type]};

  line-height: 22px;
  color: ${colors[color] ?? colors.gray11};
  margin: ${id !== 'actions' && id !== 'resend-email'
    ? '24px 0'
    : id === 'actions' || id === 'resend-email'
    ? '24px 0 0'
    : '1.6rem 0'};
  text-align: ${forceAlign ?? (!lg ? 'center' : 'left')};
  grid-area: ${id === 'disclaimer' ? 'disclaimer' : ''};

  @media (max-width: 768px) {
    ${id === 'disclaimer'
      ? `text-align: center !important;
    margin: 0 48px;
    align-self: center;
    text-align: center;`
      : ''};
  }

  @media (max-width: 768px) {
    ${id === 'disclaimer' ? `margin: 0 56px;` : ''};
  }
`;

export interface TextProps {
  children: ReactNode;

  align?: TextAlign;
  className?: string;
  color?: keyof BaseColors;
  component?: keyof JSX.IntrinsicElements;
  id?: string;
  type?: TextTypes;
}

export const Text = ({ children, align, className, color, component: Element = 'p', id, type }: TextProps) => {
  const styles = useStyles(getStyles, align, color, type, id);

  return (
    <Element className={cx('text', className)} css={styles} id={id}>
      {children}
    </Element>
  );
};
