import { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { RedirectContext } from 'context/Redirect';
import { FaroRoute } from 'utils/faro';

import { Instance } from './Instance';
import { Portal } from './Portal';
import { PostSSO } from './PostSSO';
import { PostSSOError } from './PostSSOError';
import { redirectToRoutes } from './routes';
import { To } from './To';

export const RedirectTo = () => {
  const [{ instance, to }] = useContext(RedirectContext);

  const defaultRoute = to ? redirectToRoutes.to : instance ? redirectToRoutes.instanceHome : redirectToRoutes.portal;

  return (
    <Switch>
      <FaroRoute path={redirectToRoutes.instanceHome} component={Instance} />
      <FaroRoute path={redirectToRoutes.instanceOnboarding} component={Instance} />
      <FaroRoute path={redirectToRoutes.portal} component={Portal} />
      <FaroRoute path={redirectToRoutes.to} component={To} />
      <FaroRoute path={redirectToRoutes.postSso} component={PostSSO} />
      <FaroRoute path={redirectToRoutes.postSsoError} component={PostSSOError} />
      <Redirect exact from={redirectToRoutes.base} to={defaultRoute} />
    </Switch>
  );
};
