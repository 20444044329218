/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext } from 'react';

import { Button } from 'components/Button';
import { Link } from 'components/Link';
import { Text } from 'components/Text';
import { StylingContext, StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { useStyles } from 'utils/useStyles';

const getStyles = ({ colors, viewport: { md } }: StylingContextState) => ({
  container: css`
    align-items: center;
    background-color: ${colors.white};
    bottom: 0;
    display: flex;
    flex-flow: ${md ? 'row nowrap' : 'column nowrap'};
    justify-content: space-between;
    left: 0;
    padding: ${md ? '1.5rem 5rem;' : '2rem'};
    position: fixed;
    width: 100%;
  `,
  text: css`
    margin: ${md ? '0' : '0 0 2rem'};
    background-color: ${colors.white};
    color: ${colors.gray11};
  `,
});

export const CookiesBar = () => {
  const {
    viewport: { md },
  } = useContext(StylingContext);

  const [
    {
      trackingData: { canTrack },
      trackingMeta: { canTrackIsLoading },
    },
    { startTracking },
  ] = useContext(TrackingContext);

  const styles = useStyles(getStyles);

  if (canTrack || canTrackIsLoading) {
    return null;
  }

  return (
    <div aria-label="Cookies notice" css={styles.container} id="cookies-bar" role="dialog">
      <Text
        align={md ? 'left' : 'center'}
        css={styles.text}
        id="cookies-bar-description"
        type={md ? 'normal' : 'small'}
      >
        Grafana Labs uses cookies for the normal operation of this website.{' '}
        <Link id="cookies-bar-terms" label="Learn more" to="/legal/terms/" type="external" />.
      </Text>
      <Button
        id="cookies-bar-accept"
        label="Got it!"
        onClick={startTracking}
        textSize={md ? 'normal' : 'small'}
        width="auto"
        variant="primary"
      />
    </div>
  );
};
