/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { CustomSignup } from 'pages/SignUp/CustomSignup';
import { CustomSignupProps } from 'pages/SignUp/types';
import { useStyles } from 'utils/useStyles';

export const CreateUserCustomInfo = ({ customContent }: CustomSignupProps) => {
  const styles = useStyles(getStyles);

  return (
    <div css={styles.leftPanelContainer}>
      <CustomSignup customContent={customContent} />
    </div>
  );
};

const getStyles = () => ({
  leftPanelContainer: css`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-self: center;
    align-items: center;

    padding: 40px;
    width: 534px;

    @media (max-width: 1100px) {
      display: flex;
      grid-area: customInfo;
      flex-direction: column;
      margin: 0 0 16px 0;
      padding: 32px 8px;
      width: 373px;
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 24px 32px 0px 32px;
      text-align: left;
      align-self: left;
      align-items: left;
    }

    @media (max-width: 550px) {
      width: 100%;
      padding: 24px 24px 0px 24px;
      text-align: left;
      align-self: left;
      align-items: left;
    }
  `,
});
