/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { ReactNode } from 'react';

import { BaseColors } from 'context/Styling/palette';
import { useStyles } from 'utils/useStyles';

import { Text, TextProps } from './Text';

const getStyles = () =>
  css`
    margin: 0.8rem 0 0;

    @media (max-width: 550px) {
      font-size: 14px;
    }
  `;

export interface ErrorTextProps {
  children: ReactNode;

  align?: TextProps['align'];
  className?: string;
  id?: string;
  color?: keyof BaseColors;
}

export const ErrorText = ({ children, align, className, id, color }: ErrorTextProps) => {
  const styles = useStyles(getStyles);

  return (
    <Text
      align={align}
      color={color ?? 'red3'}
      className={cx('error-text', className)}
      css={styles}
      id={id}
      type="small"
    >
      {children}
    </Text>
  );
};
