import { createContext } from 'react';

import { noop, stringNoop } from 'utils/noop';

import { RedirectContextActions, RedirectContextModel, RedirectContextState } from './types';

export const defaultRedirectContextState: RedirectContextState = {
  initialized: false,
  instance: null,
  integration: null,
  orgId: null,
  to: null,
  redirectPath: null,
  customContent: undefined,
};

export const defaultRedirectContextActions: RedirectContextActions = {
  clearInstance: noop,
  getRedirectUrl: stringNoop,
  redirectToComputedInstanceHome: noop,
  redirectToComputedInstanceK6: noop,
  redirectToComputedInstanceOnboarding: noop,
  redirectToStateInstanceHome: noop,
  redirectToStateInstanceOnboarding: noop,
  redirectToPortal: noop,
  redirectToPortalOrInstanceHome: noop,
  redirectToTo: noop,
  setOrgId: noop,
  updateCustomContent: noop,
};

export const RedirectContext = createContext<RedirectContextModel>([
  defaultRedirectContextState,
  defaultRedirectContextActions,
]);

RedirectContext.displayName = 'RedirectContext';
