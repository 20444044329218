/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext } from 'react';

import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { PUBLIC_URL } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

export const Header = () => {
  const [{ customContent }] = useContext(RedirectContext);
  const isCustomSignupSSO = customContent && !!Object.keys(customContent);
  const styles = useStyles(getStyles, isCustomSignupSSO);
  const [, { getSourcesAsQueryParams, faro }] = useContext(TrackingContext);
  let trackingQueryParams = getSourcesAsQueryParams();
  trackingQueryParams = trackingQueryParams ? `?${trackingQueryParams}` : '';
  const href = `/${trackingQueryParams}`;

  const handleLogoClick = () => {
    faro.trackLinkClick('logo', href);
  };

  return (
    <header css={styles.container} id="header">
      <div css={styles.innerContainer} id="header-container">
        <a onClick={handleLogoClick} href={href} css={styles.logoContainer} id="header-logo-container">
          <img
            src={`${PUBLIC_URL}/img/grafana-cloud-logo-for-light-theme.svg`}
            alt="Grafana logo"
            css={styles.logo}
            id="header-logo-img"
          />
        </a>
      </div>
    </header>
  );
};

const getStyles = ({ colors, viewport: { lg } }: StylingContextState, isCustomSignupSSO: boolean) => ({
  container: css`
    background-color: ${colors.gray9};
    text-align: ${lg ? 'left' : 'center'};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  innerContainer: css`
    margin: 0 auto;
    max-width: 100%;
    width: ${isCustomSignupSSO ? '132rem' : '121rem'};

    @media (max-width: 1260px) {
      width: 121rem;
    }

    @media (max-width: 1100px) {
      width: ${isCustomSignupSSO ? '111rem' : '121rem'};
    }
  `,
  logoContainer: css`
    margin-bottom: ${lg ? '0' : '2rem'};
  `,
  logo: css`
    width: 14.8rem;
    margin: 12px;
  `,
});
