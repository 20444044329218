/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';

import { Page, PagePanel } from 'components/Page';
import { Text } from 'components/Text';
import { StylingContextState } from 'context/Styling';
import { PUBLIC_URL } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

export const GraphAnimationLoader = () => {
  const styles = useStyles(getStyles);

  const frames = ['frame0.svg', 'frame1.svg', 'frame2.svg', 'frame3.svg', 'frame4.svg', 'frame5.svg'];

  return (
    <Page title="Finishing Setup" heading={'Grafana is loading...'} css={styles.page}>
      <PagePanel id="panel" align={'center'} type="full">
        <div css={styles.dashboard}>
          <svg>
            {frames.map((frame, i) => (
              <image
                id={`frame-animation-${i}`}
                xlinkHref={`${PUBLIC_URL}/img/loaders/instance-loaders/${frame}`}
                key={`frame-animation-${i}`}
                css={styles.imgFrame(i)}
              />
            ))}
          </svg>
        </div>
        <Text id={'message'} align={'center'} color={'gray11'} type={'large'} css={styles.modifiedText}>
          Your instance is loading, and will be ready shortly.
        </Text>

        <div css={styles.support}>
          If you see this message for more than a few minutes, please email{' '}
          <a css={styles.link} href="mailto:support@grafana.com">
            support@grafana.com
          </a>
        </div>
      </PagePanel>

      <div css={styles.footer}>
        <footer>
          Copyright {new Date().getFullYear()} &copy; Grafana Labs | contact us:&nbsp;
          <a css={styles.link} href="mailto:support@grafana.com">
            support@grafana.com
          </a>
          &nbsp;|&nbsp;
          <a css={styles.link} href="https://grafana.com/trademark-policy" target="_blank" rel="noreferrer">
            Trademark Policy
          </a>
        </footer>
      </div>
    </Page>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  page: css`
    justify-content: center;

    #page-content {
      background-color: ${colors.white};
      box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.05) !important;
      width: 610px;

      @media (max-width: 768px) {
        margin: 24px;
        padding: 48px;
        width: 90%;
      }

      @media (max-width: 550px) {
        padding: 24px 0;
        max-width: 414px;
      }
      @media (max-width: 400px) {
        h1 {
          padding: 0 12px;
        }
      }
    }
    #page-panels,
    #panel {
      width: 510px;

      @media (max-width: 768px) {
        padding: 0px;
        margin: 0px;
        width: 100%;
      }

      @media (max-width: 550px) {
        padding: 0 24px;
      }
    }
  `,

  h1: css`
    text-align: center !important;
    align-self: stretch;
    margin-bottom: 0px;
    justify-content: center;
    width: 100%;
    color: #fff;
    font-weight: 400;
  `,
  text: css`
    color: #c7d0d9;
    text-align: center;
  `,
  support: css`
    color: #24292e;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.021px;
    margin-top: 32px;

    ​ a {
      text-decoration: none;
    }

    @media (max-width: 550px) {
      font-size: 12px;
    }
  `,
  dashboard: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    place-items: center;
    margin: 32px 0 18px 0;

    svg {
      place-items: center;
    }
  `,
  footer: css`
    color: #24292e;
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;
    bottom: 1rem;
    left: 0;
    position: fixed;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.021px;
    border: none !important;
    margin: 0;
    padding: 0;
  `,
  imgFrame: (index: number) => css`
    position: absolute;
    visibility: hidden;
    animation: 1.5s show infinite ease-in;
    animation-delay: ${index * 0.25}s;
    width: 100%;
    left: 0;

    @keyframes show {
      0% {
        visibility: visible;
      }
      25% {
        visibility: visible;
      }
      25.1% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }
  `,
  modifiedText: css`
    margin-bottom: 0px;
  `,
  link: css`
    background-color: '';
    color: inherit;

    &:active,
    :hover {
      outline: 0;
    }
  `,
});
