// URL
const EXPECTED_HOSTNAME = process.env.REACT_APP_EXPECTED_HOSTNAME || location.hostname;
export const IS_HOSTNAME_MATCHING = EXPECTED_HOSTNAME === location.hostname;
export const IS_HTTPS = location.protocol === 'https:';
export const ORIGIN = process.env.REACT_APP_ORIGIN || location.origin;
export let PUBLIC_URL = process.env.PUBLIC_URL ?? '/';
PUBLIC_URL = PUBLIC_URL.endsWith('/') ? PUBLIC_URL.substring(0, PUBLIC_URL.length - 1) : PUBLIC_URL;
export const GRAFANA_NET_DOMAIN = process.env.DOMAIN || 'grafana.net';
export const GRAFANA_COM_DOMAIN = 'https://grafana.com';

// App
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const APP_VERSION = process.env.REACT_APP_VERSION;

// APM
export const APM_ENABLED = process.env.REACT_APP_APM_ENABLED === 'true';
export const FARO_ENABLED = process.env.REACT_APP_FARO_ENABLED === 'true';
export const FARO_INSTRUMENTATION_CONSOLE_ENABLED =
  process.env.REACT_APP_FARO_INSTRUMENTATION_CONSOLE_ENABLED === 'true';
export const FARO_INSTRUMENTATION_ERRORS_ENABLED = process.env.REACT_APP_FARO_INSTRUMENTATION_ERRORS_ENABLED === 'true';
export const FARO_INSTRUMENTATION_RUM_ENABLED = process.env.REACT_APP_FARO_INSTRUMENTATION_RUM_ENABLED === 'true';
export const FARO_INSTRUMENTATION_WEB_VITALS_ENABLED =
  process.env.REACT_APP_FARO_INSTRUMENTATION_WEB_VITALS_ENABLED === 'true';
export const FARO_KEY = process.env.REACT_APP_FARO_KEY;
export const FARO_URL = process.env.REACT_APP_FARO_URL;
export const FARO_APP = process.env.REACT_APP_FARO_APP;

// Metrics
export const ADOBE_TARGET_DOMAIN = process.env.REACT_APP_ADOBE_TARGET_DOMAIN ?? '';
export const ADOBE_TARGET_OVERRIDE_MBOX_EDGE_SERVER =
  process.env.REACT_APP_ADOBE_TARGET_OVERRIDE_MBOX_EDGE_SERVER === 'true';
export const SELF_SERVE_MBOX = 'grafana-self-serve-mbox';
export const CLEARBIT_KEY = process.env.REACT_APP_CLEARBIT_KEY;
export const CLEARBIT_SNIPPET_VERSION = process.env.REACT_APP_CLEARBIT_SNIPPET_VERSION;
export const FACEBOOK_FBQ_VERSION = process.env.REACT_APP_FACEBOOK_FBQ_VERSION;
export const FACEBOOK_KEY = process.env.REACT_APP_FACEBOOK_KEY;
export const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID ? parseInt(process.env.REACT_APP_HOTJAR_ID, 10) : undefined;
export const HOTJAR_SV = process.env.REACT_APP_HOTJAR_SV ? parseInt(process.env.REACT_APP_HOTJAR_SV, 10) : undefined;
export const LINKEDIN_KEY = process.env.REACT_APP_LINKEDIN_KEY;
export const MARKETO_KEY = process.env.REACT_APP_MARKETO_KEY ?? process.env.MARKETO_KEY ?? undefined;
export const METRICS_ENABLED = process.env.REACT_APP_METRICS_ENABLED === 'true';
export const RUDDER_STACK_CONFIG_URL = process.env.REACT_APP_RUDDER_STACK_CONFIG_URL;
export const RUDDER_STACK_DATA_PLANE_URL = process.env.REACT_APP_RUDDER_STACK_DATA_PLANE_URL;
export const RUDDER_STACK_KEY = process.env.REACT_APP_RUDDER_STACK_KEY;
export const RUDDERSTACK_INTEGRATIONS_URL = process.env.REACT_APP_RUDDERSTACK_INTEGRATIONS_URL;

// Time
export const YEAR_IN_DAYS = 365;
export const MONTH_IN_DAYS = 30;
