import { FACEBOOK_FBQ_VERSION, FACEBOOK_KEY } from 'utils/consts';
import { noop } from 'utils/noop';

export interface FbFn {
  (...args: unknown[]): void;
  callMethod?: (...args: unknown[]) => void;
  queue?: unknown[];
  push?: FbFn;
  loaded?: boolean;
  version?: string;
}

export interface WindowProperties {
  fbq: FbFn;
  _fbq: FbFn;
}

const isEnabled = !!FACEBOOK_KEY && !!FACEBOOK_FBQ_VERSION;

export const initialize = isEnabled
  ? () => {
      if (!window.fbq) {
        const fbFn: FbFn = (...args) => {
          fbFn!.callMethod ? fbFn.callMethod.apply(fbFn, args) : fbFn.queue!.push(args);
        };

        window.fbq = fbFn;

        if (!window._fbq) {
          window._fbq = fbFn;
        }

        fbFn.push = fbFn;
        fbFn.loaded = true;
        fbFn.version = FACEBOOK_FBQ_VERSION;
        fbFn.queue = [];
        window.fbq('init', FACEBOOK_KEY);
      }
    }
  : noop;

export const load = isEnabled
  ? () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://connect.facebook.net/en_US/fbevents.js';

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  : noop;

export const trackPage = isEnabled
  ? () => {
      window.fbq?.('track', 'PageView');
    }
  : noop;
