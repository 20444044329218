import { useLocation, useHistory } from 'react-router-dom';

import { Page, PagePanel } from 'components/Page';
import { SpacedText } from 'components/Text';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { signInRoutes } from 'pages/SignIn/routes';

import { CreateUserForm } from './CreateUserForm';
import { invitesRoutes } from './routes';
import { InviteState } from './types';

export const CreateUser = () => {
  const { state: { data, key, ssoProviders } = {} } = useLocation<InviteState>();
  const history = useHistory();

  useEffectOnce(() => {
    if (!data) {
      history.push(signInRoutes.base);
    }
    if (ssoProviders && ssoProviders.length !== 0) {
      history.push(invitesRoutes.createUserFromSSO, { data, key, ssoProviders });
    }
  });

  return (
    <Page heading="Accept invite" title="Invites - Create User">
      <PagePanel id="panel">
        <SpacedText align="center" id="description">
          Choose a password to finish setting up your Grafana Cloud account and get added to the {data?.orgName}{' '}
          organization.
        </SpacedText>
        <CreateUserForm />
      </PagePanel>
    </Page>
  );
};
