/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Heading } from 'components/Heading';
import { Link, LinkProps } from 'components/Link';
import { Page, PagePanel } from 'components/Page';
import { RequestIndicator, RequestIndicatorProps } from 'components/RequestIndicator';
import { ErrorText, Text } from 'components/Text';
import { RedirectContext } from 'context/Redirect';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { redirectToRoutes } from 'pages/RedirectTo/routes';
import { Endpoints, post } from 'utils/api';
import { useStyles } from 'utils/useStyles';

import { CheckInboxForm } from './CheckInboxForm';
import { signUpRoutes } from './routes';

export const CheckInbox = () => {
  const [
    {
      userMeta: { userIsLoggedIn, userHasConfirmedEmail },
    },
    { signOut, getUser },
  ] = useContext(UserContext);
  const [{ instance, to }, { getRedirectUrl, redirectToStateInstanceHome, redirectToTo, redirectToPortal }] =
    useContext(RedirectContext);
  const [, { faro }] = useContext(TrackingContext);
  const [isSending, setIsSending] = useState(false);
  const [serverError, setServerError] = useState<string>();
  const [isRequestReady, setIsRequestReady] = useState(false);
  const styles = useStyles(getStyles);
  const [userEmailState, setUserEmailState] = useState<string | undefined>('');

  const history = useHistory();

  const getUserEmail = async () => {
    const newUserData = await getUser();
    setUserEmailState(newUserData?.email);
  };

  const emailJsx = (
    <p css={styles.checkInboxPText}>
      Check your inbox (<strong>{userEmailState}</strong>) and click the confirmation link or use the activation code
      we&apos;ve sent.
    </p>
  );

  useEffectOnce(() => {
    getUserEmail();

    if (!userIsLoggedIn) {
      history.push(signUpRoutes.createUser);
    } else if (userHasConfirmedEmail) {
      if (to) {
        redirectToTo();
      } else if (instance) {
        redirectToStateInstanceHome();
      } else {
        redirectToPortal();
      }
    }
  });

  const handleResendEmailClick: LinkProps['onClick'] = async () => {
    if (!isSending && !isRequestReady) {
      setIsSending(true);

      try {
        await post(Endpoints.RESEND_VALIDATION_EMAIL, {
          redirectUrl: getRedirectUrl(instance ? redirectToRoutes.instanceHome : signUpRoutes.selectOrganisation),
        });
      } catch (err) {
        faro.trackError(err);
        setServerError(err.message);
      } finally {
        setIsSending(false);
        setIsRequestReady(true);
      }
    }
  };

  const handleIndicatorOnHide: RequestIndicatorProps['onHide'] = () => {
    setIsRequestReady(false);
    setServerError(undefined);
  };

  const handleGetBackToSignUpClick: LinkProps['onClick'] = () => {
    signOut(signUpRoutes.createUser);
  };

  return (
    <Page
      heading="Please confirm you're a human"
      title="Sign Up - Check Inbox"
      headerIcon="check-inbox"
      headingWidth="full"
      css={styles.createUserPageStyleOverride}
    >
      <PagePanel id="panel" type="full">
        <Heading id="panel-heading" type={'2'}>
          We love software. But we make it for people, not bots.
        </Heading>
        <Text align="left" id="description" color={'gray11'} css={styles.modifiedTextMargins}>
          {emailJsx}
        </Text>
        <CheckInboxForm
          isResendReady={isRequestReady}
          isResendSuccess={!serverError}
          onHideRequestIndicator={handleIndicatorOnHide}
          onResendEmail={handleResendEmailClick}
        />

        <div css={styles.newResendConfirmText}>
          <Text align="center" id="actions" type={'normal'} css={styles.actions}>
            Not receiving the email?{' '}
            <Link
              id="resend-email-btn"
              label="Resend the confirmation email"
              onClick={handleResendEmailClick}
              type="button"
            />
            <br />
            {isRequestReady && (
              <RequestIndicator
                errorTitle="Email was not sent"
                id="resend-email-indicator"
                success={!serverError}
                successTitle="Email sent"
                onHide={handleIndicatorOnHide}
              />
            )}{' '}
            <br />
            <span css={styles.goBackToSignup}>
              Wrong email?{' '}
              <Link id="link-back" label="Go back to signup" onClick={handleGetBackToSignUpClick} type="button" />
            </span>
          </Text>
        </div>

        {serverError && <ErrorText id="resend-email-server-error">{serverError}</ErrorText>}
      </PagePanel>
    </Page>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  actions: css`
    margin-top: 21px;
    color: #24292ebf;
    text-align: center;
  `,
  modifiedTextMargins: css`
    margin: 32px 0 7px;

    @media (max-width: 550px) {
      font-size: 14px;
      margin: 24px 0 7px;
    }
  `,
  newResendConfirmText: css`
    color: var(--text-secondary, rgba(36, 41, 46, 0.75));
    text-align: center;

    /* Body */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.021px;

    p {
      text-align: center;
      justify-content: center;
    }
  `,
  goBackToSignup: css`
    margin-top: 15px;
    background-color: #fff;
  `,
  createUserPageStyleOverride: css`
    #page-content {
      background-color: ${colors.white};
      box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.05) !important;
      width: 610px;

      @media (max-width: 768px) {
        margin: 24px;
        padding: 48px;
        width: 90%;
      }

      @media (max-width: 550px) {
        padding: 24px 0;
        max-width: 414px;
      }

      @media (max-width: 400px) {
        h1 {
          padding: 0 12px;
        }
      }
    }
    #page-panels,
    #panel {
      width: 510px;

      @media (max-width: 768px) {
        padding: 0px;
        margin: 0px;
        width: 100%;
      }

      @media (max-width: 550px) {
        padding: 0 24px;
      }
    }

    button#submit {
      margin-top: 16px;
    }
  `,
  checkInboxPText: css`
    margin: 0;
  `,
});
