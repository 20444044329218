import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, ButtonProps } from 'components/Button';
import { Link, LinkProps } from 'components/Link';
import { Page, PagePanel } from 'components/Page';
import { Text } from 'components/Text';
import { RedirectContext } from 'context/Redirect';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { signInRoutes } from 'pages/SignIn/routes';

import { InviteState } from './types';

export const InvalidUser = () => {
  const [, { redirectToPortal }] = useContext(RedirectContext);
  const { state: { data } = {} } = useLocation<InviteState>();
  const [, { signOut }] = useContext(UserContext);
  const history = useHistory();

  useEffectOnce(() => {
    if (!data) {
      history.push(signInRoutes.base);
    }
  });

  const handleLogOutCurrentAccountClick: LinkProps['onClick'] = () => {
    signOut(signInRoutes.base);
  };

  const handleGoToPortalClick: ButtonProps['onClick'] = () => {
    redirectToPortal();
  };

  return (
    <Page heading="Oh no! This invite failed." title="Invites - Invalid User">
      <PagePanel id="panel">
        <Text align="center" id="description">
          The invite for this org was sent to a different email address. To accept this invitation, please either{' '}
          <Link
            id="sign-out-btn"
            label="log out of your current account"
            onClick={handleLogOutCurrentAccountClick}
            type="button"
          />{' '}
          and then re-accept, or accept in a new incognito window.
        </Text>
        <Button variant="primary" id="go-to-portal-btn" label="Go to portal" onClick={handleGoToPortalClick} />
      </PagePanel>
    </Page>
  );
};
