/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { ReactNode } from 'react';

import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { StylingContextState } from 'context/Styling';
import { useStyles } from 'utils/useStyles';

const getStyles = ({ colors }: StylingContextState) => ({
  container: css`
    background-color: rgba(224, 34, 110, 0.08);
    border-radius: 2px;
    border: 1px solid var(--border-medium, rgba(36, 41, 46, 0.3));
    display: flex;
    margin: 0.8rem 0 0;
    padding: 1.8rem;
    width: 100%;
    color: ${colors.gray9};
  `,
  icon: css`
    color: ${colors.gray9};
    height: 24px;
    width: 24px;
  `,
  right: css`
    padding-left: 1.5rem;
  `,
  title: css`
    font-weight: 500;
    margin: 0;
  `,
  message: css`
    margin-bottom: 0;
    margin-top: 8px;
  `,
});

export interface ErrorBoxProps {
  children: ReactNode;

  className?: string;
  id?: string;
  title?: string;
}

export const ErrorBox = ({ children, className, id, title = 'Whoops, something went wrong.' }: ErrorBoxProps) => {
  const styles = useStyles(getStyles);

  return (
    <div className={cx('error-box', className)} css={styles.container} id={id}>
      <div>
        <Icon css={styles.icon} name={'exclamation-circle2'} title="Error box icon" />
      </div>
      <div css={styles.right}>
        <Text align="left" color={'gray11'} css={styles.title} type="small">
          {title}
        </Text>
        {children && (
          <Text align="left" color={'gray11'} css={styles.message} type="small">
            {children}
          </Text>
        )}
      </div>
    </div>
  );
};
