type PrimaryRoutes = Record<string, string>;
type GeneratedRoutes<T extends PrimaryRoutes> = {
  [label in keyof T]: string;
} & {
  base: string;
};

export const generateRoutes = <P extends PrimaryRoutes>(routes: P, base: string): GeneratedRoutes<P> => {
  base = `/${base}`;
  const mappedPaths = Object.entries(routes).map(([key, value]) => [key, `${base}/${value}`]);

  const result = Object.fromEntries(mappedPaths);
  result.base = base;

  return result;
};
