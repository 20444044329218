import { ReactNode, useState } from 'react';

import { useEffectOnce } from 'hooks/useEffectOnce';
import { useQuery } from 'hooks/useQuery';
import { Endpoints, get } from 'utils/api';

import { defaultDashboardContextState, DashboardContext } from './dashboardContext';
import { Dashboard } from './types';

export interface DashboardProviderProps {
  children: ReactNode;
}

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const [dashboard, setDashboard] = useState(defaultDashboardContextState);

  const dashboardIdParam = useQuery('pg');
  const dashboardId = useQuery(dashboardIdParam === 'dashboards' ? 'plcmt' : 'pg')?.replace(/\D/g, '');

  useEffectOnce(() => {
    const getDashboardData = async () => {
      try {
        const dashboard = await get<Dashboard>(`${Endpoints.DASHBOARDS}/${dashboardId}`);
        if (dashboard.statusCode === 'active') {
          setDashboard({ dashboard });
        }
      } catch (err) {}
    };

    if (dashboardId) {
      getDashboardData();
    }
  });

  return <DashboardContext.Provider value={dashboard}>{children}</DashboardContext.Provider>;
};
