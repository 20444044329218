import { generateRoutes } from 'utils/generateRoutes';

export const redirectToRoutes = generateRoutes(
  {
    instanceHome: 'instance-home',
    instanceOnboarding: 'instance-onboarding',
    portal: 'portal',
    to: 'to',
    postSso: 'post-sso',
    postSsoError: 'post-sso-error',
  },
  'redirect'
);
