import { ReactNode, useState } from 'react';

import { useEffectOnce } from 'hooks/useEffectOnce';
import { Endpoints, get } from 'utils/api';

import { defaultStackRegionContextState, StackRegionContext } from './stackRegionContext';
import { GetStackRegionsFn, SetStackRegionsFn, StackRegion } from './types';

export interface StackRegionProviderProps {
  children: ReactNode;
}

export const StackRegionProvider = (props: StackRegionProviderProps) => {
  const [stackRegionState, setStackRegionState] = useState(defaultStackRegionContextState);

  const setStackRegions: SetStackRegionsFn = async (data) => {
    setStackRegionState((stackRegionState) => ({
      ...stackRegionState,
      stackRegionsData: data,
      isLoading: false,
    }));
  };

  const getStackRegions: GetStackRegionsFn = async () => {
    let stackRegions;

    try {
      stackRegions = await get<{ items: StackRegion[] }>(`${Endpoints.GET_STACK_REGIONS}?provider=aws`);
    } catch (err) {
      stackRegions = null;
    }

    const regions = stackRegions?.items ?? [];
    await setStackRegions(regions);
    return regions;
  };

  useEffectOnce(() => {
    getStackRegions();
  });

  return (
    <StackRegionContext.Provider value={[stackRegionState, { getStackRegions, setStackRegions }]}>
      {stackRegionState.isLoading || !props?.children ? null : props.children}
    </StackRegionContext.Provider>
  );
};
