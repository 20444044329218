import { CustomSignupProductType } from 'pages/SignUp/types';
import { ORIGIN, GRAFANA_COM_DOMAIN, PUBLIC_URL } from 'utils/consts';

export type CustomProductRedirects = (typeof customSignupProducts)[number]['redirect'];

/** How to use this file:
 * Below are the custom signups contained within this spreadsheet
 * https://docs.google.com/spreadsheets/d/1kUui1g2ouTMhNH_H1zX_SonZtnj0rER4YXSTC2pQYf4/edit?pli=1#gid=821683971
 * which is managed by GTM, starting with Chieu Pham. To update copy on a signup page
 * simply update this file. Here's how to do that:
 *
 * Required fields:
 * `key`: the "current logic" column and what will be parsed both as a string and as query params
 *        in the redirectSetter function in the file `customSignupHelpers.tsx`,
 * `title`: the copy that will be most prominent on the custom signup form,
 * `subtitle`: an array of bullets,
 * `redirect`: the url the user is redirected to after verfication and initialization,
 * `imgAlt`: self-explanatory (the word "logo" is appended to this in the `CustomSignup` component)
 * `imgSrc`: source of where the logo image comes from
 *
 * Optional fields:
 * `isHeroImg`: the default logo is an icon size. When set to true, it renders a large size logo
 * `isNumber`: The checkmark bulle points change from default checkmarks to number list
 * `description`: subtitle of bullet points
 */
export const customSignupProducts: CustomSignupProductType[] = [
  {
    key: 'k6',
    title: 'Sign up for Grafana Cloud and start load testing with k6',
    description:
      'Grafana Cloud k6 builds on Grafana Labs’ acquisition of k6 by natively integrating load and performance testing in the fully managed Grafana Cloud observability stack.',
    subtitle: [
      'Collaborative performance testing',
      'Seamless CLI integration',
      'Worldwide test execution',
      'Free forever access to 500 virtual hours for 3 users',
    ],
    imgAlt: 'k6',
    imgSrc: `${PUBLIC_URL}/img/k6-logo.svg`,
    redirect: `a/k6-app/initialize`,
  },
  {
    key: 'kubernetes',
    title: 'Sign up for Grafana Cloud to monitor Kubernetes',
    subtitle: [
      'Pre-built Grafana dashboards and alerts tailored for monitoring Kubernetes',
      'Spot problems at the highest level and drill down within your infrastructure to find the crashing pod',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces for 3 users',
    ],
    imgAlt: 'Kubernetes',
    imgSrc: `${PUBLIC_URL}/img/kubernetes-logo.svg`,
    redirect: `a/grafana-k8s-app/cluster`,
  },
  {
    key: 'irm',
    title: 'Sign up for Grafana Cloud with IRM',
    subtitle: [
      'Quickly detect issues and changes in your production systems',
      'Easily escalate alerts to the right teams',
      'Automate declaring, assigning tasks, and communicating for incidents of any severity',
      'Identify actionable insights on incidents across services, teams, or geos',
    ],
    imgAlt: 'IRM - Grafana Alerting, Incident, and OnCall',
    imgSrc: `${ORIGIN}/static/assets/img/logos/grafana-irm-logos-graphic.svg`,
    redirect: `alerts-and-incidents`,
  },
  {
    key: 'incident',
    title: 'Sign up for Grafana Cloud with Incident',
    subtitle: [
      'Powerful incident management, built on top of Grafana',
      'Automate your incident response processes',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces for 3 users',
    ],
    imgAlt: 'Grafana Incident',
    imgSrc: `${ORIGIN}/static/assets/img/grafana-incident-icon.svg`,
    redirect: `a/grafana-incident-app/incidents`,
  },
  {
    key: 'oncall',
    title: 'Sign up for Grafana Cloud with Oncall',
    subtitle: [
      'Reduce toil in on-call management through simpler workflows and interfaces',
      'Use simpler workflows and interfaces that are tailored specifically for engineers',
      'Free forever access for 3 users',
    ],
    imgAlt: 'Grafana On Call',
    imgSrc: `${ORIGIN}/static/img/logos/logo-grafana-oncall-oss.svg`,
    redirect: `a/grafana-oncall-app`,
  },
  {
    key: 'frontend-observability',
    title: 'Sign up for Grafana Cloud to gain real user monitoring insights',
    subtitle: [
      'Quantify end user experience ',
      'Easily track errors',
      'End-to-end view into every user interaction',
      'Free forever access to 50k Frontend sessions per month',
    ],
    imgAlt: 'frontend-observability',
    imgSrc: `${ORIGIN}/static/assets/img/logos/grafana-logo-faro.svg`,
    redirect: `a/grafana-kowalski-app/apps/new`,
  },
  {
    key: 'slo',
    title: 'Sign up for Grafana Cloud and confidently create, manage, and scale SLOs',
    subtitle: [
      'Guided wizard builds and maintains SLO, alerts, and dashboards for each SLO created',
      'Reduce alert noise and engineer fatigue by monitoring through customer-impacting and service-oriented SLOs',
      'Free forever access up to 25 SLOs',
    ],
    imgAlt: 'slo',
    imgSrc: `${PUBLIC_URL}/img/slo-logo.svg`,
    redirect: `a/grafana-slo-app/home`,
  },
  {
    key: 'application-observability',
    title: 'Sign up for Grafana Cloud to observe applications',
    subtitle: [
      'Opinionated dashboards and workflows',
      'Unified observability across metrics, logs, traces',
      'OpenTelemetry and Prometheus native',
      'Free forever access to 2,232 Application Observability host hours per month',
    ],
    imgAlt: '',
    imgSrc: '',
    redirect: `a/grafana-app-observability-app/services`,
  },
  {
    key: 'dashboards',
    title: 'Get started with dashboards in Grafana Cloud',
    subtitle: ['Create an account', 'Connect a data source', 'Import a dashboard'],
    imgAlt: 'All dashboards',
    imgSrc: `${PUBLIC_URL}/img/general-dashboard.svg`,
    redirect: `a/cloud-home-app/onboarding-flow/start`,
    isHeroImg: true,
    isNumber: true,
  },
  {
    key: '1860-node-exporter-full',
    title: 'Get started with dashboards in Grafana Cloud',
    subtitle: ['Create an account', 'Connect a data source', 'Import a dashboard'],
    imgAlt: 'Node Exporter Full Dashboard',
    imgSrc: `${PUBLIC_URL}/img/general-dashboard.svg`,
    redirect: `a/cloud-home-app/onboarding-flow/start`,
    isHeroImg: true,
    isNumber: true,
  },
  {
    key: '4701-jvm-micrometer',
    title: 'Get started with dashboards in Grafana Cloud',
    subtitle: ['Create an account', 'Connect a data source', 'Import a dashboard'],
    imgAlt: 'JVM Micrometer Dashboard',
    imgSrc: `${PUBLIC_URL}/img/general-dashboard.svg`,
    redirect: `a/cloud-home-app/onboarding-flow/start`,
    isHeroImg: true,
    isNumber: true,
  },
  {
    key: '9614-nginx-ingress-controller',
    title: 'Get started with dashboards in Grafana Cloud',
    subtitle: ['Create an account', 'Connect a data source', 'Import a dashboard'],
    imgAlt: 'NGINX Ingress controller dashboard',
    imgSrc: `${PUBLIC_URL}/img/general-dashboard.svg`,
    redirect: `a/cloud-home-app/onboarding-flow/start`,
    isHeroImg: true,
    isNumber: true,
  },
  {
    key: '2587-k6-load-testing-results',
    title: 'Get started with dashboards in Grafana Cloud',
    subtitle: ['Create an account', 'Connect a data source', 'Import a dashboard'],
    imgAlt: 'k6 Load Testing Results dashboard',
    imgSrc: `${PUBLIC_URL}/img/general-dashboard.svg`,
    redirect: `a/cloud-home-app/onboarding-flow/start`,
    isHeroImg: true,
    isNumber: true,
  },
  {
    key: '9710-open-weather-map',
    title: 'Get started with dashboards in Grafana Cloud',
    subtitle: ['Create an account', 'Connect a data source', 'Import a dashboard'],
    imgAlt: 'Open Weather Map dashboard',
    imgSrc: `${PUBLIC_URL}/img/general-dashboard.svg`,
    redirect: `a/cloud-home-app/onboarding-flow/start`,
    isHeroImg: true,
    isNumber: true,
  },
  {
    key: 'synthetic-monitoring',
    title: 'Sign up for Grafana Cloud and start synthetic monitoring',
    subtitle: [
      'Simulate critical user journey',
      'Scale synthetic monitoring with an as-code approach',
      'Troubleshoot and resolve issues faster',
      'Free forever access to 100k Synthetics Test Executions per month',
    ],
    imgAlt: 'Synthetic Monitoring',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-synthetic-monitoring-app/versions/1.10.1/logos/large`,
    redirect: `a/grafana-synthetic-monitoring-app/home`,
  },
  {
    key: 'clickhouse-datasource',
    title: 'Sign up for Grafana Cloud to visualize ClickHouse data',
    subtitle: [
      'Create a ClickHouse data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Grafana ClickHouse',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-clickhouse-datasource/versions/3.0.0/logos/large`,
    redirect: `connections/datasources/grafana-clickhouse-datasource`,
  },
  {
    key: 'alertmanager',
    title: 'Sign up for Grafana Cloud to visualize Alertmanager data',
    subtitle: [
      'Create an Alertmanager data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Grafana Alertmanager',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/alertmanager/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/alertmanager`,
  },
  {
    key: 'azure-monitor-datasource',
    title: 'Sign up for Grafana Cloud to visualize Azure Monitor data',
    subtitle: [
      'Create an Azure Monitor data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Azure Monitor',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-azure-monitor-datasource/versions/1.0.0/logos/large`,
    redirect: `connections/datasources/grafana-azure-monitor-datasource`,
  },
  {
    key: 'cloudwatch',
    title: 'Sign up for Grafana Cloud to visualize CloudWatch data',
    subtitle: [
      'Create an Azure Monitor data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'CloudWatch',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/cloudwatch/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/cloudwatch`,
  },
  {
    key: 'elasticsearch',
    title: 'Sign up for Grafana Cloud to visualize Elasticsearch data',
    subtitle: [
      'Create an Elasticsearch data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Elasticsearch',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/elasticsearch/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/elasticsearch`,
  },
  {
    key: 'stackdriver',
    title: 'Sign up for Grafana Cloud to visualize Google Cloud Monitoring data',
    subtitle: [
      'Create a Google Cloud Monitoring data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Google Cloud Monitoring',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/stackdriver/versions/1.0.0/logos/large`,
    redirect: `connections/datasources/stackdriver`,
  },
  {
    key: 'graphite',
    title: 'Sign up for Grafana Cloud to visualize Graphite data',
    subtitle: [
      'Create a Graphite data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Graphite',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/graphite/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/graphite`,
  },
  {
    key: 'influxdb',
    title: 'Sign up for Grafana Cloud to visualize InfluxDB data',
    subtitle: [
      'Create an InfluxDB data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'InfluxDB',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/influxdb/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/influxdb`,
  },
  {
    key: 'jaeger',
    title: 'Sign up for Grafana Cloud to visualize Jaeger data',
    subtitle: [
      'Create a Jaeger data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Jaeger',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/jaeger/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/jaeger`,
  },
  {
    key: 'loki',
    title: 'Sign up for Grafana Cloud to visualize Loki data',
    subtitle: [
      'Create a Loki data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Grafana Loki',
    imgSrc: `${ORIGIN}/static/img/logos/logo-loki.svg`,
    redirect: `connections/datasources/loki`,
  },
  {
    key: 'mssql',
    title: 'Sign up for Grafana Cloud to visualize Microsoft SQL Server data',
    subtitle: [
      'Create a Microsoft SQL data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Microsoft SQL Server',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/mssql/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/mssql`,
  },
  {
    key: 'pg=plugins&plcmt=mysql',
    title: 'Sign up for Grafana Cloud to visualize MySQL data',
    subtitle: [
      'Create a MySQL data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'MySQL Server',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/mysql/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/mysql`,
  },
  {
    key: 'opentsdb',
    title: 'Sign up for Grafana Cloud to visualize OpenTSDB data',
    subtitle: [
      'Create a OpenTSDB data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'OpenTSDB',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/opentsdb/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/opentsdb`,
  },
  {
    key: 'pg=plugins&plcmt=postgres',
    title: 'Sign up for Grafana Cloud to visualize PostgresSQL data',
    subtitle: [
      'Create a PostgressSQL data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'PostgreSQL',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/postgres/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/postgres`,
  },
  {
    key: 'prometheus',
    title: 'Sign up for Grafana Cloud to visualize Prometheus data',
    subtitle: [
      'Create a Prometheus data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Prometheus',
    imgSrc: `${ORIGIN}/static/img/metrics/prometheus-icon.svg`,
    redirect: `connections/datasources/prometheus`,
  },
  {
    key: 'tempo',
    title: 'Sign up for Grafana Cloud to visualize Tempo data',
    subtitle: [
      'Create a Tempo data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Grafana Tempo',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/tempo/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/tempo`,
  },
  {
    key: 'zipkin',
    title: 'Sign up for Grafana Cloud to visualize Zipkin data',
    subtitle: [
      'Create a Zipkin data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Zipkin',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/zipkin/versions/5.0.0/logos/large`,
    redirect: `connections/datasources/zipkin`,
  },
  {
    key: 'redis-datasource',
    title: 'Sign up for Grafana Cloud to visualize Redis data',
    subtitle: [
      'Create a Redia data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Redis Visualize',
    imgSrc: `${ORIGIN}/static/img/logos/logo-redis.svg`,
    redirect: `connections/datasources/redis`,
  },
  {
    key: 'athena-datasource',
    title: 'Sign up for Grafana Cloud to visualize Amazon Athena data',
    subtitle: [
      'Create an Amazon Athena data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Athena Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-athena-datasource/versions/2.9.1/logos/large`,
    redirect: `connections/datasources/athena`,
  },
  {
    key: 'redshift-datasource',
    title: 'Sign up for Grafana Cloud to visualize Amazon Redshift data',
    subtitle: [
      'Create an Amazon Redshift data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Redshift Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-redshift-datasource/versions/1.8.1/logos/large`,
    redirect: `connections/datasources/redshift`,
  },
  {
    key: 'timestream-datasource',
    title: 'Sign up for Grafana Cloud to visualize Amazon Timestream data',
    subtitle: [
      'Create an Amazon Timestream data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Timestream Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-timestream-datasource/versions/2.6.0/logos/large`,
    redirect: `connections/datasources/timestream`,
  },
  {
    key: 'appdynamics-datasource',
    title: 'Sign up for Grafana Cloud to visualize AppDynamics data',
    subtitle: [
      'Create an AppDynamics data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'App Dynmaics Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/dlopes7-appdynamics-datasource/versions/3.0.12/logos/large`,
    redirect: `connections/datasources/grafana-appdynamics-datasource`,
  },
  {
    key: 'datadog-datasource',
    title: 'Sign up for Grafana Cloud to visualize Datadog data',
    subtitle: [
      'Create a Datadog data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Datadog Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-datadog-datasource/versions/latest/logos/small`,
    redirect: `connections/datasources/grafana-datadog-datasource`,
  },
  {
    key: 'dynatrace-datasource',
    title: 'Sign up for Grafana Cloud to visualize Dynatrace data',
    subtitle: [
      'Create a Dynatrace data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Dynatrace Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-dynatrace-datasource/versions/latest/logos/large`,
    redirect: `connections/datasources/grafana-dynatrace-datasource`,
  },
  {
    key: 'gitlab-datasource',
    title: 'Sign up for Grafana Cloud to visualize Gitlab data',
    subtitle: [
      'Create a Gitlab data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Gitlab Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-gitlab-datasource/versions/latest/logos/large`,
    redirect: `connections/datasources/grafana-gitlab-datasource`,
  },
  {
    key: 'grafana-honeycomb-datasource',
    title: 'Sign up for Grafana Cloud to visualize Honeycomb data',
    subtitle: [
      'Create a Honeycomb data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Honeycomb Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-honeycomb-datasource/versions/latest/logos/small`,
    redirect: `connections/datasources/grafana-honeycomb-datasource`,
  },
  {
    key: 'jira-datasource',
    title: 'Sign up for Grafana Cloud to visualize Jira data',
    subtitle: [
      'Create a Jira data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Jira Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-jira-datasource/versions/latest/logos/small`,
    redirect: `connections/datasources/grafana-jira-datasource`,
  },
  {
    key: 'mongodb-datasource',
    title: 'Sign up for Grafana Cloud to visualize MongoDB data',
    subtitle: [
      'Create a MongoDB data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'MongoDB Data Source',
    imgSrc: `${ORIGIN}/static/img/logos/mongodb-green-leaf.svg`,
    redirect: `connections/datasources/grafana-mongodb-datasource`,
  },
  {
    key: 'newrelic-datasource',
    title: 'Sign up for Grafana Cloud to visualize New Relic data',
    subtitle: [
      'Create a New Relic data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'New Relic Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-newrelic-datasource/versions/latest/logos/small`,
    redirect: `connections/datasources/grafana-newrelic-datasource`,
  },
  {
    key: 'oracle-datasource',
    title: 'Sign up for Grafana Cloud to visualize Oracle data',
    subtitle: [
      'Create an Oracle data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Oracle Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-oracle-datasource/versions/2.4.3/logos/large`,
    redirect: `connections/datasources/grafana-oracle-datasource`,
  },
  {
    key: 'salesforce-datasource',
    title: 'Sign up for Grafana Cloud to visualize Salesforce data',
    subtitle: [
      'Create a Salesforce data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Salesforce Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-salesforce-datasource/versions/1.0.0/logos/large`,
    redirect: `connections/datasources/grafana-salesforce-datasource`,
  },
  {
    key: 'saphana-datasource',
    title: 'Sign up for Grafana Cloud to visualize SAP HANA data',
    subtitle: [
      'Create an SAP HANA data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'SAP HANA Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/media/images/logos/sap.png`,
    redirect: `connections/datasources/grafana-saphana-datasource`,
  },
  {
    key: 'servicenow-datasource',
    title: 'Sign up for Grafana Cloud to visualize ServiceNow data',
    subtitle: [
      'Create a ServiceNow data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'ServiceNow Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-servicenow-datasource/versions/latest/logos/small`,
    redirect: `connections/datasources/grafana-servicenow-datasource`,
  },
  {
    key: 'snowflake-datasource',
    title: 'Sign up for Grafana Cloud to visualize Snowflake data',
    subtitle: [
      'Create a Snowflake data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Snowflake Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-snowflake-datasource/versions/latest/logos/small`,
    redirect: `connections/datasources/grafana-snowflake-datasource`,
  },
  {
    key: 'splunk-datasource',
    title: 'Sign up for Grafana Cloud to visualize Splunk Infra Monitoring data',
    subtitle: [
      'Create a Splunk data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Splunk Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-splunk-datasource/versions/latest/logos/small`,
    redirect: `connections/datasources/grafana-splunk-datasource`,
  },
  {
    key: 'splunk-monitoring-datasource',
    title: 'Sign up for Grafana Cloud to visualize Splunk data',
    subtitle: [
      'Create a Splunk Infra Monitoring data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Splunk Infra Monitoring Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-splunk-monitoring-datasource/versions/latest/logos/small`,
    redirect: `connections/datasources/grafana-splunk-monitoring-datasource`,
  },
  {
    key: 'wavefront-datasource',
    title: 'Sign up for Grafana Cloud to visualize Wavefront data',
    subtitle: [
      'Create a Wavefront data source and visualize your data',
      'Free forever access to 10K metrics + 50GB logs + 50GB traces',
    ],
    imgAlt: 'Wavefront Data Source',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-wavefront-datasource/versions/latest/logos/small`,
    redirect: `connections/datasources/grafana-wavefront-datasource`,
  },
  {
    key: 'asterisk-monitor',
    title: 'Sign up for Grafana Cloud to monitor Asterisk',
    subtitle: [
      'Easily monitor your Asterisk deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Asterisk',
    imgSrc: `${ORIGIN}/static/img/logos/asterisk_icon.svg`,
    redirect: `connections/add-new-connection/asterisk`,
  },
  {
    key: 'confluent-cloud-monitor',
    title: 'Sign up for Grafana Cloud to monitor Confluent Cloud',
    subtitle: [
      'Easily monitor your Confluent Cloud deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Confluent Cloud',
    imgSrc: `${ORIGIN}/static/img/logos/confluent-cloud-logo.svg`,
    redirect: `connections/add-new-connection/confluent-cloud`,
  },
  {
    key: 'docker-monitor',
    title: 'Sign up for Grafana Cloud to monitor Docker',
    subtitle: [
      'Easily monitor your Docker deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Docker',
    imgSrc: `${ORIGIN}/static/img/logos/docker_logo.svg`,
    redirect: `connections/add-new-connection/docker`,
  },
  {
    key: 'jenkins-monitor',
    title: 'Sign up for Grafana Cloud to monitor Jenkins',
    subtitle: [
      'Easily monitor your Jenkins deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Jenkins',
    imgSrc: `${ORIGIN}/static/img/logos/452px-jenkins_logo.svg.png`,
    redirect: `connections/add-new-connection/jenkins`,
  },
  {
    key: 'linux-monitor',
    title: 'Sign up for Grafana Cloud to monitor Linux',
    subtitle: [
      'Easily monitor your Linux deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Linux',
    imgSrc: `${ORIGIN}/static/img/logos/logo-linux.svg`,
    redirect: `connections/add-new-connection/linux-node`,
  },
  {
    key: 'macos-monitor',
    title: 'Sign up for Grafana Cloud to monitor MacOS',
    subtitle: [
      'Easily monitor your MacOS deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'MacOs',
    imgSrc: `https://storage.googleapis.com/grafanalabs-integration-logos/apple.svg`,
    redirect: `connections/add-new-connection/macos-node`,
  },
  {
    key: 'mysql-monitor',
    title: 'Sign up for Grafana Cloud to monitor MySQL',
    subtitle: [
      'Easily monitor your MySQL deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'MySQL',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/mysql/versions/5.0.0/logos/large`,
    redirect: `connections/add-new-connection/mysql`,
  },
  {
    key: 'nodejs-monitor',
    title: 'Sign up for Grafana Cloud to monitor Node JS',
    subtitle: [
      'Easily monitor your Node JS deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Node JS',
    imgSrc: `${ORIGIN}/static/img/logos/nodejs_icon.svg`,
    redirect: `connections/add-new-connection/nodejs`,
  },
  {
    key: 'postgresql-monitor',
    title: 'Sign up for Grafana Cloud to monitor PostgresSQL',
    subtitle: [
      'Easily monitor your PostgresSQL deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'PostgresSQL',
    imgSrc: `${ORIGIN}/static/img/logos/postgresql_elephant_icon.svg`,
    redirect: `connections/add-new-connection/postgres`,
  },
  {
    key: 'redis-monitor',
    title: 'Sign up for Grafana Cloud to monitor Redis',
    subtitle: [
      'Easily monitor your Redis deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Redis Monitor',
    imgSrc: `${ORIGIN}/static/img/logos/logo-redis.svg`,
    redirect: `connections/add-new-connection/redis`,
  },
  {
    key: 'windows-monitor',
    title: 'Sign up for Grafana Cloud to monitor Windows',
    subtitle: [
      'Easily monitor your Windows deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Windows',
    imgSrc: `${ORIGIN}/static/img/logos/microsoft_logo.svg.png`,
    redirect: `connections/add-new-connection/windows-exporter`,
  },
  {
    key: 'apolloserver-monitor',
    title: 'Sign up for Grafana Cloud to monitor Apollo Server',
    subtitle: [
      'Easily monitor your Apollo Server deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Apollo Server',
    imgSrc: `https://storage.googleapis.com/grafanalabs-integration-logos/apollo.svg`,
    redirect: `connections/add-new-connection/apollo-server`,
  },
  {
    key: 'ciliumenterprise-monitor',
    title: 'Sign up for Grafana Cloud to monitor Cilium Enterprise',
    subtitle: [
      'Easily monitor your Cilium Enterprise deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Cilium Enterprise',
    imgSrc: `${PUBLIC_URL}/img/cilium-logo.svg`,
    redirect: `connections/add-new-connection/cilium-enterprise`,
  },
  {
    key: 'jira-monitor',
    title: 'Sign up for Grafana Cloud to monitor Jira',
    subtitle: [
      'Easily monitor your Jira deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Jira Monitor',
    imgSrc: `${GRAFANA_COM_DOMAIN}/api/plugins/grafana-jira-datasource/versions/latest/logos/small`,
    redirect: `connections/add-new-connection/jira`,
  },
  {
    key: 'kafka-monitor',
    title: 'Sign up for Grafana Cloud to monitor Kafka',
    subtitle: [
      'Easily monitor your Kafaka deployment with Grafana Cloud’s out-of-the-box monitoring solution',
      'Forever free tier includes 3 users and up to 10k metrics series to support your monitoring needs',
    ],
    imgAlt: 'Kafka Monitor',
    imgSrc: `https://storage.googleapis.com/grafanalabs-integration-logos/kafka.svg`,
    redirect: `connections/add-new-connection/kafka`,
  },
  {
    key: 'plcmt=advanced',
    title: 'Sign up for the Premium Cloud Bundle plan with Grafana Cloud',
    subtitle: [
      'All Grafana Cloud features, Enterprise Plugins included',
      'Includes 20k metrics, 100 GB logs, 100 GB traces, 100 GB profiles, 1000 k6 VUh, & 5 users per month',
      '24x7 Support',
    ],
    imgAlt: '',
    imgSrc: '',
    redirect: `a/cloud-home-app/onboarding-flow/start`,
  },
  {
    key: 'pg=play',
    title: 'The (actually useful) free forever plan.',
    subtitle: [
      'Grafana, of course',
      '10k metrics',
      '50GB logs',
      '50GB traces',
      '50GB profiles',
      '500VUh k6 testing',
      '50k Frontend sessions',
      '14-day retention',
      '3 active users',
    ],
    imgAlt: 'Grafana Cloud',
    imgSrc: `${PUBLIC_URL}/img/signup/signup-multiverse.svg`,
    redirect: `a/cloud-home-app/onboarding-flow/start`,
    description: 'Account includes:',
    isHeroImg: true,
  },
  {
    key: 'oss-migration',
    title: 'The (actually useful) free forever plan',
    subtitle: [
      'Grafana, of course',
      '10k metrics',
      '50GB logs',
      '50GB traces',
      '50GB profiles',
      '500VUh k6 testing',
      '50k Frontend sessions',
      '14-day retention',
      '3 active users',
      '2,232 Application Observability host hours',
    ],
    imgAlt: 'Grafana Cloud',
    imgSrc: `${PUBLIC_URL}/img/signup/signup-multiverse.svg`,
    redirect: `a/grafana-selfservetutorials-app/migrate-oss`,
    description: 'Account includes:',
    isHeroImg: true,
  },
];
