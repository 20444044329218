import { ADOBE_TARGET_DOMAIN, ADOBE_TARGET_OVERRIDE_MBOX_EDGE_SERVER, PUBLIC_URL } from 'utils/consts';
import { noop, noopPromise } from 'utils/noop';

import { LocationDetails } from './types';

export interface WindowProperties {
  adobe?: {
    target: {
      triggerView: (viewName: string) => void;
      getOffer: <T = unknown>(config: {
        mbox: string;
        success: (actions: T) => void;
        error: (status: string, error: string) => void;
      }) => void;
    };
  };
  targetGlobalSettings?: {
    overrideMboxEdgeServer?: boolean;
    serverDomain?: string;
  };
}

const isEnabled = !!ADOBE_TARGET_DOMAIN;

export const initialize = isEnabled
  ? () => {
      window.targetGlobalSettings = {
        overrideMboxEdgeServer: ADOBE_TARGET_OVERRIDE_MBOX_EDGE_SERVER,
        serverDomain: ADOBE_TARGET_DOMAIN,
      };
    }
  : noop;

export const load = isEnabled
  ? () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `${PUBLIC_URL}/scripts/at.js`;
      script.onload = () => {
        trackPage({
          path: location.pathname,
          title: document.title,
          location: location.href,
        });
      };

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  : noop;

export const trackPage = isEnabled
  ? (locationDetails: LocationDetails) => {
      const viewName = locationDetails.path.startsWith('/') ? locationDetails.path.substr(1) : locationDetails.path;

      window.adobe?.target?.triggerView?.(viewName);
    }
  : noop;

export const getData = isEnabled
  ? async <T = any>(mbox: string) => {
      return new Promise<T>((resolve, reject) => {
        if (!window.adobe?.target?.getOffer) {
          reject({
            status: 'error',
            error: 'Adobe Target is not loaded',
          });
        } else {
          window.adobe.target.getOffer?.<T>({
            mbox,
            success: (actions) => {
              resolve(actions);
            },
            error: (status, error) => {
              reject({
                status,
                error,
              });
            },
          });
        }
      });
    }
  : noopPromise;
