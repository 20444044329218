import { TestGroup } from 'types/TestGroup';

import { customSignupProducts } from './CustomSignup.data';
import { CustomSignupProductType } from './types';

export const DASHBOARDS_CONST = 'dashboards';

/**
 * @func getCustomKeySelector and @func getCustomKeySelectorByQueryParams - helper funcs for finding object in customSignupProducts
 * @param search
 * These functions are designed to parse the keys in this spreadsheet (in "New Logic" column of "April 2023 Update" tab), from the URL:
 * https://docs.google.com/spreadsheets/d/1kUui1g2ouTMhNH_H1zX_SonZtnj0rER4YXSTC2pQYf4/edit?pli=1#gid=821683971
 * In the URL, we'll have both basic strings/keys to match, which will be handled by the getCustomKeySelector function in this file;
 * and then we'll also need to parse queryParams. For the New Logic keys that have queryParams in them,
 * such as `/auth/sign-up/create-user?pg=plugins&plcmt=graphite`, the function getCustomKeySelectorByQueryParams
 * will make sure that, in case the query params return in a different order for example, that the correct object is returned
 * from customSignupProducts in the file named `CustomSignup.data.ts`.
 *
 * When new keys or queryParams are being added to the above spreadsheet, you can add them to these functions.
 * The @func redirectSetterFunction below these will also need updating
 *  */
const getCustomKeySelector = (search: string): CustomSignupProductType | undefined =>
  Object.values(customSignupProducts).find((s) => search.includes(s.key));

const getCustomKeySelectorByQueryParams = (
  search: string,
  plcmtParam?: string,
  pgParam?: string,
  group?: TestGroup
): CustomSignupProductType | undefined => {
  if (pgParam?.includes(DASHBOARDS_CONST)) {
    return dashboardsURLProcessing(plcmtParam, group);
  }
  // if plcmtParam and pgParam
  if (plcmtParam && pgParam && search.includes(plcmtParam) && search.includes(pgParam)) {
    return Object.values(customSignupProducts).find((p) => p.key.includes(plcmtParam) && p.key.includes(pgParam));
    // if plcmtParam only
  } else if (plcmtParam && search.includes(plcmtParam)) {
    return Object.values(customSignupProducts).find((p) => p.key.includes(plcmtParam));
    // if pgParam only
  } else if (pgParam && search.includes(pgParam)) {
    return Object.values(customSignupProducts).find((p) => p.key.includes(pgParam));
    // if no query params
  } else {
    return Object.values(customSignupProducts).find((p) => search.includes(p.key));
  }
};

function dashboardsURLProcessing(plcmtParam?: string, group?: TestGroup): CustomSignupProductType | undefined {
  /**
   * For dashboards: If the A/B test `group` variable is undefined, then this is NOT an A/B test, and therefore the setting of
   * customSignupProduct should continue as usual. If `group` === TestGroup.TEST, then we also set the custom redirect as usual.
   * Else (i.e., if `group` === TestGroup.CONTROL) we DONT set the custom redirect and instead we want this function to return
   * general 'dashboards' object from this function
   */
  if (group !== TestGroup.CONTROL) {
    const dashboardName = plcmtParam?.split('sidebar-').slice(1).join('-'); // Dashboards `plcmtParam` will include "sidebar" as a prefix, so let's slice that off the front
    /**
     * Every dashboard in CustomSignup.data will include a `pgParam` attribute that includes the word "dashboards" and if it's a dashboard that requires a
     * custom form it will include a `plcmtParam` that matches a key in the google spreadsheet linked above. For now, the `plcmtParam` will have a prefix
     * (handled by dashboardName variable above) */
    if (plcmtParam && dashboardName && Object.values(customSignupProducts).find((p) => p.key.includes(dashboardName))) {
      return Object.values(customSignupProducts).find((p) => p.key.includes(dashboardName));
    }
  }
  return Object.values(customSignupProducts).find((p) => p.key === DASHBOARDS_CONST);
}

/**
 * @function redirectSetterFunction that will return custom-signup object that is used in the RedirectProvider
 * @param plcmtParam
 * @param pgParam
 * @param group
 */
export const redirectSetter = (
  search: string,
  plcmtParam?: string,
  pgParam?: string,
  group?: TestGroup
): CustomSignupProductType | undefined => {
  // If a dashboard, send logic to getCustomKeySelectorByQueryParams for further parsing
  const isADashboard = pgParam?.includes(DASHBOARDS_CONST);

  if (!isADashboard && getCustomKeySelector(search)) {
    return getCustomKeySelector(search);
  } else if (getCustomKeySelectorByQueryParams(search, plcmtParam, pgParam, group)) {
    return getCustomKeySelectorByQueryParams(search, plcmtParam, pgParam, group);
  }

  return;
};
