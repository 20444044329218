import { Redirect, Switch } from 'react-router-dom';

import { CookiesBar } from 'components/CookiesBar';
import { Layout } from 'components/Layout';
import { DashboardProvider } from 'context/Dashboards';
import { PersistentStateProvider } from 'context/PersistentState';
import { PluginProvider } from 'context/Plugin';
import { RedirectProvider } from 'context/Redirect';
import { StylingProvider } from 'context/Styling';
import { TrackingProvider } from 'context/Tracking';
import { UserProvider } from 'context/User';
import { ForgotPassword } from 'pages/ForgotPassword';
import { forgotPasswordRoutes } from 'pages/ForgotPassword/routes';
import { GrafanaSso } from 'pages/GrafanaSso';
import { grafanaSsoRoutes } from 'pages/GrafanaSso/routes';
import { Invites } from 'pages/Invites';
import { invitesRoutes } from 'pages/Invites/routes';
import { RedirectTo } from 'pages/RedirectTo';
import { redirectToRoutes } from 'pages/RedirectTo/routes';
import { SignIn } from 'pages/SignIn';
import { signInRoutes } from 'pages/SignIn/routes';
import { SignUp } from 'pages/SignUp';
import { signUpRoutes } from 'pages/SignUp/routes';
import { FaroRoute } from 'utils/faro';

import { GlobalStyles } from './GlobalStyles';

export const App = () => {
  return (
    <StylingProvider>
      <PersistentStateProvider>
        <TrackingProvider>
          <UserProvider>
            <DashboardProvider>
              <PluginProvider>
                <RedirectProvider>
                  <Layout>
                    <GlobalStyles />
                    <Switch>
                      <FaroRoute path={signUpRoutes.base} component={SignUp} />
                      <FaroRoute path={signInRoutes.base} component={SignIn} />
                      <FaroRoute path={invitesRoutes.base} component={Invites} />
                      <FaroRoute path={forgotPasswordRoutes.base} component={ForgotPassword} />
                      <FaroRoute path={redirectToRoutes.base} component={RedirectTo} />
                      <FaroRoute path={grafanaSsoRoutes.base} component={GrafanaSso} />
                      <Redirect exact from="/" to={signUpRoutes.base} />
                    </Switch>
                  </Layout>
                  <CookiesBar />
                </RedirectProvider>
              </PluginProvider>
            </DashboardProvider>
          </UserProvider>
        </TrackingProvider>
      </PersistentStateProvider>
    </StylingProvider>
  );
};
