/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useCallback, useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ErrorBox } from 'components/ErrorBox';
import { Input, isRequired, Select, Submit } from 'components/Form';
import { PluginContext } from 'context/Plugin';
import { RedirectContext } from 'context/Redirect';
import { StackRegionContext } from 'context/StackRegion';
import { TrackingContext } from 'context/Tracking';
import { UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { Endpoints, post } from 'utils/api';
import { GRAFANA_NET_DOMAIN } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

import { validateSlugAvailability } from './validateSlugAvailability';

const getStyles = () => ({
  slugInput: css`
    input {
      text-transform: lowercase;
    }
  `,
});

interface SelectOrganisationFormValues {
  slug: string;
  region: string;
  name: string;
}

const formName = 'create-organisation';

export const SelectOrganisationForm = () => {
  const [{ userData }, { getUser, setUser }] = useContext(UserContext);
  const [{ stackRegionsData }] = useContext(StackRegionContext);
  const { plugin } = useContext(PluginContext);
  const [, { faro, trackEvent }] = useContext(TrackingContext);
  const [serverError, setServerError] = useState<string>();
  const [createdOrgs, setCreatedOrgs] = useState<string[]>([]);
  const [startedTrials, setStartedTrials] = useState<string[]>([]);
  const [createdInstances, setCreatedInstances] = useState<string[]>([]);
  const [, { redirectToComputedInstanceOnboarding }] = useContext(RedirectContext);
  const styles = useStyles(getStyles);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    clearErrors,
    setValue,
  } = useForm<SelectOrganisationFormValues>({ mode: 'onBlur' });

  useEffectOnce(() => {
    setValue('slug', userData?.username ?? '', { shouldDirty: true, shouldValidate: true, shouldTouch: true });
    setValue('region', !!stackRegionsData?.length ? stackRegionsData[0].slug : '');
  });

  const handleFormSubmit: SubmitHandler<SelectOrganisationFormValues> = useCallback(
    async (values) => {
      setServerError(undefined);

      try {
        faro.trackFormSubmit(formName);
        let slug: string = values.slug.toLowerCase();

        if (!createdOrgs.includes(slug)) {
          // Select organisation
          const response = await post<{ slug: string }>(Endpoints.SELECT_ORGANISATION, { slug });

          slug = response.slug;

          setCreatedOrgs((createdOrgs) => [...createdOrgs, slug]);
        }

        if (!startedTrials.includes(slug)) {
          // Start trial
          await post<{ id: number; name: string }>(Endpoints.START_TRIAL, {
            name: slug,
            slug,
            ...(plugin && plugin.status === 'enterprise' && { addOns: [`plugin-${plugin.slug}`] }),
          });

          setStartedTrials((startedTrials) => [...startedTrials, slug]);
        }

        if (!createdInstances.includes(slug)) {
          // Create instance
          await post(Endpoints.CREATE_INSTANCE, {
            graphite: true,
            logs: true,
            name: `${slug}.${GRAFANA_NET_DOMAIN}`,
            org: slug,
            plan: 'gcloud',
            prometheus: true,
            url: slug,
            region: values.region,
            ...(plugin && plugin.status === 'active' && { plugins: [`${plugin.slug}`] }),
          });

          setCreatedInstances((createdInstances) => [...createdInstances, slug]);
        }

        const newUserData = await getUser();

        await setUser(newUserData);

        faro.trackFormSubmitSuccess(formName);

        redirectToComputedInstanceOnboarding(newUserData!);

        trackEvent({
          name: `finish-create-stack`,
        });
      } catch (err) {
        const message = err.code === 'Conflict' ? 'Sadly, that URL is already taken. Please try another.' : err.message;
        faro.trackFormSubmitError(formName, err.message);
        faro.trackError(err);

        trackEvent({
          name: `finish-create-stack-fail`,
          error: message,
        });
        setServerError(message);
      }
    },
    [
      createdInstances,
      createdOrgs,
      faro,
      getUser,
      plugin,
      setUser,
      startedTrials,
      trackEvent,
      redirectToComputedInstanceOnboarding,
    ]
  );

  return (
    <form id="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Input
        control={control}
        css={styles.slugInput}
        disabled={isSubmitting}
        errors={errors}
        id="slug"
        label="Stack URL"
        name="slug"
        showLabel
        suffix={'.' + GRAFANA_NET_DOMAIN}
        type="text"
        onChange={() => {
          clearErrors('slug');
        }}
        validate={[isRequired(), validateSlugAvailability()]}
        validMessage="Awesome, that URL is all yours!"
        legend={'Must be one lowercase word, no dots, dashes, underscores, or spaces.'}
        infoTooltip={
          'You can name your stack by team, project, or state (such as production, development, or staging).'
        }
      />

      {stackRegionsData && stackRegionsData.length > 1 && (
        <Select
          control={control}
          disabled={isSubmitting}
          errors={errors}
          id="region"
          label="Deployment region"
          name="region"
          options={stackRegionsData.map((region) => ({ label: region.publicName ?? region.name, value: region.slug }))}
          showLabel
          infoTooltip={'Select a region close to your users for the best performance.'}
        />
      )}
      <Submit
        disabled={!!errors?.slug || isSubmitting}
        id="submit"
        isLoading={isSubmitting}
        label={'Finish Setup'}
        loadingText={'Loading'}
      />
      {serverError && <ErrorBox id="server-error">{serverError}</ErrorBox>}
    </form>
  );
};
