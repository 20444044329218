/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { ReactNode } from 'react';

import { useStyles } from 'utils/useStyles';

import { Text, TextProps } from './Text';

const getStyles = () =>
  css`
    margin: 0 0 3rem;
    color: #24292e;
  `;

export interface SpacedTextProps {
  children: ReactNode;

  align?: TextProps['align'];
  className?: string;
  id?: string;
}

export const SpacedText = ({ children, align, className, id }: SpacedTextProps) => {
  const styles = useStyles(getStyles);

  return (
    <Text align={align} className={cx('spaced-text', className)} css={styles} id={id}>
      {children}
    </Text>
  );
};
