/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Submit } from 'components/Form';
import { Link, LinkProps } from 'components/Link';
import { Page, PagePanel } from 'components/Page';
import { RequestIndicator, RequestIndicatorProps } from 'components/RequestIndicator';
import { ErrorText, Text } from 'components/Text';
import { TrackingContext } from 'context/Tracking';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { signInRoutes } from 'pages/SignIn/routes';
import { Endpoints, post } from 'utils/api';
import { useStyles } from 'utils/useStyles';

import { forgotPasswordRoutes } from './routes';

export const CheckInbox = () => {
  const [, { faro }] = useContext(TrackingContext);
  const { state: { emailOrUsername } = {} } = useLocation<{ emailOrUsername?: string }>();
  const [isSending, setIsSending] = useState(false);
  const [serverError, setServerError] = useState<string>();
  const [isRequestReady, setIsRequestReady] = useState(false);
  const history = useHistory();
  const styles = useStyles(getStyles);

  useEffectOnce(() => {
    if (!emailOrUsername) {
      history.push(forgotPasswordRoutes.recover);
    }
  });

  const handleResendEmailClick: LinkProps['onClick'] = async () => {
    if (!isSending && !isRequestReady) {
      setIsSending(true);

      try {
        await post<boolean>(Endpoints.FORGOT_PASSWORD, { emailOrUsername });
      } catch (err) {
        faro.trackError(err);
        setServerError(err.message);
      } finally {
        setIsSending(false);
        setIsRequestReady(true);
      }
    }
  };

  const handleIndicatorOnHide: RequestIndicatorProps['onHide'] = () => {
    setIsRequestReady(false);
    setServerError(undefined);
  };

  return (
    <Page heading="Check your inbox" title="Forgot Password - Check Inbox" css={styles.content}>
      <PagePanel id="panel" type="full">
        <Text id="description" align="left" color={'gray11'} css={styles.modifiedTextMargins}>
          Please check your inbox and click the link you have received from us to reset your password.
        </Text>

        <Submit id="sign-in-btn" label="Send me back to login" role="link" href={signInRoutes.base} />

        <Text id="resend-email" color={'gray11'} align={'center'}>
          No email? <Link label="Resend confirmation link" onClick={handleResendEmailClick} type="button" />
          {isRequestReady && (
            <RequestIndicator
              errorTitle="Confirmation link was not sent"
              id="resend-email-indicator"
              success={!serverError}
              successTitle="Confirmation link sent"
              onHide={handleIndicatorOnHide}
            />
          )}
        </Text>
        {serverError && <ErrorText className="resend-email-server-error">{serverError}</ErrorText>}
      </PagePanel>
    </Page>
  );
};

const getStyles = () => ({
  modifiedTextMargins: css`
    margin: 32px 0 0px;
    line-height: 22px;

    @media (max-width: 550px) {
      margin: 24px 0 0px;
      font-size: 14px;
    }
  `,
  content: css`
    #page-content {
      box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.05) !important;
    }
  `,
});
