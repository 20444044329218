import { ButtonFill, ButtonSize, ButtonVariant } from 'components/Button/Button.types';
import { RichColor, StylingContextState } from 'context/Styling';
import { palette } from 'context/Styling/palette';

function getPropertiesForButtonSize(size: ButtonSize, context: StylingContextState) {
  switch (size) {
    case 'sm':
      return {
        padding: 1,
        fontSize: context.typography.size.sm,
        height: 3,
      };

    case 'lg':
      return {
        padding: 3,
        fontSize: context.typography.size.lg,
        height: 6,
      };
    case 'md':
    default:
      return {
        padding: 2,
        fontSize: context.typography.size.md,
        height: 4,
      };
  }
}

function getButtonVariantStyles(
  context: StylingContextState,
  variant: ButtonVariant,
  fill: ButtonFill,
  width: ButtonSize
) {
  let color: RichColor | null = null;
  switch (variant) {
    case 'secondary':
      color = context.colors.secondary;
      break;

    case 'destructive':
      color = context.colors.error;
      break;

    case 'success':
      color = context.colors.success;
      break;

    case 'primary':
      color = context.colors.primary;
      break;
  }

  const { height, padding, fontSize } = getPropertiesForButtonSize(width, context);
  const paddingMinusBorder = 8 * padding - 1;

  const disabledStyles = getPropertiesForDisabled(context, fill);
  const baseStyles = {
    padding: `0 ${paddingMinusBorder}px`,
    fontSize,
    fontWeight: 500,
    height: `${height * 8}px`,
    lineHeight: `${8 * height - 2}px`,
    verticalAlign: 'middle',
    borderRadius: '2px',
    flexGrow: width === 'full' ? 1 : '',
    width: width === 'full' ? '100%' : 'auto',
    cursor: 'pointer',
    '&:focus': {
      outline: '2px dotted transparent',
      outlineOffset: '2px',
      boxShadow: `0 0 0 2px ${context.colors.background.canvas}, 0 0 0px 4px ${context.colors.primary.main}`,
      transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
      transitionDuration: '0.2s',
      transitionProperty: 'outline, outline-offset, box-shadow',
    },
    '&:focus-visible': {
      outline: '2px dotted transparent',
      outlineOffset: '2px',
      boxShadow: `0 0 0 2px ${context.colors.background.canvas}, 0 0 0px 4px ${context.colors.primary.main}`,
      transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
      transitionDuration: '0.2s',
      transitionProperty: 'outline, outline-offset, box-shadow',
    },
    '&:focus:not(:focus-visible)': {
      outline: 'none',
      boxShadow: 'none',
    },
    ...disabledStyles,
  };

  if (color) {
    let outlineBorderColor = color.border;
    let borderColor = 'transparent';

    // Secondary button has some special rules as we lack theem color token to
    // specify border color for normal button vs border color for outline button
    if (variant === 'secondary') {
      borderColor = color.border;
      outlineBorderColor = context.colors.border.strong;
    }

    if (fill === 'outline') {
      return {
        ...baseStyles,
        background: 'transparent',
        color: color.text,
        border: `1px solid ${outlineBorderColor}`,
        transition: context.transitions.create(['background-color', 'border-color', 'color'], {
          duration: context.transitions.duration.short,
        }),

        '&:hover': {
          background: color.transparent,
          borderColor: context.colors.emphasize(outlineBorderColor, 0.25),
          color: color.text,
        },
      };
    }

    if (fill === 'text') {
      return {
        ...baseStyles,
        background: 'transparent',
        color: color.text,
        border: '1px solid transparent',
        transition: context.transitions.create(['background-color', 'color'], {
          duration: context.transitions.duration.short,
        }),

        '&:focus': {
          outline: 'none',
          textDecoration: 'none',
        },

        '&:hover': {
          background: color.transparent,
          textDecoration: 'none',
        },
      };
    }

    return {
      ...baseStyles,
      background: color.main,
      padding: '4px',
      color: `${variant === 'primary' ? context.colors.white : color.contrastText}`,
      height: '100%',
      border: `1px solid ${borderColor}`,
      transition: context.transitions.create(['background-color', 'box-shadow', 'border-color', 'color'], {
        duration: context.transitions.duration.short,
      }),

      '&:hover:enabled': {
        background: palette.blueLightMainHover,
      },
    };
  }
  return baseStyles;
}

export function getPropertiesForVariant(
  context: StylingContextState,
  variant: ButtonVariant,
  fill: ButtonFill,
  isLoading: boolean,
  width: ButtonSize
) {
  const defaultButtonStyles = {
    alignItems: 'center',
    backgroundPosition: 'bottom right',
    backgroundSize: '200% 100%',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    margin: '0',
    minWidth: width === 'full' ? 'auto' : '14rem',
    outline: 'none',
    padding: '1.2rem',
    transition: 'all 300ms ease',
    width: width === 'full' ? '100%' : 'auto',

    '&:disabled': {
      opacity: '0.6',
    },
  };

  if (['primary', 'secondary', 'success', 'destructive'].includes(variant)) {
    return getButtonVariantStyles(context, variant, fill, width);
  } else if (variant === 'dark') {
    return {
      ...defaultButtonStyles,
      backgroundColor: context.colors.dark1,
      color: context.colors.white,
      outline: '0.1rem solid transparent',

      '&:hover': {
        backgroundColor: 'transparent',
        backgroundPosition: 'bottom left',
        outlineColor: context.colors.gray5,
      },
      '&:focus': {
        backgroundColor: 'transparent',
        backgroundPosition: 'bottom left',
        boxShadow: `inset 0 0 0 0.2rem ${context.colors.blue3}, 0 0.1rem 0.6rem ${context.colors.blue2}`,
        outlineColor: context.colors.gray5,
      },
      '&:active': {
        backgroundColor: 'transparent',
        backgroundPosition: 'bottom left',
        outlineColor: context.colors.gray5,
      },
    };
  } else {
    // variant = white
    return {
      ...defaultButtonStyles,
      backgroundColor: !isLoading ? context.colors.white : context.colors.gray8,
      color: !isLoading ? context.colors.gray1 : context.colors.white,

      '&:hover': {
        backgroundColor: 'transparent',
        backgroundPosition: 'bottom left',
        color: context.colors.white,
      },
      '&:focus': {
        backgroundColor: 'transparent',
        backgroundPosition: 'bottom left',
        boxShadow: `inset 0 0 0 0.2rem ${context.colors.blue3}, 0 0.1rem 0.6rem ${context.colors.blue2}`,
        color: context.colors.white,
      },
    };
  }
}

function getPropertiesForDisabled(context: StylingContextState, fill: ButtonFill) {
  const disabledStyles = {
    cursor: 'not-allowed',
    boxShadow: 'none',
    color: context.colors.text.disabled,
    transition: 'none',
  };

  if (fill === 'text') {
    return {
      '&:disabled': {
        ...disabledStyles,
        background: 'transparent',
        border: `1px solid transparent`,
      },
      '&[disabled]': {
        ...disabledStyles,
        background: 'transparent',
        border: `1px solid transparent`,
      },
    };
  }

  if (fill === 'outline') {
    return {
      '&:disabled': {
        ...disabledStyles,
        background: 'transparent',
        border: `1px solid ${context.colors.border.weak}`,
      },
      '&[disabled]': {
        ...disabledStyles,
        background: 'transparent',
        border: `1px solid ${context.colors.border.weak}`,
      },
    };
  }

  return {
    '&:disabled': {
      ...disabledStyles,
      background: context.colors.action.disabledBackground,
      border: `1px solid transparent`,
    },
    '&[disabled]': {
      ...disabledStyles,
      background: context.colors.action.disabledBackground,
      border: `1px solid transparent`,
    },
  };
}
