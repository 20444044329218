import { CloudOrgData, SubscriptionCodeType } from 'types/orgTypes';

export const isTrial = (orgData: CloudOrgData | undefined): boolean => {
  if (!!orgData) {
    const currentProductIsFree: boolean = orgData.subscriptions.current.product === SubscriptionCodeType.FREE;
    const currentIsTrial: boolean = orgData.subscriptions.current.isTrial;
    console.log(
      'isTrial - logic',
      currentProductIsFree && currentIsTrial,
      'orgData.subscriptions.current',
      orgData.subscriptions.current
    );
    return currentProductIsFree && currentIsTrial;
  }
  return false;
};
