import { useMediaQueries } from '@react-hook/media-query';
import { ReactNode } from 'react';

import { defaultStylingContextState, StylingContext } from './stylingContext';
import { getTypography } from './typography';

export interface StylingProviderProps {
  children: ReactNode;
}

export const StylingProvider = ({ children }: StylingProviderProps) => {
  const { breakpoints } = defaultStylingContextState;

  const { matches } = useMediaQueries({
    sm: `(min-width: ${breakpoints.sm})`,
    md: `(min-width: ${breakpoints.md})`,
    lg: `(min-width: ${breakpoints.lg})`,
    xl: `(min-width: ${breakpoints.xl})`,
    xxl: `(min-width: ${breakpoints.xxl})`,
    lgHeight: `(max-height: ${breakpoints.lg})`,
  });

  return (
    <StylingContext.Provider
      value={{
        ...defaultStylingContextState,
        theme: 'light',
        typography: getTypography(matches),
        viewport: matches,
      }}
    >
      {children}
    </StylingContext.Provider>
  );
};
