import { createContext } from 'react';

import { DashboardContextState } from './types';

export const defaultDashboardContextState: DashboardContextState = {
  dashboard: null,
};

export const DashboardContext = createContext(defaultDashboardContextState);

DashboardContext.displayName = 'DashboardContext';
