/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { useEffect, useState } from 'react';

import { LoaderTypes } from 'components/Loader/types';
import { Text } from 'components/Text';
import { StylingContextState } from 'context/Styling';
import { PUBLIC_URL } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

const getStyles = (
  _styling: StylingContextState,
  type: LoaderProps['type'],
  hasLabel: boolean,
  isSpinnerToCheckmark?: boolean,
  correctSpinnerSvg?: string
) => css`
  width: ${type === LoaderTypes.SPINNER ? '2.4rem' : type === LoaderTypes.SPINNER_BLUE ? '18px' : 'auto'};
  margin-right: ${hasLabel ? '12px' : '0'};
  vertical-align: bottom;
  color: ${_styling.colors.gray11};

  ${isSpinnerToCheckmark &&
  css`
    animation: fadeIn 1s;
    width: 18px !important;
    vertical-align: text-bottom !important;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `}

  ${correctSpinnerSvg === 'checkmark-done-loading' &&
  css`
    animation: twistUpright 0.75s;

    @keyframes twistUpright {
      0% {
        rotate: 45deg;
        opacity: 0.5;
      }
      100% {
        rotate: 0deg;
        opacity: 1;
      }
    }
  `}
`;

export interface LoaderProps {
  className?: string;
  id?: string;
  label?: string;
  type?: LoaderTypes;
  isSpinnerToCheckmark?: boolean;
}

export const Loader = ({
  className,
  id,
  label,
  type = LoaderTypes.DOTS,
  isSpinnerToCheckmark = false,
}: LoaderProps) => {
  const [animateStyle, setAnimateStyle] = useState<LoaderProps['type']>(LoaderTypes.SPINNER_BLUE);
  const correctSpinnerSvg = type === 'spinner' ? 'spinner-gray' : 'spinner-blue' ? animateStyle : type;
  const styles = useStyles(getStyles, type, !!label, isSpinnerToCheckmark, correctSpinnerSvg);

  useEffect(() => {
    if (isSpinnerToCheckmark) {
      setTimeout(function () {
        setAnimateStyle(LoaderTypes.CHECKMARK_DONE_LOADING);
      }, 1500);
    }
  });

  return (
    <Text className={cx('loader', className)} id={id}>
      <img src={`${PUBLIC_URL}/img/loaders/${correctSpinnerSvg}.svg`} alt="Loader" className="icon" css={styles} />
      {label}
    </Text>
  );
};
