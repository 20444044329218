/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { ReactNode } from 'react';

import { Heading } from 'components/Heading';
import { StylingContextState } from 'context/Styling';
import { PUBLIC_URL } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

import { widths, Widths } from './widths';

const getStyles = ({ colors, viewport: { lg } }: StylingContextState, width: Widths) => {
  return {
    main: css`
      max-width: ${lg ? widths[width] : '100%'};
      display: flex;
      flex-direction: column;
      color: ${colors.gray11};
      margin-bottom: 0;
      justify-content: center;
      text-align: center;

      @media (max-width: 550px) {
        font-size: 24px;
      }
    `,
    icon: css`
      justify-content: center;
      width: 90px;
      height: 90px;
      margin: 0 0 24px;
      align-self: center;
    `,
  };
};

export interface PageHeadingProps {
  children: ReactNode;
  className?: string;
  width?: Widths;
  icon?: string;
}

export const PageHeading = ({ children, className, width = 'medium', icon }: PageHeadingProps) => {
  const styles = useStyles(getStyles, width);

  return (
    <Heading className={className} css={styles.main} id="page-title" type="1">
      {icon && <img src={`${PUBLIC_URL}/img/icons/${icon}.svg`} alt={icon} css={styles.icon} />}
      {children}
    </Heading>
  );
};
