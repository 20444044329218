/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';

import { AlternateAction } from 'components/AlternateAction';
import { SSOButtons, publicSsoProviders } from 'components/SSOButtons';
import { Text } from 'components/Text';
import { StylingContextState } from 'context/Styling';
import { signInRoutes } from 'pages/SignIn/routes';
import { signUpRoutes } from 'pages/SignUp/routes';
import { PUBLIC_URL } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

import { CreateUserForm, CreateUserFormProps } from './CreateUserForm';

interface CreateUserSSOProps {
  incomingEmail?: string;
}

export const CreateUserSSO = ({ incomingEmail }: CreateUserSSOProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));
  const handleFormSubmit: CreateUserFormProps['onSubmit'] = () => {
    setIsSubmitting(true);
  };

  const handleFormError: CreateUserFormProps['onError'] = () => {
    setIsSubmitting(false);
  };

  return (
    <div css={styles.rightPanelContainer} id="sso-form">
      <div css={styles.panelWrapper} id="panel-wrapper">
        <div css={styles.createCloudHeaderText}>
          <img
            src={`${PUBLIC_URL}/img/grafana-cloud-logo-for-light-theme.svg`}
            alt="Grafana logo"
            css={styles.logo}
            id="header-logo-img"
          />
          <h2>Create your free account</h2>
        </div>
        <div id="sso-buttons-group">
          <SSOButtons
            disableUserCreation={false}
            ssoProviders={publicSsoProviders}
            disabled={isSubmitting}
            redirectRoute={signUpRoutes.selectOrganisation}
          />
        </div>
        <Text align="center" css={styles.textSeparator} type="normal" id="text-separator">
          or
        </Text>
        <CreateUserForm incomingEmail={incomingEmail} onSubmit={handleFormSubmit} onError={handleFormError} />
        <AlternateAction
          actionLabel="Log in"
          actionLink={signInRoutes.base}
          id="sign-in-link"
          label="Have an account?"
          type="normal"
        />
      </div>
    </div>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  rightPanelContainer: css`
    display: flex;
    flex-direction: column;
    width: 610px;
    text-align: center;
    align-self: center;
    background-color: ${colors.gray9};
    margin-top: 0px;
    padding: 48px;
    border-radius: 8px;
    background: var(--background-primary, #fff);
    box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.05);

    #sso-buttons-group button {
      font-size: 14px !important;
    }

    @media (max-width: 1100px) {
      display: grid;
      grid-area: ssoPanel;
      margin: 0;
      width: 100%;
      justify-content: stretch;
    }

    @media (max-width: 550px) {
      max-width: 414px;
      padding: 24px;
      align-self: top;
    }
  `,
  logo: css`
    width: 218px;
    height: 38px;
    margin: 0px;
  `,
  textSeparator: css`
    margin: 16px 0 7px;
    color: ${colors.gray11};
    background-color: ${colors.white};
  `,
  panelWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  createCloudHeaderText: css`
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 114.286% */
    letter-spacing: -0.07px;

    h2 {
      font-size: 28px;
      margin: 24px 0 32px;
      padding: 0;

      @media (max-width: 1100px) {
        font-size: 24px;
      }
    }
    align-self: center;
    svg,
    img {
      text-align: center;
    }
  `,
});
