/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';

import { ButtonFill, ButtonSize, ButtonVariant } from 'components/Button/Button.types';
import { getPropertiesForVariant } from 'components/Button/Button.utils';
import { StylingContextState } from 'context/Styling';

export const getButtonStyles = (
  context: StylingContextState,
  width: ButtonSize,
  textSize: keyof StylingContextState['typography']['text'],
  iconMarginMultiplier: number,
  isLoading: boolean,
  variant: ButtonVariant = 'primary',
  fill: ButtonFill = 'solid'
) => {
  const variantStyles = getPropertiesForVariant(context, variant, fill, isLoading, width);

  return {
    button: css`
      ${context.typography.text[textSize!]};

      ${variantStyles};
      border-radius: 5px;
    `,
    icon: css`
      height: 1.8rem;
      margin-right: ${0.4 * iconMarginMultiplier}rem;
      width: 1.8rem;

      &.noLabel {
        margin-right: 0;
      }
    `,
    loader: css`
      color: inherit;
      margin: 0;
      padding: 0;
      text-align: center;
    `,
  };
};
