/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import cx from 'classnames';
import { MouseEventHandler, useContext } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { noop } from 'utils/noop';
import { useStyles } from 'utils/useStyles';

const getStyles = ({ colors }: StylingContextState, underline?: boolean) => css`
  border: 0.1rem solid transparent;
  border-radius: 0.2rem;
  color: #1f62e0;
  outline: none;
  text-decoration: ${underline ? 'underline' : 'none'};

  &.primary {
    color: var(--text-link, #1f62e0);
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      border-color: ${colors.blue3};
    }
  }

  &.secondary {
    text-decoration: underline;

    &:hover {
      filter: drop-shadow(0 0.1rem 0.6rem ${colors.white});
    }

    &:focus {
      filter: drop-shadow(0 0.1rem 0.6rem ${colors.white});
    }
  }

  &.spaced {
    margin-left: 0.8rem;
  }

  &.secondaryAlt {
    color: ${colors.text.secondary};
    text-decoration: none;

    &:hover {
      filter: none;
      text-decoration: underline;
    }

    &:focus {
      color: ${colors.white};
    }
  }
`;

export interface LinkProps {
  label: string;

  className?: string;
  id?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  spaced?: boolean;
  style?: 'primary' | 'secondary' | 'secondaryAlt';
  to?: string;
  trackingLabel?: string;
  type?: 'internal' | 'external' | 'button';
  underline?: boolean;
}

export const Link = ({
  label,
  className,
  id,
  onClick = noop,
  spaced,
  style = 'primary',
  to,
  trackingLabel,
  type = 'internal',
  underline = true,
}: LinkProps) => {
  const styles = useStyles(getStyles, underline);
  const [, { faro }] = useContext(TrackingContext);

  const commonProps = {
    className: cx('link', spaced && 'spaced', style, className),
    css: styles,
    id,
    onClick: (...args: Parameters<MouseEventHandler<HTMLAnchorElement>>) => {
      faro.trackLinkClick(trackingLabel ?? label, to!);
      onClick(...args);
    },
    role: type === 'button' ? 'button' : 'link',
  };

  switch (type) {
    case 'button': {
      const handleButtonClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault();
        faro.trackButtonClick(trackingLabel ?? label);

        onClick(event);
      };

      return (
        <a {...commonProps} href="#" onClick={handleButtonClick}>
          {label}
        </a>
      );
    }

    case 'external':
      return (
        <a {...commonProps} href={to} target="_blank" rel="noreferrer">
          {label}
        </a>
      );

    default:
      return (
        <ReactRouterLink {...commonProps} to={to!}>
          {label}
        </ReactRouterLink>
      );
  }
};
