/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { useMemo } from 'react';

import { StylingContextState } from 'context/Styling';
import { palette } from 'context/Styling/palette';
import { BulletRenderer } from 'pages/SignUp/BulletRenderer';
import { useStyles } from 'utils/useStyles';

export const ListLoader = () => {
  const styles = useStyles(getStyles);

  const memoedBulletRenderer = useMemo(() => {
    const listItems = [
      'Unlimited Users (invite your whole team)',
      'Unlimited Metrics',
      'Unlimited Logs',
      'Unlimited Traces',
      'Unlimited Profiles',
      '100k Synthetics Test Executions',
      '500 k6 VUh',
      'Unlimited Frontend Sessions',
      'Access to all Enterprise plugins',
    ];

    return <BulletRenderer listItems={listItems} shouldAnimate={true} />;
  }, []);

  return (
    <div css={styles.listLoader}>
      <ul css={styles.ul}>
        <div id="header">Trial includes:</div>
        {memoedBulletRenderer}
      </ul>
    </div>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  listLoader: css`
    background: #f0f5ff;
    padding: 16px;
    margin: 32px 0 16px;
    border-radius: 4px;
    border-top: 2px solid ${palette.blueLightMain};
  `,
  ul: css`
    color: var(--text-primary, ${colors.gray11});
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
    margin: 0;
    padding: 0;

    #header {
      margin-bottom: 0;
    }
  `,
});
