/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';

import { Link } from 'components/Link';
import { Text, TextAlign } from 'components/Text';
import { useStyles } from 'utils/useStyles';

interface DisclaimerProps {
  trackingQueryParams?: string;
  align?: string;
}

export const Disclaimer = ({ trackingQueryParams = '', align = 'left' }: DisclaimerProps) => {
  const styles = useStyles(getStyles);

  return (
    <Text id="disclaimer" type="tiny" align={align as TextAlign}>
      <span css={styles.main}>
        By creating an account, you agree to our{' '}
        <Link
          id="disclaimer-terms"
          to={`/legal/terms/${trackingQueryParams}`}
          type="external"
          label="Terms and Conditions"
        />{' '}
        and{' '}
        <Link
          id="disclaimer-msa"
          to={`/legal/msa/${trackingQueryParams}`}
          type="external"
          label="Master Services Agreement"
        />
        .
      </span>
    </Text>
  );
};

const getStyles = () => ({
  main: css`
    color: var(--text-secondary, rgba(36, 41, 46, 0.75));
    text-align: center;

    a {
      text-decoration: underline;
      color: var(--text-secondary, rgba(36, 41, 46, 0.75)) !important;
    }

    /* Body Small */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px;
    grid-row-start: row1-start;
  `,
});
