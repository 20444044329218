import * as rudderanalytics from 'rudder-sdk-js';

import {
  RUDDER_STACK_DATA_PLANE_URL,
  RUDDER_STACK_KEY,
  RUDDER_STACK_CONFIG_URL,
  RUDDERSTACK_INTEGRATIONS_URL,
} from 'utils/consts';
import { noop } from 'utils/noop';

import { EventTraits, OrganisationTraits, OrganisationsTraits, UserTraits } from './types';

const isEnabled = !!RUDDER_STACK_KEY && !!RUDDER_STACK_DATA_PLANE_URL;

export const load = isEnabled
  ? () => {
      rudderanalytics.load(RUDDER_STACK_KEY!, RUDDER_STACK_DATA_PLANE_URL!, {
        logLevel: 'WARN',
        configUrl: RUDDER_STACK_CONFIG_URL,
        destSDKBaseURL: RUDDERSTACK_INTEGRATIONS_URL,
      });
    }
  : noop;

export const trackPage = isEnabled
  ? () => {
      rudderanalytics.page();
    }
  : noop;

export const trackEvent = isEnabled
  ? ({ name, componentKey, ...params }: EventTraits) => {
      // Note: If you're familiar with hg-cloud-home-admin repo, "name" here === "eventName" there.
      // Preferably, "name" stays the same betwen experiments or domains e.g. 'cloud_signup_flow_event'
      // or 'cloud_signup_invite_team_event'
      let trackParams: EventTraits = { name, ...params };

      if (componentKey) {
        // Note: If you're familiar with hg-cloud-home-admin, "componentKey" should be the key that
        // changes per event or page view. Optional.
        trackParams = { ...trackParams, component_key: componentKey };
      }

      rudderanalytics.track(name, trackParams ?? {});
    }
  : noop;

export const identifyUser = isEnabled
  ? ({ id, ...traits }: UserTraits) => {
      rudderanalytics.identify(`${id}`, traits);
    }
  : noop;

export const resetUser = isEnabled
  ? () => {
      rudderanalytics.reset();
    }
  : noop;

export const assignUserToOrganisation = isEnabled
  ? (organisations: OrganisationsTraits) => {
      organisations.forEach(({ id, ...traits }: OrganisationTraits) => {
        rudderanalytics.group(`${id}`, traits);
      });
    }
  : noop;
