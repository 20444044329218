import { useCallback } from 'react';

import { Endpoints, post } from 'utils/api';

export const validateSlugAvailability = () =>
  useCallback(async (slug: string) => {
    let message = undefined;

    try {
      await post(Endpoints.CHECK_ORGANISATION_SLUG, {
        slug: slug.toLowerCase(),
      });

      await post(Endpoints.CHECK_INSTANCE_SLUG, {
        slug: slug.toLowerCase(),
      });
    } catch (err) {
      message = err.code === 'Conflict' ? 'Sadly, that URL is already taken. Please try another.' : err.message;
    }

    return message;
  }, []);
