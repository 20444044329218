import Cookies from 'js-cookie';

const COOKIE_NAME = 'config';

const getConfig = (): Record<string, unknown> => {
  let config;

  try {
    config = JSON.parse(Cookies.get(COOKIE_NAME)?.substr(2) ?? '');
  } catch (err) {
    config = {};
  }

  return config;
};

export const getConfigProp = (propName: string): unknown => {
  return getConfig()[propName];
};
