/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';

import { Page, PagePanel } from 'components/Page';
import { StylingContextState } from 'context/Styling';
import { ListLoader } from 'pages/SignUp/ListLoader';
import { PUBLIC_URL } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

export const LoadingInstanceTrialBenefits = () => {
  const styles = useStyles(getStyles);

  return (
    <Page title="Finishing Setup" css={styles.page}>
      <PagePanel id="panel" align={'center'} type="full">
        <div css={styles.img}>
          <img src={`${PUBLIC_URL}/img/loaders/logo-instance-loaders/frame0.svg`} />
        </div>
        <div css={styles.loadingText}>Loading...</div>
        <h1 id={'message'} css={styles.header}>
          Your 14-day Unlimited Usage Trial
        </h1>

        <ListLoader />

        <div css={styles.afterTrial}>
          After that you can choose to continue with the Free Forever Plan, or Pay As You Go
        </div>

        <div css={styles.support}>
          If you see this message for more than a few minutes, please email{' '}
          <a css={styles.link} href="mailto:support@grafana.com">
            support@grafana.com
          </a>
        </div>
      </PagePanel>

      <div css={styles.footer}>
        <footer>
          Copyright {new Date().getFullYear()} &copy; Grafana Labs | contact us:&nbsp;
          <a css={styles.link} href="mailto:support@grafana.com">
            support@grafana.com
          </a>
          &nbsp;|&nbsp;
          <a css={styles.link} href="https://grafana.com/trademark-policy" target="_blank" rel="noreferrer">
            Trademark Policy
          </a>
        </footer>
      </div>
    </Page>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  page: css`
    justify-content: center;

    #page-content {
      background-color: ${colors.white};
      box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.05) !important;
      width: 610px;

      @media (max-width: 768px) {
        margin: 24px;
        padding: 48px;
        width: 90%;
      }

      @media (max-width: 550px) {
        padding: 24px 0;
        max-width: 414px;
      }
      @media (max-width: 400px) {
        h1 {
          padding: 0 12px;
        }
      }
    }
    #page-panels,
    #panel {
      width: 510px;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        padding: 0px;
        margin: 0px;
        width: 100%;
      }

      @media (max-width: 550px) {
        padding: 0 24px;
      }
    }
  `,
  logoLoader: css`
    place-items: center;
  `,
  text: css`
    color: #c7d0d9;
    text-align: center;
  `,
  support: css`
    color: #24292e;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.018px;
    margin-top: 32px;

    ​ a {
      text-decoration: none;
    }

    @media (max-width: 550px) {
      font-size: 12px;
    }
  `,
  img: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-items: center;
    margin: 0;
    margin-bottom: 5px;
    place-items: center;
    background: center / contain no-repeat url('${PUBLIC_URL}/img/loaders/logo-instance-loaders/background-layer1.svg'),
      center / contain no-repeat url('${PUBLIC_URL}/img/loaders/logo-instance-loaders/background-layer2.svg');
    background-size: 73px, 90px;
    min-height: 90px;

    svg {
      height: 53px;
      width: 53px;
    }
  `,
  loadingText: css`
    text-align: center;
    font-weight: 400;
    font-size: 12px;
  `,
  footer: css`
    color: #24292e;
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: center;
    bottom: 1rem;
    left: 0;
    position: fixed;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.021px;
    border: none !important;
    margin: 0;
    padding: 0;
  `,
  imgFrame: (index: number) => css`
    position: absolute;
    visibility: hidden;
    animation: 1.5s show infinite ease-in;
    animation-delay: ${index * 0.25}s;
    width: 100%;
    left: 0;

    @keyframes show {
      0% {
        visibility: visible;
      }
      25% {
        visibility: visible;
      }
      25.1% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }
  `,
  header: css`
    margin-bottom: 0px;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: ${colors.gray11};
    margin-top: 24px;
  `,
  link: css`
    background-color: '';
    color: inherit;

    &:active,
    :hover {
      outline: 0;
    }
  `,
  afterTrial: css`
    text-align: center;
    color: ${colors.gray11};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.021px;
  `,
});
