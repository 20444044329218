/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ErrorBox } from 'components/ErrorBox';
import { hasMinLength, Input, InputProps, isRequired, Submit } from 'components/Form';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useQuery } from 'hooks/useQuery';
import { Endpoints, post } from 'utils/api';
import { ORIGIN } from 'utils/consts';
import { useStyles } from 'utils/useStyles';

interface CheckInboxFormValues {
  emailConfirmationCode: string;
}

interface CheckInboxFormProps {
  formName: string,
  redirectPath: string,
}

export const CheckInboxForm = ({ formName, redirectPath }: CheckInboxFormProps) => {
  const [, { faro }] = useContext(TrackingContext);
  const [serverError, setServerError] = useState<string | null>(null);
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));
  const code = useQuery('code');
  const emailConfirmationCode = useQuery('emailConfirmationCode');

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<CheckInboxFormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { emailConfirmationCode },
  });

  const handleFormSubmit: SubmitHandler<CheckInboxFormValues> = useCallback(
    async (values) => {
      setServerError(null);

      try {
        faro.trackFormSubmit(formName);
        await post<boolean>(Endpoints.GRAFANA_SSO_LOGIN, { code, ...values });
        faro.trackFormSubmitSuccess(formName);
        window.location.href = `${ORIGIN}/${redirectPath}`;
      } catch (err) {
        faro.trackFormSubmitError(formName, err.message);
        faro.trackError(err);
        setServerError(err.message);
      }
    },
    [formName, code, redirectPath, faro]
  );

  const handleInputChange: InputProps['onChange'] = () => {
    if (serverError !== null) {
      setServerError(null);
    }
  };

  useEffect(() => {
    if (isDirty && isValid) {
      handleSubmit(handleFormSubmit)();
    }
  }, [handleSubmit, handleFormSubmit, isValid, isDirty]);

  useEffectOnce(() => {
    if (emailConfirmationCode) {
      handleSubmit(handleFormSubmit)();
    }
  });

  return (
    <form id="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <Input
        control={control}
        disabled={isSubmitting}
        errors={errors}
        id="confirmation-code"
        label="Activation code"
        name="emailConfirmationCode"
        onChange={handleInputChange}
        showLabel
        type="text"
        css={styles.inputs}
        formatter={(value) => value.trim()}
        validate={[isRequired(), hasMinLength(8)]}
      />
      <Submit
        disabled={isSubmitting}
        id="submit"
        isLoading={isSubmitting}
        label="Check activation code"
        loadingText={'Loading'}
      />
      {serverError && <ErrorBox id="server-error">{serverError}</ErrorBox>}
    </form>
  );
};

const getStyles = ({ colors }: StylingContextState) => ({
  inputs: css`
    margin: 1.1rem 0;
    input {
      color: ${colors.gray11};

      &:focus,
      &:hover {
        background-color: ${colors.white};
      }
    }
  `,
});
