/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { ReactNode, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useStyles } from 'utils/useStyles';

import { Disclaimer } from './Disclaimer';
import { PageHeading } from './PageHeading';
import { Widths } from './widths';

const getStyles = ({ colors, viewport: { lg, md } }: StylingContextState) => ({
  main: css`
    align-items: ${md ? 'center' : 'stretch'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    width: 100vw;
    background-color: ${colors.gray9};

    @media (max-width: 768px) {
      justify-content: flex-start;
    }

    @media (max-width: 550px) {
      align-items: center;
    }
  `,
  content: css`
    background-color: ${colors.white};
    color: var(--text-primary, #24292e);
    border-radius: 8px;
    display: flex;
    width: 514px;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: 48px;

    @media (max-width: 768px) {
      margin: 24px 48px;
      padding: 48px;
      width: 90%;
    }

    @media (max-width: 550px) {
      max-width: 414px;
      padding: 24px;
    }
  `,
  panels: css`
    display: flex;
    flex-flow: row ${lg ? 'nowrap' : 'wrap'};
    width: 414px;
    justify-content: center;

    @media (max-width: 1260px) {
      width: 100%;
      word-wrap: wrap;
    }

    @media (max-width: 768px) {
      width: 100%;
      word-wrap: wrap;
    }
  `,
});

export interface PageProps {
  children: ReactNode;
  title: string;
  className?: string;
  heading?: string;
  headingWidth?: Widths;
  headerIcon?: string;
}

export const Page = ({ children, title, className, heading, headingWidth = 'full', headerIcon }: PageProps) => {
  const history = useHistory();
  const styles = useStyles((theme: StylingContextState) => getStyles(theme));
  const [, { trackPage, getReferrers, getSourcesAsTrackingParams, getSourcesAsQueryParams }] =
    useContext(TrackingContext);
  let trackingQueryParams = getSourcesAsQueryParams();
  trackingQueryParams = trackingQueryParams ? `?${trackingQueryParams}` : '';

  const isSignupPage = title.includes('Sign Up') || title.includes('Create User');

  useEffectOnce(() => {
    trackPage(
      {
        ...getReferrers(),
        ...getSourcesAsTrackingParams(),
      },
      {
        location: location.href,
        path: location.pathname,
        title,
      }
    );
  });

  useEffect(() => {
    document.title = `Grafana Cloud - ${title}`;

    return () => {
      document.title = 'Grafana Cloud';
    };
  }, [title]);

  return (
    <>
      <main aria-labelledby="page" className={className} css={styles.main} id="page">
        <div css={styles.content} id="page-content">
          {heading && (
            <PageHeading width={headingWidth} icon={headerIcon}>
              {heading}
            </PageHeading>
          )}
          <div css={styles.panels} id="page-panels">
            {children}
          </div>
        </div>
        {isSignupPage && !history.location.pathname.includes('create-user') && (
          <Disclaimer trackingQueryParams={trackingQueryParams} />
        )}
      </main>
    </>
  );
};
