/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';

import { AlternateAction } from 'components/AlternateAction';
import { Page, PagePanel } from 'components/Page';
import { Text } from 'components/Text';
import { signInRoutes } from 'pages/SignIn/routes';
import { useStyles } from 'utils/useStyles';

import { RecoverForm } from './RecoverForm';

export const Recover = () => {
  const styles = useStyles(getStyles);
  return (
    <Page heading="Forgot password?" title="Forgot Password - Recover Form" css={styles.content}>
      <PagePanel id="panel" type="full">
        <Text align="left" id="description" color={'gray11'} css={styles.modifiedTextMargins}>
          Enter your email address or username, and we&apos;ll send you a link to reset your password.
        </Text>
        <RecoverForm />
        <AlternateAction actionLabel="Log in" actionLink={signInRoutes.base} id="sign-in-link" label="Or return to" />
      </PagePanel>
    </Page>
  );
};

const getStyles = () => ({
  modifiedTextMargins: css`
    margin: 32px 0 7px !important;

    @media (max-width: 550px) {
      margin: 24px 0 7px !important;
    }
  `,
  content: css`
    #page-content {
      box-shadow: 0px 32px 48px 0px rgba(0, 0, 0, 0.05) !important;
    }
  `,
});
