/** @jsxImportSource @emotion/react */
import cx from 'classnames';
import { MouseEventHandler, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { getButtonStyles } from 'components/Button/Button.styles';
import { ButtonFill, ButtonSize, ButtonVariant } from 'components/Button/Button.types';
import { Icon } from 'components/Icon';
import { Loader } from 'components/Loader';
import { LoaderTypes } from 'components/Loader/types';
import { StylingContextState } from 'context/Styling';
import { TrackingContext } from 'context/Tracking';
import { noop } from 'utils/noop';
import { useStyles } from 'utils/useStyles';

export interface ButtonProps {
  label: string;

  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  href?: string;
  icon?: string;
  iconLabel?: string;
  iconMarginMultiplier?: number;
  id?: string;
  isLoading?: boolean;
  loadingText?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  role?: 'button' | 'link';
  textSize?: keyof StylingContextState['typography']['text'];
  trackingLabel?: string;
  type?: 'button' | 'submit';
  width?: ButtonSize;
  variant?: ButtonVariant;
  fill?: ButtonFill;
}

export const Button = ({
  label,
  ariaLabel,
  className,
  disabled,
  hideLabel,
  href,
  icon,
  iconLabel,
  iconMarginMultiplier = 3,
  id,
  isLoading,
  loadingText = label,
  onClick = noop,
  role = 'button',
  textSize = 'normal',
  trackingLabel,
  type = 'button',
  width = 'full',
  variant = 'white',
  fill = 'solid',
}: ButtonProps) => {
  const styles = useStyles(getButtonStyles, width, textSize, iconMarginMultiplier, isLoading, variant, fill);
  const history = useHistory();
  const [, { faro }] = useContext(TrackingContext);

  let actualOnClick: ButtonProps['onClick'] = (...args) => {
    faro.trackButtonClick(trackingLabel ?? label);
    onClick(...args);
  };

  if (role === 'link') {
    actualOnClick = (event) => {
      faro.trackLinkClick(trackingLabel ?? label, href!);
      onClick(event);

      history.push(href!);
    };
  }

  return (
    <button
      aria-label={ariaLabel ?? label}
      className={cx('button', className)}
      css={styles.button}
      data-href={href}
      disabled={disabled}
      id={id}
      type={type}
      onClick={actualOnClick}
      role={role}
      style={{ whiteSpace: 'nowrap' }}
    >
      {!isLoading ? (
        <>
          {icon && (
            <Icon name={icon} className={cx(hideLabel && 'noLabel')} css={styles.icon} title={iconLabel ?? label} />
          )}
          {!hideLabel && label}
        </>
      ) : (
        <Loader css={styles.loader} label={!hideLabel ? loadingText : undefined} type={LoaderTypes.SPINNER} />
      )}
    </button>
  );
};
