import { Validate } from 'react-hook-form';

const defaultMessages = {
  isRequired: 'This field cannot be empty',
  hasMinLength: 'This field should be at least %value% characters long',
  isEmail: 'This field should be a valid email address',
  hasComplexity: 'This field must contain at least 1 character and 1 number',
};

export const isRequired = (message?: string): Validate<string, any> => {
  message = message ?? defaultMessages.isRequired;

  return (value) => (Boolean(value) ? undefined : message);
};

export const hasMinLength = (minLength: number, message?: string): Validate<string, any> => {
  message = (message ?? defaultMessages.hasMinLength).replace('%value%', minLength.toString());

  return (value) => (!Boolean(value) || value.length >= minLength ? undefined : message);
};

export const isEmail = (message?: string): Validate<string, any> => {
  message = message ?? defaultMessages.isEmail;

  return (value) =>
    !Boolean(value) ||
    // https://stackoverflow.com/a/46181/4468021
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
      ? undefined
      : message;
};

export const hasComplexity = (message?: string): Validate<string, any> => {
  message = message ?? defaultMessages.hasComplexity;

  return (value) => (!Boolean(value) || (/[a-z]/i.test(value) && /[0-9]/.test(value)) ? undefined : message);
};
