/** @jsxImportSource @emotion/react */
import cx from 'classnames';

import { getSvgSize } from 'components/Icon/utils';
import { IconSize } from 'types/size';
import { PUBLIC_URL } from 'utils/consts';

export interface IconProps {
  name: string;
  title: string;

  className?: string;
  id?: string;
  size?: IconSize;
}

export const Icon = ({ name, className, id, title, size }: IconProps) => {
  const svgSize = !!size ? getSvgSize(size) : '100%';

  if (name.includes('for-light-theme')) {
    return <img src={`${PUBLIC_URL}/img/icons/${name}.svg`} alt={name} className={cx('icon', className)} />;
  }

  return (
    <svg aria-label={title} className={cx('icon', className)} width={svgSize} height={svgSize} id={id} role="">
      <use xlinkHref={`${PUBLIC_URL}/img/icons/${name}.svg#icon`} />
    </svg>
  );
};
