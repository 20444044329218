/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { StylingContextState } from 'context/Styling';

export const customSignupStyles = (
  { colors, typography }: StylingContextState,
  hasDescription?: boolean,
  shouldShowListItem?: boolean,
  animatedList?: boolean
) => {
  return {
    textWrapper: css`
      ${typography.text.small};
      line-height: 16px;
      text-align: left;
      color: ${colors.gray11};
    `,
    standardTextWrapper: css`
      ${typography.text.small};
      color: ${colors.gray8};
      line-height: 1.5rem;
      text-align: left;
      padding-left: 0px;
      margin-left: 0px;
    `,
    headingSpacing: css`
      margin-bottom: 0;
    `,
    iconsWrapper: css`
      font-size: 1rem;
      line-height: 2.1rem;
      font-weight: 600;
      color: ${colors.gray11};
      padding: 0.7rem 0;
      margin-right: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    `,
    iconImg: css`
      img {
        width: 49px;
        height: 45px;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
      }
    `,
    dashboardImg: css`
      img {
        height: 200px;
        margin-left: -34px;
      }
    `,
    header: css`
      ${typography.text.tiny};
      line-height: 32px;
      font-weight: 400;
      font-size: 28px;
      margin-bottom: ${hasDescription ? '8px' : '1.8rem'};
      color: ${colors.gray11};

      @media (max-width: 768px) {
        font-size: 24px;
      }
    `,
    h4: css`
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 122.222% */
      letter-spacing: 0.045px;
      margin: 0;
      margin-bottom: 14px;
      color: ${colors.gray11};
      margin-bottom: 1.8rem;
    `,
    labelWrapper: css`
      ${typography.text.tiny};
      line-height: 1.2rem;
      font-weight: 500;
      color: ${colors.yellow};
      text-align: left;
      margin-bottom: 1rem;
      margin-left: 4.65rem;
    `,
    proContainer: css`
      margin-bottom: 2.5rem;
    `,
    proExtras: css`
      ${typography.text.small};
      color: ${colors.gray9};
      line-height: 2.5rem;
      text-align: left;
      margin-bottom: 1rem;
      margin-left: 4.65rem;
    `,
    freeForeverFeatures: css`
      list-style-type: none;
      ${typography.text.small};
      color: ${colors.gray9};
      line-height: 2.5rem;
      text-align: left;
    `,
    ulStyle: css`
      padding: 0px;
      margin-top: 0;
      font-size: 18px;
      font-weight: 400;
      display: flex;
      flex-flow: column nowrap;
      color: ${colors.gray11};

      @media (max-width: 768px) {
        font-size: 16px;
      }
    `,
    liStyle: css`
      list-style-type: none;
      line-height: 22px;
      display: ${shouldShowListItem ? 'flex' : 'none'};
      flex-flow: row nowrap;
      margin: ${animatedList ? '8px 0 0 0' : '8px 0'};

      ${animatedList &&
      css`
        animation: fadeInAnimation ease 3s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        @keyframes fadeInAnimation {
          0% {
            max-height: 0px;
            opacity: 0.5;
          }
          100% {
            max-height: 100px;
            opacity: 1;
          }
        }
      `}
    `,
    bulletCheckbox: css`
      margin-right: 12px;
      vertical-align: text-top;
      padding-top: 0;
    `,
    bulletNumber: css`
      margin-right: 12px;
      vertical-align: text-top;
    `,
    loader: css`
      color: blue;
      margin: 0 8px 0 0;
      padding: 0;
      text-align: center;
    `,
    li: css`
      font-size: ${animatedList ? '14px' : '18px'};
    `,
  };
};
