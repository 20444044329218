import { ReactNode, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PersistentStateContext } from 'context/PersistentState';
import { Instance as InstanceModel, Organisation, UserContext } from 'context/User';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { SignInErrorCodes } from 'pages/SignIn/errorCodes';
import { signInRoutes } from 'pages/SignIn/routes';
import { CustomProductRedirects } from 'pages/SignUp/CustomSignup.data';
import { redirectSetter } from 'pages/SignUp/customSignupHelpers';
import { signUpRoutes } from 'pages/SignUp/routes';
import { CustomSignupProductType } from 'pages/SignUp/types';
import { CloudOrgData, SubscriptionCodeType } from 'types/orgTypes';
import { Endpoints, get } from 'utils/api';
import { ORIGIN, PUBLIC_URL, GRAFANA_NET_DOMAIN } from 'utils/consts';
import { getConfigProp } from 'utils/cookieConfig';
import { isAbsoluteUrl } from 'utils/isAbsoluteUrl';
import { isInstanceUrl } from 'utils/isInstanceUrl';

import { defaultRedirectContextState, RedirectContext } from './redirectContext';
import {
  ClearInstanceFn,
  GetRedirectUrlFn,
  RedirectToComputedInstanceHomeFn,
  RedirectToComputedInstanceK6Fn,
  RedirectToComputedInstanceOnboardingFn,
  RedirectToPortalFn,
  RedirectToPortalOrInstanceHomeFn,
  RedirectToStateInstanceHomeFn,
  RedirectToStateInstanceOnboardingFn,
  RedirectToToFn,
  SetOrgIdFn,
  UpdateCustomContentFn,
} from './types';

const cloudHomePath = '/a/cloud-home-app';

const instancePaths = {
  home: '/',
  gettingStarted: `${cloudHomePath}/getting-started`,
  onboardingFlow: `${cloudHomePath}/onboarding-flow/start`,
  connections: '/connections/add-new-connection',
  k6: '/a/k6-app',
};

export interface RedirectProviderProps {
  children: ReactNode;
}

export const RedirectProvider = ({ children }: RedirectProviderProps) => {
  const [redirectState, setRedirectState] = useState(defaultRedirectContextState);
  const { search, state } = useLocation<{ redirectStack?: string }>();
  const history = useHistory();
  const [{ userData }, { signOut }] = useContext(UserContext);
  const [persistentState, { setPersistentData }] = useContext(PersistentStateContext);
  const params = new URLSearchParams(search);
  const plcmtParam = params.get('plcmt') ?? undefined;
  const pgParam = params.get('pg') ?? undefined;
  const customSignupProduct = redirectSetter(search, plcmtParam, pgParam);

  useEffectOnce(() => {
    const toParam = params.get('to');
    const redirectPath = params.get('redirectPath');
    let to = redirectState.to;

    if (customSignupProduct) {
      params.set('redirectPath', customSignupProduct?.key as CustomProductRedirects);
      setPersistentData(customSignupProduct);
    }

    if (toParam) {
      if (!isAbsoluteUrl(toParam)) {
        to = !toParam.startsWith('/') ? `/${toParam}` : toParam;
      }
      params.delete('to');
    }

    let integration = params.get('integration');
    if (integration) {
      params.delete('integration');
    }

    let instance: string | null = null;
    const referrer = document.referrer;
    if (isInstanceUrl(referrer)) {
      instance = referrer;
    } else {
      const instanceParam = params.get('instance');
      if (isInstanceUrl(instanceParam)) {
        instance = instanceParam;
      }
      params.delete('instance');
    }

    history.replace({
      search: params.toString(),
    });

    setRedirectState((redirectState) => ({
      ...redirectState,
      initialized: true,
      instance,
      integration,
      to,
      redirectPath,
      customContent: customSignupProduct,
    }));
  });

  // START: STATE SETTERS
  const updateCustomContent: UpdateCustomContentFn = (customContent: CustomSignupProductType) =>
    setRedirectState({
      ...redirectState,
      redirectPath: customContent.key,
      customContent,
    });

  const setOrgId: SetOrgIdFn = (orgId = null) =>
    setRedirectState({
      ...redirectState,
      orgId,
    });

  const clearInstance: ClearInstanceFn = () => {
    setRedirectState((redirectState) => ({
      ...redirectState,
      instance: null,
    }));
  };
  // END: STATE SETTERS

  // START: GETTERS/SELECTORS
  const selectOrgFromList = (user = userData): Organisation => {
    let organisation: Organisation | undefined;

    const memberships = user?.memberships ?? [];
    const { orgId } = redirectState;

    // Try to get the org saved in the context
    if (orgId) {
      organisation = memberships.find((organisation) => organisation.orgId === orgId);
    }

    // Fallback to org saved in config
    if (!organisation) {
      const slugFromConfig = getConfigProp('org');

      if (slugFromConfig) {
        organisation = memberships.find((organisation) => organisation.orgSlug === slugFromConfig);
      }
    }

    // Fallback to the default org
    if (!organisation) {
      organisation = memberships.find((organisation) => organisation.defaultOrg);
    }

    // Fallback to first org in the list
    if (!organisation) {
      organisation = memberships[0];
    }

    return organisation;
  };

  const selectInstanceFromList = (instances: InstanceModel[], user = userData, stackId?: string) => {
    if (stackId) {
      const stack = instances.find((instance) => instance.id === Number(stackId));

      if (stack) {
        return stack;
      }
    }
    const orgId = redirectState.orgId ?? selectOrgFromList(user)?.orgId;
    console.log('selectInstanceFromList - instances', instances);
    return instances.find((instance) => instance.orgId === orgId) ?? instances[0];
  };

  const getInstances = async (user = userData) => {
    const memberships = user?.memberships ?? [];

    try {
      // Build the orgId list
      const orgIdIn = redirectState.orgId ?? memberships.map((organisation) => organisation.orgId).join(',');
      // Fetch the instances for the built orgId
      const instances = await get<{ items: InstanceModel[] }>(`${Endpoints.GET_INSTANCES}?orgIdIn=${orgIdIn}`);

      return instances?.items ?? [];
    } catch (err) {
      return [];
    }
  };

  const getRedirectUrl: GetRedirectUrlFn = (path) => {
    const { instance, to } = redirectState;
    const queryParams = new URLSearchParams();

    if (customSignupProduct) {
      queryParams.set('forceCustomization', customSignupProduct?.key as CustomProductRedirects);
    }

    if (instance) {
      queryParams.set('instance', instance);
    }

    if (to) {
      queryParams.set('to', to);
    }

    let stringifiedQueryParams = queryParams.toString();

    if (stringifiedQueryParams) {
      stringifiedQueryParams = `?${stringifiedQueryParams}`;
    }

    return `${PUBLIC_URL}${path}${stringifiedQueryParams}`;
  };
  // END: GETTERS/SELECTORS

  // START: MAIN REDIRECT FUNCTIONS
  const redirectToPortal: RedirectToPortalFn = (user = userData) => {
    const organisation = selectOrgFromList(user);

    if (organisation) {
      window.location.href = `${ORIGIN}/orgs/${organisation.orgSlug}`;
    } else {
      window.location.href = `${ORIGIN}/users/${user?.username}/settings`;
    }
  };

  const redirectToInstance = (path: string, instanceUrl: string, isActiveInstance?: boolean) => {
    const actualPath = !!customSignupProduct?.redirect
      ? `/${customSignupProduct.redirect}`
      : !!persistentState?.customContent?.redirect
      ? `/${persistentState.customContent?.redirect}`
      : !!path
      ? `${encodeURIComponent(path)}`
      : `${encodeURIComponent(instancePaths.onboardingFlow)}`;

    // if the grafana instance is active, skip the loading screen
    if (isActiveInstance) {
      console.log('redirectToInstance - isActiveInstance logged', isActiveInstance);
      window.location.href = `${instanceUrl}/set-redirect-and-login?url=${actualPath}`;
    } else {
      console.log('redirectToInstance - else - !isActiveInstance && !isInTrial logged', isActiveInstance);
      history.push(`${signUpRoutes.loadingInstance}?url=${actualPath}&instanceUrl=${instanceUrl}`);
    }
  };
  // END: MAIN REDIRECT FUNCTIONS

  // START: MAIN HANDLER FUNCTIONS THAT SPECIFY WHICH REDIRECT PATH TO USE
  // All of these functions will invoke either `redirectToInstance` or `redirectToPortal`
  // (found above)
  const redirectToStateInstance = async (path: string) => {
    const { instance } = redirectState;

    const instanceUrl = new URL(instance!);
    try {
      await get<{ items: InstanceModel[] }>(
        `${Endpoints.GET_INSTANCE}/${instanceUrl.host.replace('.' + GRAFANA_NET_DOMAIN, '')}`
      );
      redirectToInstance(path, instance!);
    } catch (err) {
      signOut(signInRoutes.base, {
        errorCode: SignInErrorCodes.NO_ORG_ACCESS,
      });
    }
  };

  const redirectToStateInstanceHome: RedirectToStateInstanceHomeFn = async () =>
    redirectToStateInstance(instancePaths.home);

  const redirectToStateInstanceOnboarding: RedirectToStateInstanceOnboardingFn = async () =>
    redirectToStateInstance(instancePaths.connections);

  const redirectToComputedInstance = async (path: string, user = userData) => {
    const { orgId } = redirectState;
    const instances = await getInstances(user);

    if (instances.length === 0 || (instances.length > 1 && !state?.redirectStack)) {
      redirectToPortal(user);

      return;
    }

    const instance = selectInstanceFromList(instances, user, state?.redirectStack);
    let isActiveInstanceAndNotTrial = false;

    try {
      const tempOrgId = orgId ?? instance?.orgId;

      if (tempOrgId) {
        const orgData = await get<CloudOrgData>(`${Endpoints.GET_ORGS}/${tempOrgId}`);
        const currentProductIsFree: boolean = orgData?.subscriptions?.current.product === SubscriptionCodeType.FREE;
        const isInTrial = orgData?.subscriptions?.current.isTrial && currentProductIsFree;

        // We do not want new users to skip loading screen (who also have an instance status of 'active')
        isActiveInstanceAndNotTrial = instance?.status === 'active' && !isInTrial;
      }
    } catch (err) {
      console.log('err', err.message);
    }

    redirectToInstance(path, instance.url, isActiveInstanceAndNotTrial);
  };

  const redirectToComputedInstanceHome: RedirectToComputedInstanceHomeFn = async (user) =>
    redirectToComputedInstance(instancePaths.home, user);

  const redirectToComputedInstanceK6: RedirectToComputedInstanceK6Fn = async (user) =>
    redirectToComputedInstance(instancePaths.k6, user);

  const redirectToComputedInstanceOnboarding: RedirectToComputedInstanceOnboardingFn = async (user) => {
    const params = new URLSearchParams(search);

    if (persistentState?.customContent) {
      params.set('redirectPath', persistentState?.customContent?.key as CustomProductRedirects);
    }

    let path = instancePaths.onboardingFlow;
    if (!!persistentState.customContent?.redirect) {
      path = persistentState.customContent.redirect;
    }

    return redirectToComputedInstance(path, user);
  };

  const redirectToTo: RedirectToToFn = () => {
    const { to } = redirectState;

    if (to) {
      window.location.href = `${ORIGIN}${to}`;
    }
  };

  const redirectToPortalOrInstanceHome: RedirectToPortalOrInstanceHomeFn = async (user) => {
    const actualUser = user ?? userData;
    const orgsNo = actualUser?.memberships.length ?? 0;

    if (orgsNo > 1) {
      redirectToPortal(user);
    } else {
      const instances = await getInstances(actualUser);

      if (instances.length === 1) {
        redirectToInstance(instancePaths.home, instances[0].url);
      } else {
        redirectToPortal(actualUser);
      }
    }
  };
  // END: MAIN HANDLER FUNCTIONS THAT SPECIFIY WHICH REDIRECT PATH TO USE

  return (
    <RedirectContext.Provider
      value={[
        redirectState,
        {
          clearInstance,
          getRedirectUrl,
          redirectToComputedInstanceHome,
          redirectToComputedInstanceK6,
          redirectToComputedInstanceOnboarding,
          redirectToStateInstanceHome,
          redirectToStateInstanceOnboarding,
          redirectToPortal,
          redirectToTo,
          redirectToPortalOrInstanceHome,
          setOrgId,
          updateCustomContent,
        },
      ]}
    >
      {redirectState.initialized ? children : null}
    </RedirectContext.Provider>
  );
};
